export const ru = {
  translations: {
    welcome: "Добро пожаловать",
    Settings: "設定",
    "This page allows you to customize your browsing experience on Blocksscan.":
      "Эта страница позволяет настроить ваш опыт использования Blocksscan.",
    Address: "住所",
    Overview: "Обзор",
    Balance: "Баланс",
    Value: "Стоимость",
    "More Info": "Больше информации",
    "Private Name Tags": "Приватные теги имени",
    Add: "Добавить",
    "LAST TXN SENT": "ПОСЛЕДНЯЯ ОТПРАВЛЕННАЯ TXN",
    from: "от",
    "FIRST TXN SENT": "ПЕРВАЯ ОТПРАВЛЕННАЯ TXN",
    "Multichain Info": "Мультичейн информация",
    day: "день",
    days: "дней",
    hour: "час",
    hours: "часов",
    minute: "минута",
    minutes: "минут",
    second: "секунда",
    seconds: "секунд",
    ago: "назад",
    "Multichain Portfolio": "Мультичейн портфолио",
    Transactions: "Транзакции",
    "Internal Transactions": "Внутренние транзакции",
    Transfers: "Переводы",
    "NFT Transfers": "NFT-переводы",
    "Token Holding": "Удержание токенов",
    "Token Inventory": "Инвентарь токенов",
    Events: "События",
    Contract: "Контракт",
    Rewards: "Награды",
    Analytics: "Аналитика",
    Download: "Скачать",
    "CSV Export": "Экспорт CSV",
    Tokens: "Токены",
    Chains: "Цепи",
    "Hide Chains": "Скрыть цепи",
    "Show More Chains": "Показать больше цепей",
    "Hide All Chains": "Скрыть все цепи",
    "Display All Chains": "Показать все цепи",
    "Name Tag": "Имя тега",
    Percentage: "Процент",
    Capacity: "Вместимость",
    Owner: "Владелец",
    "Latest Signed Block": "Последний подписанный блок",
    Status: "Статус",
    "Contract Name": "Название контракта",
    Compiler: "Компилятор",
    Version: "Версия",
    Txns: "Транзакции",
    Verified: "Проверено",
    Audit: "Аудит",
    License: "Лицензия",
    "New Smart Contracts": "Новые смарт-контракты",
    "New Verified Contracts": "Новые проверенные контракты",
    "Smart Contracts": "Смарт-контракты",
    "Verified Smart Contract": "Проверенный смарт-контракт",
    Optimization: "Оптимизация",
    "Constructor args": "Аргументы конструктора",
    "Verified Contracts": "Проверенные контракты",
    Solidity: "Solidity",
    Accounts: "Аккаунты",
    "Block Height": "Высота блока",
    ToDo: "Делать",
    Finalized: "Завершено",
    Timestamp: "Отметка времени",
    "Proposed On": "Предложено",
    "Block proposed on epoch": "Блок предложен в эпоху",
    Withdrawals: "Выводы",
    "withdrawals in this block": "выводы в этом блоке",
    Fee: "Сбор",
    "Fee Recipient": "Получатель сборов",
    "rsync-builder in 12 secs": "rsync-строитель за 12 сек",
    "Block Reward": "Награда за блок",
    "Total Difficulty": "Общая сложность",
    Size: "Размер",
    bytes: "байт",
    "Gas Used": "Используемый газ",
    "Gas Target": "Цель газа",
    "Gas Limit": "Лимит газа",
    "Base Fee Per Gas": "Базовая плата за газ",
    "Burnt Fees": "Сожженные сборы",
    "Extra Data": "Дополнительные данные",
    rsyncbuilder: "rsync-строитель",
    Hash: "Хэш",
    "Parent Hash": "Родительский хэш",
    StateRoot: "Корень состояния",
    WithdrawalsRoot: "Корень выводов",
    Nonce: "Nonce",
    "More Details": "Подробнее",
    "Click to show less": "Нажмите, чтобы показать меньше",
    "Click to show more": "Нажмите, чтобы показать больше",
    "Also known as Block Number. The block height, which indicates the length of the blockchain, increases after the addition of the new block.":
      "Также известен как номер блока. Высота блока, которая указывает на длину блокчейна, увеличивается после добавления нового блока.",
    "The finality status of the block.": "Статус окончательности блока.",
    "This block is finilized.": "Этот блок завершен.",
    "The date and time at which a block is produced.":
      "Дата и время создания блока.",
    "Slot and epoch this block is proposed on.":
      "Слот и эпоха, в которой предложен этот блок.",
    "The number of transactions in the block. Internal transaction is transactions as a result of contract execution that involves Ether value.":
      "Число транзакций в блоке. Внутренняя транзакция - это транзакции в результате выполнения контракта, включающие стоимость эфира.",
    "Number of beacon withdrawals in this block.":
      "Число выводов маяков в этом блоке.",
    "Address receiving fees from transactions in this block.":
      "Адрес, получающий сборы за транзакции в этом блоке.",
    "For each block, the block producer is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block.":
      "Для каждого блока производителю блока выдается конечное количество эфира сверх сборов, уплаченных за все транзакции в блоке.",
    "Total difficulty of the chain until this block.":
      "Общая сложность цепи до этого блока.",
    "The block size is actually determined by the block's gas limit.":
      "Фактически размер блока определяется пределом газа блока.",
    "The total gas used in the block and its percentage of gas filled in the block.":
      "Общий объем газа, использованный в блоке, и его процент заполнения газом в блоке.",
    "Total gas limit provided by all transactions in the block.":
      "Общий лимит газа, предоставленный всеми транзакциями в блоке.",
    "Post-London Upgrade, this represents the minimum gas Used multiplier required for a tx to be included in a block.":
      "После обновления Лондона это представляет собой минимальный множитель использованного газа, необходимый для включения tx в блок.",
    "Post-London Upgrade, this represents the part of the tx fee that is burnt: baseFeePerGas * gasUsed.":
      "После обновления Лондона это представляет собой часть комиссии за tx, которая сжигается: baseFeePerGas * gasUsed.",
    "Any data that can be included by the block producer in the block.":
      "Любые данные, которые может включить производитель блока в блок.",
    "The hash of the block header of the current block.":
      "Хэш заголовка блока текущего блока.",
    "The hash of the block from which this block was generated, also known as its parent block.":
      "Хэш блока, из которого был создан этот блок, также известный как его родительский блок.",
    "The root of the state trie.": "Корень дерева состояний.",
    "Block header withdrawal root hash.":
      "Хэш корневого блока вывода заголовка.",
    "Block nonce is a value used during mining to demonstrate proof of work for a block.":
      "Блочный nonce - это значение, используемое во время майнинга для демонстрации доказательства работы блока.",
    Block: "Блок",
    Blocks: "Блоки",
    Age: "Возраст",
    Node: "Узел",
    "Base Fee": "Базовая комиссия",
    "See preview of the transaction details.":
      "Просмотреть предварительный просмотр деталей транзакции.",
    "Additional Info": "Дополнительная информация",
    confirmation: "подтверждение",
    "Transaction Action": "Действие транзакции",
    Transfer: "Перевод",
    To: "Кому",
    "Transaction Fee": "Комиссия за транзакцию",
    "Gas Info": "Информация о газе",
    "See more details": "Посмотреть больше деталей",
    "Tx Hash": "Хэш транзакции",
    Type: "Тип",
    From: "От",
    "Tx Fee": "Комиссия за транзакцию",
    "in the position": "на позиции",
    "A TxHash or transaction hash is a unique 66-character identifier that is generated whenever a transaction is executed.":
      "TxHash или хэш транзакции - это уникальный идентификатор из 66 символов, который генерируется при выполнении транзакции.",
    "Transaction Hash": "Хэш транзакции",
    "TxHash to clipboard": "Скопировать хэш в буфер обмена",
    "The status of the transaction.": "Статус транзакции.",
    "Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was produced.":
      "Номер блока, в котором записана транзакция. Подтверждения блоков указывают, сколько блоков было добавлено с момента создания транзакции.",
    Confirmation: "Подтверждение",
    "Pending...": "В ожидании...",
    "The date and time at which a transaction is produced.":
      "Дата и время создания транзакции.",
    "Confirmed within": "Подтверждено в течение",
    sec: "сек",
    "Highlighted events of the transaction.": "Выделенные события транзакции.",
    "The sending party of the transaction.": "Отправитель транзакции.",
    "The receiving party of the transaction (could be a contract address).":
      "Получатель транзакции (может быть адресом контракта).",
    created: "создано",
    "List of {{_tokenType}}20 tokens transferred in the transaction.":
      "Список {{_tokenType}}20 токенов, переведенных в транзакции.",
    "Tokens Transferred": "Переведенные токены",
    For: "Для",
    " tokens transferred in the transaction.":
      " токенов, переведенных в транзакции.",
    "List of": "Список",
    "Token ID": "Идентификатор токена",
    "List of tokens mint transferred in the transaction.":
      "Список токенов, выпущенных в транзакции.",
    "Tokens minted": "Выпущенные токены",
    "List of tokens burnt transferred in the transaction.":
      "Список сожженных токенов, переведенных в транзакции.",
    "Tokens burnt": "Сожженные токены",
    "The value being transacted in Ether and fiat value. Note: You can click the fiat value (if available) to see historical value at the time of transaction.":
      "Передаваемая стоимость в эфире и эквиваленте в фиатной валюте. Примечание: вы можете щелкнуть стоимость в фиатной валюте (если доступно), чтобы увидеть историческое значение на момент транзакции.",
    "Amount paid to process the transaction in Ether and fiat value.":
      "Сумма, уплаченная за обработку транзакции в эфире и эквиваленте в фиатной валюте.",
    "Actual Tx Cost/Fee": "Фактическая стоимость/комиссия за транзакцию",
    "Cost per unit of gas spent for the transaction, in Ether and Gwei.":
      "Стоимость за единицу газа, потраченного на транзакцию, в эфире и Gwei.",
    "Gas Price": "Цена газа",
    "Maximum amount of gas allocated for the transaction & the amount eventually used. Normal ETH transfers involve 21,000 gas units while contracts involve higher values.":
      "Максимальное количество газа, выделенного для транзакции, и использованное в итоге. Обычные переводы ETH включают 21 000 газовых единиц, в то время как контракты включают более высокие значения.",
    "Gas Limit & Usage by Txn": "Лимит газа и использование по Txn",
    "Base Fee refers to the network Base Fee at the time of the block, while Max Fee & Max Priority Fee refer to the max amount a user is willing to pay for their tx & to give to the block producer respectively.":
      "Базовая комиссия относится к базовой комиссии сети на момент блока, в то время как максимальная комиссия и максимальная приоритетная комиссия относятся к максимальной сумме, которую пользователь готов заплатить за свою tx и передать производителю блока соответственно.",
    "Gas Fees": "Комиссия за газ",
    Max: "максимальный",
    "Max Priority": "максимальный приоритет",
    Base: "база",
    "Total amount of ETH burnt from this tx & total fees saved from the amount the user was willing to pay for this tx.":
      "Общая сумма ETH, сожженная этой транзакцией, и общие сбережения по комиссиям от суммы, которую пользователь был готов заплатить за эту транзакцию.",
    "Burnt & Txn Savings Fees":
      "Сожженные и сбереженные комиссии за транзакции",
    Burnt: "сожженный",
    "Txn Savings": "Сбережения по транзакции",
    "Other data related to this transaction.":
      "Другие данные, относящиеся к этой транзакции.",
    "Other Attributes": "Другие атрибуты",
    "Txn Type": "Тип транзакции",
    Position: "позиция",
    Legacy: "наследие",
    "Input Data": "Входные данные",
    Hex: "Шестнадцатеричный",
    "to clipboard": "в буфер обмена",
    "Decode input data": "Декодировать входные данные",
    "Method id": "Идентификатор метода",
    Call: "Вызов",
    Name: "Название",
    Data: "Данные",
    "Logdata to clipboard": "Данные журнала в буфер обмена",
    "Address to clipboard": "Адрес в буфер обмена",
    Indexed: "С индексацией",
    Topics: "Темы",
    Text: "Текст",
    Number: "Номер",
    TO: "до",
    number: "номер",
    "Tx row trace to clipboard":
      "Отслеживание строки транзакции в буфер обмена",
    "No trace entries found.": "Записей отслеживания не найдено.",
    Before: "До",
    After: "После",
    "State Diffrence": "Разница состояния",
    Token: "Токен",
    "Transaction Details": "Детали транзакции",
    "Token transfers": "Переводы токенов",
    Logs: "Логи",
    state: "состояние",
    "Raw trace": "Сырой след",
    Method: "Метод",
    "Careers at BlocksScan": "Карьера в BlocksScan",
    "Join BlocksScan and enhance the world's leading blockchain explorer and search, API &amp; analytics platform.":
      "Присоединяйтесь к BlocksScan и улучшайте ведущий в мире исследователь блокчейна и платформу поиска, API и аналитики.",
    "Perks and Benefits": "Преимущества и льготы",
    "Ours isn’t just a grand vision. We back it up with the company and culture tailored for realizing it.":
      "Наша цель - это не просто великая идея. Мы поддерживаем ее компанией и культурой, созданными для ее реализации.",
    "Free Flow Drinks &amp; Snacks": "Бесплатные напитки и закуски",
    "Never worry about a lack of refreshments or tasty morsels to munch on while adding value to the team.":
      "Не беспокойтесь о нехватке освежающих напитков или вкусных кусочков, которые можно покусать, добавляя ценность команде.",
    "Best Coffee in Town": "Лучший кофе в городе",
    "State-of-the-art coffee machine and experienced baristas in the house for the coffee lovers to thrive.":
      "Современная кофейная машина и опытные бариста на службе у любителей кофе.",
    "Annual Trips": "Годовые поездки",
    "Enjoy a break from the daily grind with sponsored trips to cool new locations and experiences.":
      "Наслаждайтесь перерывом от повседневной рутины с спонсорскими поездками в крутые новые места и впечатления.",
    "Office Entertainment": "Офисное развлечение",
    "Games and gadgets to distract yourself with while thinking up novel solutions to hard problems.":
      "Игры и гаджеты, чтобы отвлечься, придумывая новые решения для сложных проблем.",
    "Food Allowance": "Питание",
    "Extra lunch money provided along with the occasional team lunch and dinners.":
      "Дополнительные деньги на обед, а также случайные обеды и ужины в команде.",
    "Commute Allowance": "Денежная компенсация за проезд",
    "Concerns with transportation and parking eased so that team members can focus on delivering value.":
      "Проблемы с транспортом и парковкой облегчены, чтобы члены команды могли сосредоточиться на достижении целей.",
    "Flex Benefits": "Гибкие льготы",
    "For almost anything that improves your life - training courses, fitness plans... even beauty facials!":
      "Почти для всего, что улучшает вашу жизнь - обучающие курсы, планы фитнеса... даже косметические процедуры!",
    "Medical Benefits": "Медицинское обслуживание",
    "Coverage of outpatient, dental and optical services from a registered clinic of your choice.":
      "Покрытие амбулаторного, стоматологического и офтальмологического обслуживания в зарегистрированной клинике по вашему выбору.",
    "Flexible working hours": "Гибкий график работы",
    "Contribute to the team effort without worrying about what time to clock in.":
      "Вносите свой вклад в работу команды, не беспокоясь о времени начала работы.",
    "Learning Sessions": "Обучающие сессии",
    "Keep up with the latest breakthroughs and best practices in the industry with learned and experienced teammates.":
      "Следите за последними новинками и лучшими практиками в отрасли вместе с учеными и опытными коллегами.",
    "Working at BlocksScan": "Работа в BlocksScan",
    "BlocksScan is a team pioneering at the forefront of the blockchain industry. Our platform provides the infrastructure for users and developers to access and build decentralized applications powering billions of dollars in value.":
      "BlocksScan - это команда, первопроходцы на переднем крае блокчейн-индустрии. Наша платформа обеспечивает инфраструктуру для доступа и создания децентрализованных приложений, создающих миллиарды долларов ценности.",
    "We are made up of diverse backgrounds and skillsets, with a combined passion for providing equitable access to open financial systems. While we strive for excellence in all that we do, we don't take ourselves too seriously either. We love to explore new frontiers and have fun in doing so.":
      "Мы состоим из разнообразных профессиональных и культурных фонов, сочетая страсть к обеспечению равного доступа к открытым финансовым системам. Мы стремимся к совершенству во всем, что делаем, но и не относимся к себе слишком серьезно. Мы любим исследовать новые горизонты и веселиться, делая это.",
    "Understand Contract Functionalities":
      "Понимание функциональности контракта",
    "What are the functions or methods available in this contract?":
      "Какие функции или методы доступны в этом контракте?",
    "Design a WithdrawBalance() method to withdraw contract balance.":
      "Создайте метод WithdrawBalance() для вывода остатка контракта.",
    "What is the logical structure of this contract's code?":
      "Какова логическая структура кода этого контракта?",
    "Is this a proxy contract?": "Это прокси-контракт?",
    "Write me a smart contract of voting.":
      "Напишите мне смарт-контракт для голосования.",
    "What does this contract do?": "Что делает этот контракт?",
    "How does SafeMath change the contract's state?":
      "Как SafeMath изменяет состояние контракта?",
    "Code Reader": "Читатель кода",
    NEW: "новый",
    "Understand and use smart contracts better with CodeRunAI.":
      "Лучше понимайте и используйте смарт-контракты с помощью CodeRunAI.",
    Mode: "Режим",
    "I have an API Key": "У меня есть ключ API",
    "Any open source contract": "Любой открытый исходный код контракта",
    "Any question": "Любой вопрос",
    "I don't have an API Key": "У меня нет ключа API",
    "Popular open source contracts":
      "Популярные открытые исходные коды контрактов",
    "Example questions": "Примеры вопросов",
    DEMO: "Демо",
    "OpenAI API key": "Ключ OpenAI API",
    "Where to find my API Key?": "Где найти мой ключ API?",
    "Save it to the browser": "Сохраните его в браузере",
    "GPT Model": "Модель GPT",
    "Smart Contract": "Смарт-контракт",
    "Contract Address": "Адрес контракта",
    "Contract Code": "Код контракта",
    Load: "Загрузить",
    Trending: "Тренды",
    "Contract Files": "Файлы контракта",
    "Source Code": "Исходный код",
    "Popular Contracts": "Популярные контракты",
    "No Record Found": "Запись не найдена",
    "Loading...": "Загрузка...",
    "Hi, there.": "Привет.",
    "I can help you in the following scenarios":
      "Я могу помочь в следующих сценариях",
    ME: "Я",
    "Enter any question you are interested in":
      "Введите любой интересующий вас вопрос",
    Signer: "Подписант",
    "Send native": "Отправить нативный",
    "Add one more": "Добавить еще один",
    Read: "Читать",
    Write: "Писать",
    Loading: "Загрузка",
    "Method Name": "Имя метода",
    Permalink: "Постоянная ссылка",
    Code: "Код",
    "Read Contract": "Читать контракт",
    "Read Proxy": "Читать прокси",
    "Write Contract": "Писать контракт",
    "Write Proxy": "Писать прокси",
    "Contract Source Code Verified": "Проверенный исходный код контракта",
    "Are you the contract creator": "Вы создатель контракта",
    "Verify And Publish": "Проверить и опубликовать",
    "your contract source code today": "ваш исходный код контракта сегодня",
    "EVM Version": "Версия EVM",
    "Optimization Runs": "Оптимизационные запуски",
    "Contract File Path": "Путь к файлу контракта",
    "Compiler Version": "Версия компилятора",
    "Optimization Enabled": "Оптимизация включена",
    "Verified At": "Проверено в",
    "Constructor Arguments": "Аргументы конструктора",
    "ABI-encoded": "ABI-кодированный",
    "Byte Code": "Байт-код",
    File: "Файл",
    of: "из",
    "ABI for the implementation contract at": "ABI для контракта реализации на",
    "Type of Backing": "Тип поддержки",
    "Type of Peg": "Тип штыря",
    "Collater­alization": "Залог",
    "Storage Of Assets": "Хранение активов",
    Market: "Рынок",
    "Market Capitalization": "Рыночная капитализация",
    "Circulating Supply": "Оборотное предложение",
    "Click here to": "Нажмите здесь, чтобы",
    update: "обновить",
    "the token information / general information":
      "информацию о токене / общую информацию",
    Metadata: "Метаданные",
    Missing: "Отсутствует",
    Chain: "Цепь",
    "Portfolio %": "Портфель %",
    Price: "Цена",
    Amount: "Количество",
    Showing: "Показ",
    "tokens with a value of": "токенов на сумму",
    Sponsored: "Спонсированный",
    Complier: "Компилятор",
    "Token Name": "Имя токена",
    "Total Holders": "Всего держателей",
    "Collection Name": "Имя коллекции",
    Validator: "Валидатор",
    in: "в",
    View: "Просмотр",
    "Latest Transactions": "Последние транзакции",
    "Latest Blocks": "Последние блоки",
    Customize: "Настроить",
    "NFT Address": "NFT Адрес",
    "all Blocks": "все блоки",
    "Top Tokens": "Топ токены",
    "Top Contracts": "Топ контракты",
    "Top NFTs": "Топ NFT",
    "Ask AI | Search by Address | Txn Hash | Block | Token... ":
      "Поиск по адресу / хэшу транзакции / блоку / токену... ",
    "No transactions found.": "Транзакции не найдены.",
    "Market Cap": "Капитализация рынка",
    "Total Transactions": "Общий объем сделок",
    "MED GAS PRICE": "Цена MED GAS",
    "TOTAL BLOCK": "Всего блоков",
    "GasTracker Estimate for Average Gas":
      "Оценка средней стоимости газа от GasTracker",
    "Total Accounts": "Всего учетных записей",
    "Daily Transactions": "Ежедневные транзакции",
    "Contact Us": "Связаться с нами",
    "Feedback Form": "Форма обратной связи",
    "Please understand that we can only assist with issues directly related to the block explorer services. Before writing in, please be informed that":
      "Пожалуйста, поймите, что мы можем помочь только с проблемами, непосредственно связанными с услугами блок-эксплорера. Прежде чем писать, убедитесь в следующем:",
    "Pending Transaction": "Ожидающие транзакции",
    "We do not": "Мы не",
    "process transactions": "обрабатываем транзакции",
    "and are therefore unable to expedite, cancel or replace them. If you are having issues with pending transactions, please refer":
      "и поэтому не можем ускорить, отменить или заменить их. Если у вас возникли проблемы с ожидающими транзакциями, обратитесь",
    here: "сюда",
    "Blocksscan is an independent": "Blocksscan - независимый",
    "block explorer": "блок-эксплорер",
    "unrelated to other service providers (unless stated explicitly otherwise) and is therefore unable to provide a precise response for inquiries that are specific to other service providers.":
      "не имеющий отношения к другим поставщикам услуг (если не указано явно иначе), поэтому не в состоянии предоставить точный ответ на запросы, касающиеся других поставщиков услуг.",
    "Knowledge Base": "База знаний",
    "The links on the right might already have the answer to your inquiry. If they don't, perhaps one of the other articles in our":
      "Ссылки справа могут уже содержать ответ на ваш запрос. Если нет, возможно, вам понадобится одна из других статей нашего",
    "page does.": "страницы.",
    "Community Support": "Поддержка сообщества",
    "We will never ask for your private keys or passwords.":
      "Мы никогда не попросим ваши секретные ключи или пароли.",
    "NEVER send us these.": "НИКОГДА не отправляйте нам их.",
    "While we try our best to respond in a timely manner, we often have a backlog of inquiries. Please do not":
      "Хотя мы стараемся отвечать своевременно, у нас часто накапливается задолженность по запросам. Пожалуйста, не",
    "spam us": "рассылайте нам спам",
    "Wallet / Exchange / Project related issues":
      "Проблемы, связанные с кошельком / обменом / проектом",
    "Kindly reach out to your wallet service provider, exchanges or project/contract owner for further support as they are in a better position to assist you on the issues related to and from their platforms.":
      "Пожалуйста, обратитесь к поставщику услуг кошелька, бирже или владельцу проекта/контракта для получения дальнейшей поддержки, поскольку они находятся в более выгодном положении для помощи в вопросах, связанных с их платформами.",
    Subject: "Тема",
    "Please Select Your Message Subject": "Выберите тему вашего сообщения",
    "General Inquiry": "Общий запрос",
    Advertising: "Реклама",
    "Update Token Info": "Обновить информацию о токене",
    "Add Name Tag/Label": "Добавить имя/метку",
    "Request Removal of Name Tag": "Запрос на удаление имени/метки",
    "Suggest Transaction Action": "Предложить действие по транзакции",
    "Update Proxy Contract's Implementation Address":
      "Обновить адрес реализации контракта-посредника",
    "Report Phishing Address": "Сообщить о фишинговом адресе",
    "Security Audit": "Аудит безопасности",
    "Priority Support": "Приоритетная поддержка",
    "API Support": "Поддержка API",
    "1. Inquiries": "1. Запросы",
    "2. Submissions": "2. Подачи",
    "3. Security": "3. Безопасность",
    "Help & Support": "Помощь и поддержка",
    "24/7 support. Our team is here to help you and make sure your product is up to date.":
      "Поддержка 24/7. Наша команда здесь, чтобы помочь вам и убедиться, что ваш продукт актуален.",
    Sales: "Продажи",
    "Have a presale question about our products and features? Or looking for a refund?":
      "У вас есть вопросы о предварительной продаже наших продуктов и функций? Или вы ищете возврат денег?",
    "Media & Press": "СМИ и пресса",
    "Online awards and publications. Get our online media resources and learn about our company.":
      "Онлайн-награды и публикации. Получите наши онлайн-медиа-ресурсы и узнайте о нашей компании.",
    "Tx Hash|Block|Age": "Хэш Tx|Блок|Возраст",
    "12 days ago": "12 дней назад",
    "Event Logs": "Журнал событий",
    Mint: "Отчеканивание",
    Transaction: "Транзакция",
    Success: "Успешно",
    Failed: "Не удалось",
    Pending: "В ожидании",
    "Contract call": "Вызов контракта",
    "Contract creation": "Создание контракта",
    "Token transfer": "Передача токена",
    "Token creation": "Создание токена",
    "Coin transfer": "Передача монет",
    REMASC: "Управление вознаграждением через смарт-контракт",
    Bridge: "Мост",
    Rank: "Ранг",
    Quantity: "Количество",
    Item: "Элемент",
    Details: "Детали",
    ID: "ID",
    "Last Traded": "Последняя сделка",
    "Estimated Value on Day of Txn": "Предполагаемая стоимость на день сделки",
    "Displaying current Value; Click to show Value on Day of Txn":
      "Отображение текущей стоимости; Нажмите, чтобы показать стоимость на день сделки",
    Action: "Действие",
    "Verify & Publish Contract Source Code":
      "Проверить и опубликовать исходный код контракта",
    "COMPILER TYPE AND VERSION SELECTION": "ТИП КОМПИЛЯТОРА И ВЫБОР ВЕРСИИ",
    "Contract Licence": "Лицензия контракта",
    "For best practices, all contract source code holders, publishers and authors are encouraged to also specify the accompanying license for their verified contract source code provided.":
      "Для соблюдения лучших практик все владельцы исходного кода контракта, издатели и авторы также призываются указывать сопутствующую лицензию для предоставленного проверенного исходного кода контракта.",
    "Please select": "Пожалуйста, выберите",
    "Verification Method": "Метод верификации",
    "Compiler Type": "Тип компилятора",
    "Enter name here...": "Введите имя здесь...",
    "Is Yul Contract": "Является ли контракт Yul",
    "The compiler version is specified in pragma solidity X.X.X. Use the compiler version rather than the nightly build. If using the Solidity compiler, run solc —version to check.":
      "Версия компилятора указывается в pragma solidity X.X.X. Используйте версию компилятора, а не ночную сборку. Если используется компилятор Solidity, выполните solc —version, чтобы проверить.",
    "The EVM version the contract is written for. If the bytecode does not match the version, we try to verify using the latest EVM version.":
      "Версия EVM, для которой написан контракт. Если байткод не соответствует версии, мы пытаемся выполнить проверку с использованием последней версии EVM.",
    "Type here ...": "Введите здесь ...",
    "We recommend using flattened code. This is necessary if your code utilizes a library or inherits dependencies. Use the POA solidity flattener or the Truffle flattener.":
      "Мы рекомендуем использовать сплющенный код. Это необходимо, если ваш код использует библиотеку или наследует зависимости. Используйте сплющиватель POA Solidity или сплющиватель Truffle.",
    "Enter Source Code...": "Введите исходный код...",
    "Standard JSON Input": "Стандартный входной JSON",
    "Sources and Metadata JSON":
      "Исходные файлы *.sol или *.yul и метаданные JSON",
    "Sources *.sol or *.yul files": "Исходные файлы *.sol или *.yul",
    "Main Interfaces (.vy or .json)": "Основные интерфейсы (.vy или .json)",
    "Upload the standard input JSON file created during contract compilation":
      "Загрузите стандартный входной JSON-файл, созданный во время компиляции контракта",
    "Upload all Solidity contract source files and JSON metadata file(s) created during contract compilation.":
      "Загрузите все исходные файлы контрактов Solidity и файлы метаданных JSON, созданные во время компиляции контракта.",
    "Upload all Solidity or Yul contract source files":
      "Загрузите все исходные файлы контрактов Solidity или Yul",
    "Primary compiled Vyper contract. Only add the main contract here whose bytecode has been deployed, all other files can be uploaded to the interfaces box below":
      "Основной скомпилированный Vyper-контракт. Здесь добавляйте только основной контракт, байткод которого был развернут, все остальные файлы можно загрузить в интерфейсный блок ниже.",
    "Drag & drop files here, or click to select files":
      "Перетащите файлы сюда или нажмите, чтобы выбрать файлы",
    "Verify & Publish": "Проверить и опубликовать",
    Reset: "Сброс",
    "Gas tracker": "Трекер газа",
    "Network utilization": "Использование сети",
    "low load": "низкая загрузка",
    "Last updated": "Последнее обновление",
    Fast: "Быстро",
    Normal: "Нормально",
    Slow: "Медленно",
    "Gas price history": "История цен на газ",
    "Charts & stats": "Графики и статистика",
    "Account Balance Checker": "Проверка баланса аккаунта",
    "You can Lookup the Account": "Вы можете искать аккаунт",
    "Historical Balance at a specific Block No or Date.":
      "Исторический баланс на определенном блоке или дате.",
    Account: "Аккаунт",
    "Date for the snapshot": "Дата снимка",
    "Block Number": "Номер блока",
    Lookup: "Поиск",
    "Choose desired language": "Выберите желаемый язык",
    "Choose the theme": "Выберите тему",
    "Dark Mode": "Темный режим",
    "Do you want to show relative time? eg.":
      "Хотите показать относительное время? например.",
    "Do you want to show UTC time? eg.": "Хотите показать время UTC? например.",
    "Change Fiat Currency": "Изменить фиатную валюту",
    Use: "Использовать",
    "prefix instead of": "вместо",
    "NFT Latest Mints": "Последние выпуски NFT",
    "Token Id": "Идентификатор токена",
    "NFT Transfer": "Передача NFT",
    "Your browser does not support the video tag.":
      "Ваш браузер не поддерживает тег видео.",
    "Min Price": "Минимальная цена",
    "Last Sale": "Последняя продажа",
    "Current owner of this NFT.": "Текущий владелец этого NFT.",
    "Address of this NFT contract.": "Адрес этого NFT-контракта",
    "Address that deployed this NFT contract.":
      "Адрес, который развернул этот NFT-контракт.",
    Creator: "Создатель",
    "Classification of NFT based on how the image is stored: On-Chain refers to those with data stored on the blockchain while Off-Chain refers to those with data stored elsewhere on the Internet.":
      "Классификация NFT основана на том, как изображение сохранено: On-Chain означает те, у которых данные хранятся на блокчейне, в то время как Off-Chain означает те, у которых данные хранятся в другом месте в Интернете.",
    Classification: "Классификация",
    "This NFT's unique token ID.": "Уникальный идентификатор токена этого NFT.",
    "The standard followed by this NFT.": "Стандарт, соблюдаемый этим NFT.",
    "Token Standard": "Стандарт токена",
    "Marketplaces trading this NFT.": "Рынки, торгующие этим NFT.",
    Marketplaces: "Рынки",
    Properties: "Свойства",
    Description: "Описание",
    Collection: "Коллекция",
    Volume: "Объем",
    Change: "Изменение",
    "Max Price": "Максимальная цена",
    "NFT Top Mints": "Лучшие выпуски NFT",
    Owners: "Владельцы",
    "Total Assets": "Всего активов",
    "Note: Filtered by  totalSupply() , method returned a zero value.":
      "Примечание: Отфильтровано по totalSupply(), метод вернул нулевое значение.",
    "A Paid Service": "Платная услуга",
    "At BlocksScan, we handle a substantial volume of support requests daily. While we strive our best to respond to each one in a timely manner, we recognize that certain projects may want to submit their token information quicker than our standard turnaround time.":
      "В BlocksScan мы ежедневно обрабатываем значительный объем запросов на поддержку. Несмотря на то что мы делаем все возможное, чтобы отвечать на каждый вовремя, мы понимаем, что некоторым проектам может потребоваться быстрее предоставить информацию о своих токенах, чем наше стандартное время обработки.",
    "In addition to expediting token updates, we also provide priority support for other urgent or technical matters, such as contract verification.":
      "Помимо ускорения обновлений токенов, мы также предоставляем приоритетную поддержку для других срочных или технических вопросов, таких как проверка контрактов.",
    "Kindly follow the simple steps below to get your support prioritized.":
      "Пожалуйста, следуйте простым шагам ниже, чтобы получить приоритетную поддержку.",
    "Get Started": "Начать",
    "How to Start": "Как начать",
    "Simple steps for getting Priority Support.":
      "Простые шаги для получения приоритетной поддержки.",
    "Step 1": "Шаг 1",
    "Check Token Information": "Проверьте информацию о токене",
    "Ensure that token information is submitted. Check our token update guidelines":
      "Убедитесь, что информация о токене отправлена. Проверьте наши рекомендации по обновлению токенов",
    "Step 2": "Шаг 2",
    "Provide Token Update Request": "Предоставьте запрос на обновление токена",
    "Provide us with the token update request information in the contact form below.":
      "Предоставьте нам информацию о запросе на обновление токена в контактной форме ниже.",
    "Step 3": "Шаг 3",
    "We'll Review Your Request": "Мы рассмотрим ваш запрос",
    "Our team will review your request and contact you using an official":
      "Наша команда рассмотрит ваш запрос и свяжется с вами с использованием официального",
    "Please use this form to get in touch for priority support or featured listing with our team.":
      "Пожалуйста, используйте эту форму, чтобы связаться с нами для получения приоритетной поддержки или размещения в списке с нашей командой.",
    "Requester Name": "Имя запросившего",
    "Requester Email": "Электронная почта запросившего",
    "Token Contract Address": "Адрес контракта токена",
    "Choose Priority Service": "Выберите приоритетную услугу",
    "Token Update": "Обновление токена",
    "Featured Listing": "Рекомендуемый список",
    "Contract Verification": "Проверка контракта",
    Others: "Другие",

    "Learn more about": "Узнать больше о",
    "Helpdesk Ticket Number": "Номер тикета службы поддержки",
    "Additional Notes": "Дополнительные заметки",
    Note: "Примечание",
    "We will": "Мы будем",
    NOT: "НЕ",
    "request payment via direct deposit. All payments will be directed through our official payment channel.":
      "запрашивать оплату через прямой депозит. Все платежи будут осуществляться через наш официальный платежный канал.",
    NEVER: "НИКОГДА",
    "ask for your private key or seed words. Please":
      "просить ваш приватный ключ или сид-фразу. Пожалуйста",
    "DO NOT SHARE": "НЕ ДЕЛИТЕСЬ",
    "your private keys with anyone.": "своими приватными ключами с кем-либо.",
    "Send Message": "Отправить сообщение",
    "Ex. John Doe": "Пример - Джон Доу",
    "If you have any additional notes, please leave them here":
      "Если у вас есть дополнительные заметки, пожалуйста, оставьте их здесь.",
    "Privacy Policy": "Политика конфиденциальности",
    "Welcome to Blocksscan Explorer Services":
      "Добро пожаловать в сервис исследования Blocksscan",
    we: "мы",
    us: "нас",
    our: "наш",
    "We are committed to respecting the privacy and security of our users' information. This Privacy Policy outlines the types of information we may collect from you or that you may provide when you use our services, and our practices for collecting, using, maintaining, protecting, and disclosing that information.":
      "Мы стремимся уважать конфиденциальность и безопасность информации наших пользователей. Настоящая Политика конфиденциальности описывает типы информации, которые мы можем собирать от вас или которые вы можете предоставить при использовании наших услуг, а также наши методы сбора, использования, хранения, защиты и раскрытия этой информации.",
    "Information We Collect": "Собираемая нами информация",
    "We collect several types of information from and about users of our Blocksscan Explorer Services, including":
      "Мы собираем несколько типов информации от пользователей наших сервисов исследования Blocksscan, включая",
    "Personal Information: Data that personally identifies you, such as your name, email address, and payment details, provided at registration or during the use of our services.":
      "Персональная информация: данные, которые лично идентифицируют вас, такие как ваше имя, адрес электронной почты и данные платежей, предоставляемые при регистрации или при использовании наших услуг.",
    "Non-Personal Information: Data that does not personally identify you but is linked to your usage of our services, such as IP addresses, browser types, and access times.":
      "Неперсональная информация: данные, которые не идентифицируют вас лично, но связаны с вашим использованием наших услуг, такие как IP-адреса, типы браузеров и время доступа.",
    "Transactional Information: Data related to transactions conducted on the network, which may include public blockchain data.":
      "Информация о транзакциях: данные, связанные с проводимыми транзакциями в сети, которые могут включать данные общедоступного блокчейна.",
    "How We Use Your Information": "Как мы используем вашу информацию",
    "We use the information that we collect about you or that you provide to us, including any personal information":
      "Мы используем собранную о вас информацию или предоставленную вами информацию, включая любую личную информацию",
    "To present our services and its contents to you.":
      "Чтобы представить вам наши услуги и их содержимое.",
    "To provide you with information, products, or services that you request from us.":
      "Чтобы предоставить вам информацию, продукты или услуги, которые вы запросили у нас.",
    "To fulfill any other purpose for which you provide it.":
      "Для выполнения любых других целей, для которых вы предоставляете информацию.",
    "To carry out our obligations and enforce our rights arising from any contracts entered into between you and us.":
      "Для выполнения наших обязательств и защиты наших прав, вытекающих из заключенных между вами и нами контрактов.",
    "To notify you about changes to our service or any products or services we offer or provide through it.":
      "Чтобы уведомить вас о изменениях в наших услугах или предлагаемых или предоставляемых через них продуктах или услугах.",
    "For any other purpose with your consent.":
      "Для любых других целей с вашего согласия.",
    "Data Security": "Безопасность данных",
    "We will retain your personal information for as long as your account is active or as needed to provide you services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.":
      "Мы будем хранить вашу личную информацию до тех пор, пока ваша учетная запись активна или пока это необходимо для предоставления вам услуг. Мы будем хранить и использовать вашу информацию по мере необходимости для выполнения наших юридических обязательств, разрешения споров и обеспечения соблюдения наших соглашений.",
    "Your Rights": "ваши права",
    "You have the right to request access to, correct, or delete any personal information that you have provided to us. You may also object to or request restrictions on the processing of your personal information.":
      "У вас есть право запрашивать доступ, корректировку или удаление любой личной информации, которую вы предоставили нам. Вы также можете возразить или запросить ограничения на обработку ваших персональных данных.",
    "Changes to Our Privacy Policy":
      "Изменения в нашей политике конфиденциальности",
    "It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you and for periodically visiting our website and this privacy policy to check for any changes.":
      "Мы публикуем любые изменения в нашей политике конфиденциальности на этой странице. Дата последнего изменения политики конфиденциальности указана вверху страницы. Вы несете ответственность за обеспечение наличия у нас актуального, действующего и доставляемого почтового адреса для вас, а также за периодическое посещение нашего веб-сайта и этой политики конфиденциальности для проверки наличия каких-либо изменений.",
    "Contact Information": "контактная информация",
    "To ask questions or comment about this privacy policy and our privacy practices, contact us at":
      "Чтобы задать вопросы или прокомментировать нашу политику конфиденциальности и практики ее соблюдения, свяжитесь с нами по адресу:",
    "By using Blocksscan Explorer Services, you consent to our Privacy Policy.":
      "Используя услуги Blocksscan Explorer, вы соглашаетесь с нашей Политикой конфиденциальности.",
    "Max Total Supply": "Максимальное общее предложение",
    "Note: Filtered by  totalSupply() , method returned a zero value":
      "Примечание: отфильтровано по totalSupply(), метод вернул нулевое значение.",
    Holders: "Держатели",
    "Total Transfers": "Общие переводы",
    Implementation: "Внедрение",
    "Fully Diluted Market Cap": "Полностью разбавленная рыночная капитализация",
    "Circulating Supply Market Cap":
      "Рыночная капитализация циркулирующего предложения",
    "Other Info": "Другая информация",
    "Token Contract": "Контракт токена",
    "FILTERED BY TOKEN HOLDER": "ОТФИЛЬТРОВАНО ПО ВЛАДЕЛЬЦУ ТОКЕНА",
    BALANCE: "Баланс",
    Inventory: "Инвентарь",
    Info: "Информация",
    With: "с",
    decimals: "десятичные",
    "Volume 24h": "Объем 24 часа",
    "Circulating Market Cap":
      "Рыночная капитализация обращающегося предложения",
    "Onchain Market Cap": "Рыночная капитализация Onchain",
    "Token Transactions": "Токен операции",
    "X (Twitter)": "X (Твиттер)",
    Github: "Гитхаб",
    Facebook: "Фейсбук",
    Instagram: "Инстаграм",
    "Back to Top": "Назад к началу",
    "Powered by BlocksScan": `Питание ${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"}`,
    "BlocksScan is a Block Explorer and Analytics Platform for":
      `${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"} - это исследователь блоков и аналитическая платформа для`,
    "a decentralized smart contracts platform.":
      "децентрализованной платформы смарт-контрактов.",
    Company: "Компания",
    "About Us": "О нас",
    "Brand Assets": "Брендовые активы",
    Careers: "Карьера",
    "We're Hiring": "Мы ищем сотрудников",
    Terms: "Условия",
    Privacy: "Конфиденциальность",
    Community: "Сообщество",
    "API Documentation": "Документация API",
    "Network Status": "Статус сети",
    Newsletters: "Рассылки",
    Products: "Продукты",
    Services: "Услуги",
    "Explorer-as-a-Service": "Исследователь как услуга",
    "API Plans": "Планы API",
    "All Rights Reserved": "Все права защищены",
    Donations: "Пожертвования",
    Home: "Главная",
    CodeRunAI: "CodeRunAI",
    Blockchain: "Блокчейн",
    Live: "Прямой эфир",
    WIP: "WIP",
    "Pending Transactions": "Ожидающие транзакции",
    "Contract Internal Transactions": "Внутренние транзакции контракта",
    "View Blocks": "Просмотр блоков",
    "Top Accounts": "Лучшие аккаунты",
    "Token Transfers": "Передача токенов",
    "Top Mints": "Лучшие монеты",
    "Latest Trades": "Последние сделки",
    "Latest Transfers": "Последние переводы",
    "Latest Mints": "Последние монеты",
    Resources: "Ресурсы",
    "Charts And Stats": "Диаграммы и статистика",
    "Top Statistics": "Топ статистика",
    Leaderboard: "Таблица лидеров",
    Directory: "Справочник",
    Developers: "Разработчики",
    "Verify Contract": "Проверить контракт",
    "Similar Contract Search": "Поиск похожего контракта",
    "Smart Contract Search": "Поиск смарт-контрактов",
    "Contract Diff Checker": "Проверка различий контрактов",
    "Vyper Online Compiler": "Онлайн компилятор Vyper",
    "Bytecode to Opcode": "Байткод в опкод",
    "Broadcast Transaction": "Трансляция транзакции",
    More: "Еще",
    "Balance Checker": "Проверка баланса",
    "Gas Tracker": "Трекер газа",
    Light: "Светлый",
    Dim: "Тусклый",
    Dark: "Темный",
    Gas: "Газ",
    "Last Updated": "Последнее обновление",
    "Gas tracker overview": "Обзор трекера газа",
    "All Transactions": "Все транзакции",
    "Custom Card": "Настраиваемая карта",
    "Customize this card by selecting one of the options below":
      "Настройте эту карту, выбрав один из вариантов ниже",
    "Advanced Filters": "Расширенные фильтры",
    Present: "настоящее время",
    "Saved Filters": "Сохраненные фильтры",
    Close: "Закрыть",
    "Save Changes": "Сохранить изменения",
    "Top NFT": "Лучшие NFT",
    "Top Contract": "Топ контракт",
    "Top Token": "Топ токен",
    "Latest Transaction": "Последняя транзакция",
    "Latest Block": "Последний блок",
  },
};
