export const tu = {
  translations: {
    "ABI for the implementation contract at": "Uygulama sözleşmesinin ABI'si",
    "ABI-encoded": "ABI kodlu",
    "API Documentation": "API Belgeleri",
    "API Plans": "API Planları",
    "API Support": "API Desteği",
    "About Us": "Hakkımızda",
    "Account": "Hesap",
    "Account Balance Checker": "Hesap Bakiyesi Kontrolcüsü",
    "Action": "Aksiyon",
    "Add Name Tag/Label": "Ad Etiketi/Etiket Ekle",
    "Add one more": "Bir tane daha ekle",
    "Additional Info": "Ek Bilgi",
    "Additional Notes": "Ek Notlar",
    "Address": "Adres",
    "Address of this NFT contract.": "Bu NFT sözleşmesinin adresi.",
    "Address that deployed this NFT contract.": "Bu NFT sözleşmesini dağıtan adres.",
    "Advertising": "Reklam",
    "Age": "Yaş",
    "All Rights Reserved": "Tüm Hakları Saklıdır",
    "All Transactions": "Tüm İşlemler",
    "Amount": "Miktar",
    "Analytics": "Analizler",
    "Annual Trips": "Yıllık Geziler",

    "Are you the contract creator": "Siz sözleşme oluşturucusu musunuz",
    "Back to Top": "Başa Dön",
    "Balance": "Bakiye",
    "Balance Checker": "Bakiye Kontrolcüsü",
    "Base": "Temel",
    "Base Fee": "Temel Ücret",
    "Base Fee Per Gas": "Gaz Başına Temel Ücret",
    "Best Coffee in Town": "Şehirdeki En İyi Kahve",
    "Block": "Blok",
    "Block Height": "Blok Yüksekliği",
    "Block Number": "Blok Numarası",
    "Block Reward": "Blok Ödülü",
    "Block header withdrawal root hash.": "Blok başlık geri çekme kökü karması.",
    "Block nonce is a value used during mining to demonstrate proof of work for a block.": "Blok nonce, bir blok için iş kanıtını göstermek için madencilik sırasında kullanılan bir değerdir.",
    "Block proposed on epoch": "Blok önerisi yapılan çağ",
    "Blocksscan is an independent": "Blocksscan bağımsız bir",
    "Blockchain": "Blockchain",
    "Blocks": "Bloklar",
    "Broadcast Transaction": "İşlem Yayını",
    "Brand Assets": "Marka Varlıkları",
    "Bridge": "Köprü",
    "Burnt": "Yakılan",
    "Burnt & Txn Savings Fees": "Yakılan ve İşlem Tasarruf Ücretleri",
    "Byte Code": "Bayt Kodu",
    "Bytecode to Opcode": "Bayt kodunu Opcode'a",
    "By using Blocksscan Explorer Services, you consent to our Privacy Policy.": "Blocksscan Explorer Hizmetleri'ni kullanarak, Gizlilik Politikamızı kabul etmiş olursunuz.",
    "CSV Export": "CSV Dışa Aktar",
    "Call": "Çağrı",
    "Capacity": "Kapasite",
    "Careers": "Kariyerler",
    "Careers at BlocksScan": "BlocksScan'de Kariyerler",
    "Change Fiat Currency": "Fiat Para Birimini Değiştir",
    "Change": "Değiştir",
    "Charts & stats": "Grafikler ve İstatistikler",
    "Charts And Stats": "Grafikler ve İstatistikler",
    "Choose Priority Service": "Öncelikli Hizmeti Seç",
    "Choose desired language": "İstenen dili seçin",
    "Choose the theme": "Temayı seç",
    "Circulating Market Cap": "Dolaşımdaki Piyasa Değeri",
    "Circulating Supply": "Dolaşımdaki Arz",
    "Circulating Supply Market Cap": "Dolaşımdaki Arz Piyasa Değeri",
    "Classification of NFT based on how the image is stored: On-Chain refers to those with data stored on the blockchain while Off-Chain refers to those with data stored elsewhere on the Internet.": "Resmin nasıl saklandığına göre NFT'nin sınıflandırılması: On-Chain, verilerin blok zincirinde saklandığıyla ilgiliyken, Off-Chain, verilerin başka bir yerde İnternet'te saklandığıyla ilgilidir.",
    "Classification": "Sınıflandırma",
    "Click here to": "Buraya tıklayın",
    "Click to show less": "Daha az göstermek için tıklayın",
    "Click to show more": "Daha fazla göstermek için tıklayın",
    "Close": "Kapat",
    "Code": "Kod",
    "Code Reader": "Kod Okuyucu",
    "CodeRunAI": "CodeRunAI",
    "Coin transfer": "Coin transferi",
    "Collater­alization": "Teminatlandırma",
    "Collection": "Koleksiyon",
    "Collection Name": "Koleksiyon Adı",
    "Commute Allowance": "Yol Harcırahı",
    "Community": "Topluluk",
    "Community Support": "Topluluk Desteği",
    "Company": "Şirket",
    "Compiler": "Derleyici",
    "Compiler Type": "Derleyici Türü",
    "Compiler Version": "Derleyici Sürümü",
    "COMPILER TYPE AND VERSION SELECTION": "DERLEYİCİ TÜRÜ VE SÜRÜMÜ SEÇİMİ",
    "ConfirmationToken": "Onay Kodu",
    "Confirmation": "Onay",
    "Confirmed within": "İçinde onaylandı",
    "Confirming": "Onaylanıyor",
    "Contact Us": "Bize Ulaşın",
    "Contact Information": "İletişim Bilgileri",
    "Contract": "Sözleşme",
    "Contract Address": "Sözleşme Adresi",
    "Contract Code": "Sözleşme Kodu",
    "Contract Diff Checker": "Sözleşme Fark Denet İşlemi",
    "Contract File Path": "Sözleşme Dosya Yolu",
    "Contract Internal Transactions": "Sözleşme İç İşlemler",
    "Contract Licence": "Sözleşme Lisansı",
    "Contract Name": "Sözleşme Adı",
    "Contract Source Code Verified": "Sözleşme Kaynak Kodu Doğrulandı",
    "Contract Verification": "Sözleşme Doğrulaması",
    "Contract call": "Sözleşme çağrısı",
    "Contract creation": "Sözleşme oluşturma",
    "Contracts": "Sözleşmeler",
    "Contracts Internal": "Sözleşmeler İç",
    "Cost per unit of gas spent for the transaction, in Ether and Gwei.": "İşlem başına harcanan gaz birimi maliyeti, Ether ve Gwei cinsinden.",
    "Creator": "Oluşturan",
    "Created": "Oluşturuldu",
    "Current owner of this NFT.": "Bu NFT'nin mevcut sahibi.",
    "Custom Card": "Özel Kart",
    "Customize": "Özelleştir",
    "Customize this card by selecting one of the options below": "Aşağıdaki seçeneklerden birini seçerek bu kartı özelleştirin",
    "Daily Transactions": "Günlük İşlemler",
    "Dark": "Karanlık",
    "Dark Mode": "Karanlık Mod",
    "Data": "Veri",
    "Data Security": "Veri Güvenliği",
    "Date for the snapshot": "Anlık görüntü için tarih",
    "Decode input data": "Giriş verilerini çöz",
    "Details": "Detaylar",
    "Development": "Geliştirme",
    "Dim": "Sönük",
    "Directory": "Dizin",
    "Display All Chains": "Tüm Zincirleri Göster",
    "Displaying current Value; Click to show Value on Day of Txn": "Mevcut Değeri Gösteriliyor; İşlem Günündeki Değeri Göstermek İçin Tıklayın",
    "Donations": "Bağışlar",
    "Drag & drop files here, or click to select files": "Dosyaları buraya sürükleyin ve bırakın veya dosyaları seçmek için tıklayın",
    "Download": "İndir",
    "Downloaded": "İndirildi",
    "EVM Version": "EVM Sürümü",
    "Encode": "Kodla",
    "Encoded": "Kodlanmış",
    "Encoding": "Kodlama",
    "Enter Source Code...": "Kaynak Kodunu Girin...",
    "Enter name here...": "Buraya adınızı girin...",
    "Ensure that token information is submitted. Check our token update guidelines": "Token bilgilerinin gönderildiğinden emin olun. Token güncelleme yönergelerimizi kontrol edin",
    "Event Logs": "Olay Günlükleri",
    "Events": "Olaylar",
    "Ex. John Doe": "Örn. John Doe",
    "Example questions": "Örnek sorular",
    "Explorer-as-a-Service": "Hizmet Olarak Araştırmacı",
    "Export": "Dışa Aktar",
    "Extra Data": "Ek Veri",
    "Extra lunch money provided along with the occasional team lunch and dinners.": "Zaman zaman ekibin öğle yemeği ve akşam yemekleri ile birlikte ek öğle yemeği parası sağlanmaktadır.",
    "FAQ": "SSS",
    "Failed": "Başarısız",
    "Fast": "Hızlı",
    "Fee": "Ücret",
    "Fee Recipient": "Ücret Alıcı",
    "Feedback Form": "Geribildirim Formu",
    "File": "Dosya",
    "Files": "Dosyalar",
    "Filter": "Filtre",
    "Filters": "Filtreler",
    "Filtered": "Filtrelendi",
    "Finalized": "Tamamlandı",
    "Flex Benefits": "Esnek Faydalar",
    "Flexible working hours": "Esnek çalışma saatleri",
    "Food Allowance": "Yemek Yardımı",
    "For": "İçin",
    "For almost anything that improves your life - training courses, fitness plans... even beauty facials!": "Hayatınızı iyileştiren hemen hemen her şey için - eğitim kursları, fitness planları... hatta güzellik bakımları!",
    "For best practices, all contract source code holders, publishers and authors are encouraged to also specify the accompanying license for their verified contract source code provided.": "En iyi uygulamalar için, tüm sözleşme kaynak kodu sahiplerinin, yayıncılarının ve yazarlarının, doğrulanmış sözleşme kaynak kodları için eşlik eden lisansı da belirtmeleri teşvik edilir.",
    "For each block, the block producer is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block.": "Her bir blok için, blok üreticisi, bloktaki tüm işlemler için ödenen ücretlerin üstüne belirli bir miktarda Ether ile ödüllendirilir.",
    "Forums": "Forumlar",
    "Free Flow Drinks & Snacks": "Serbest Akış İçecekler ve Ara Öğünler",
    "From": "Kimden",
    "Fully Diluted Market Cap": "Tamamen Sulandırılmış Piyasa Değeri",
    "Functionality": "İşlevsellik",
    "Functions": "Fonksiyonlar",
    "Gas": "Gaz",
    "Gas Fees": "Gaz Ücretleri",
    "Gas Limit": "Gaz Limiti",
    "Gas Limit & Usage by Txn": "İşlem Başına Gaz Limiti ve Kullanımı",
    "Gas Price": "Gaz Fiyatı",
    "Gas Target": "Gaz Hedefi",
    "Gas Tracker": "Gaz Takipçisi",
    "Gas Used": "Kullanılan Gaz",
    "Gas price history": "Gaz fiyatı geçmişi",
    "Gas tracker": "Gaz takipçisi",
    "Gas tracker overview": "Gaz izleyici genel bakışı",
    "General Inquiry": "Genel Soruşturma",
    "Github": "Github",
    "Get Started": "Başlamak",
    "Help & Support": "Yardım ve Destek",
    "Helpdesk Ticket Number": "Yardım Masası Bilet Numarası",
    "Hex": "Onaltılık",
    "Hide All Chains": "Tüm Zincirleri Gizle",
    "Hide Chains": "Zincirleri Gizle",
    "Highlighted events of the transaction.": "İşlemin vurgulanan olayları.",
    "Historical Balance at a specific Block No or Date.": "Belirli Bir Blok No veya Tarihte Tarihsel Bakiye.",
    "Home": "Ana Sayfa",
    "How We Use Your Information": "Bilgilerinizi Nasıl Kullanıyoruz",
    "How to Start": "Nasıl Başlanır",
    "I can help you in the following scenarios": "Aşağıdaki senaryolarda size yardımcı olabilirim",
    "I don't have an API Key": "Bir API Anahtarım Yok",
    "I have an API Key": "Bir API Anahtarım Var",
    "ID": "Kimlik",
    "If using the Solidity compiler, run solc —version to check.": "Solidity derleyicisini kullanıyorsanız, solc —version komutunu çalıştırarak kontrol edin.",
    "If you have any additional notes, please leave them here": "Ek notunuz varsa, lütfen buraya bırakın",
    "Implementation": "Uygulama",
    "In addition to expediting token updates, we also provide priority support for other urgent or technical matters, such as contract verification.": "Token güncellemelerini hızlandırmaya ek olarak, sözleşme doğrulama gibi diğer acil veya teknik konularda öncelikli destek de sağlıyoruz.",
    "Indexed": "Dizinlenmiş",
    "Information We Collect": "Topladığımız Bilgiler",
    "Input Data": "Giriş Verisi",
    "Instagram": "Instagram",
    "Internal Transactions": "Dahili İşlemler",
    "Internal transaction is transactions as a result of contract execution that involves Ether value.": "Dahili işlem, Ethereum değeri içeren sözleşme yürütmesi sonucu gerçekleşen işlemlerdir.",
    "Is Yul Contract": "Yul Sözleşmesi mi",
    "Item": "Öğe",
    "It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you and for periodically visiting our website and this privacy policy to check for any changes.": "Gizlilik politikamızda yaptığımız herhangi bir değişikliği bu sayfada yayınlama politikamızdır. Gizlilik politikasının en son ne zaman revize edildiği sayfanın üst kısmında belirtilmiştir. Sizin için güncel, aktif ve teslim edilebilir bir e-posta adresimizin olup olmadığını ve herhangi bir değişiklik olup olmadığını kontrol etmek için web sitemizi ve bu gizlilik politikasını periyodik olarak ziyaret etmek sizin sorumluluğunuzdadır.",
    "Join BlocksScan and enhance the world's leading blockchain explorer and search, API & analytics platform.": "BlocksScan'e katılın ve dünyanın önde gelen blockchain gezgini ve arama, API ve analitik platformunu geliştirin.",
    "Keep up with the latest breakthroughs and best practices in the industry with learned and experienced teammates.": "Öğrenmiş ve deneyimli takım arkadaşları ile sektördeki en son yenilikleri ve en iyi uygulamaları takip edin.",
    "Kindly follow the simple steps below to get your support prioritized.": "Desteğinizi önceliklendirmek için lütfen aşağıdaki basit adımları izleyin.",
    "Kindly reach out to your wallet service provider, exchanges or project/contract owner for further support as they are in a better position to assist you on the issues related to and from their platforms.": "Lütfen cüzdan hizmeti sağlayıcınıza, borsalara veya proje/sözleşme sahibine daha fazla destek almak için ulaşın, çünkü onlar platformlarıyla ilgili sorunları ve bu platformlardan kaynaklanan sorunları çözmede size yardımcı olabilirler.",
    "Knowledge Base": "Bilgi Tabanı",
    "Last Sale": "Son Satış",
    "Last Traded": "Son Alışveriş",
    "Last Updated": "Son Güncelleme",
    "Latest Block": "En Son Blok",
    "Latest Blocks": "En Son Bloklar",
    "Latest Mints": "En Son Basım",
    "Latest Signed Block": "En Son İmzalı Blok",
    "Latest Transaction": "En Son İşlem",
    "Latest Transactions": "En Son İşlemler",
    "Latest Trades": "En Son İşlemler",
    "Latest Transfers": "En Son Transferler",
    "Learn more about": "Daha Fazla Bilgi Edinin",
    "Learning Sessions": "Öğrenme Oturumları",
    "Legacy": "Miras",
    "License": "Lisans",
    "Light": "Işık",
    "List of": "Liste",
    "List of {{_tokenType}}20 tokens transferred in the transaction.": "İşlemde transfer edilen {{_tokenType}}20 jetonunun listesi.",
    "List of tokens burnt transferred in the transaction.": "İşlemde transfer edilen jetonların listesi.",
    "List of tokens mint transferred in the transaction.": "İşlemde transfer edilen jetonların listesi.",
    "Load": "Yükle",
    "Loading": "Yükleniyor",
    "Loading...": "Yükleniyor...",
    "Logdata to clipboard": "Panoya kayıt verisi",
    "Logs": "Günlükler",
    "Look up": "Ara",
    "Lookup": "Arama",
    "Low load": "Düşük yük",
    "MED GAS PRICE": "Orta Gaz Fiyatı",
    "Main Interfaces (.vy or .json)": "Ana Arayüzler (.vy veya .json)",
    "Market": "Pazar",
    "Market Cap": "Piyasa Değeri",
    "Market Capitalization": "Piyasa Değeri",
    "Marketplaces": "Pazarlar",
    "Marketplaces trading this NFT.": "Bu NFT'yi takas eden pazarlar.",
    "Max": "Maksimum",
    "Max Priority": "Maksimum Öncelik",
    "Max Price": "Maksimum Fiyat",
    "Max Total Supply": "Maksimum Toplam Arz",
    "Media & Press": "Medya ve Basın",
    "Medical Benefits": "Tıbbi Faydalar",
    "Method ID": "Yöntem Kimliği",
    "Method Name": "Yöntem Adı",
    "Method": "Yöntem",
    "Method id": "Yöntem kimliği",
    "Metadata": "Meta veri",
    "Min Price": "Minimum Fiyat",
    "Missing": "Eksik",
    "Mode": "Mod",
    "More Details": "Daha Fazla Detay",
    "More Info": "Daha Fazla Bilgi",
    "Multichain Info": "Çoklu Zincir Bilgisi",
    "Multichain Portfolio": "Çoklu Zincir Portföyü",
    "Name": "Ad",
    "Name Tag": "İsim Etiketi",
    "NEVER": "Asla",
    "NEVER send us these.": "Bu asla gönderme.",
    "NEW": "Yeni",
    "NFT Address": "NFT Adresi",
    "NFT Latest Mints": "NFT Son Üretimler",
    "NFT Top Mints": "NFT En Üst Üretimler",
    "NFT Transfer": "NFT Transferi",
    "NFT Transfers": "NFT Transferleri",
    "NFT's unique token ID.": "NFT'nin benzersiz jeton kimliği.",
    "NFT": "NFT",
    "Node": "Düğüm",
    "Nonce": "Nonce",
    "Non-Personal Information: Data that does not personally identify you but is linked to your usage of our services, such as IP addresses, browser types, and access times.": "Kişisel Olmayan Bilgiler: Kişisel olarak tanımlanmayan bilgiler, örnek olarak, IP adresleri, tarayıcı tipleri, ve erişim zamanları gibi, hizmetlerin nasıl kullanıldığını belirtmektedir.",
    "Normal": "Normal",
    "Not": "Değil",
    "Note: Filtered by totalSupply(), method returned a zero value.": "Not: totalSupply() tarafından filtrelenmiş, yöntem sıfır bir değer döndürdü.",
    "Note": "Not",
    "No Record Found": "Kayıt Bulunamadı",
    "No transactions found.": "Hiç işlem bulunamadı.",
    "Number": "Numara",
    "Number of beacon withdrawals in this block.": "Bu bloktaki beacon çekilme sayısı.",
    "Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was produced.": "İşlemin kaydedildiği bloğun numarası. Blok onayları, işlemin üretildiği zamandan bu yana kaç bloğun eklendiğini gösterir.",
    "OAuth": "OAuth",
    "Office Entertainment": "Ofis Eğlencesi",
    "Onchain Market Cap": "Onchain Piyasa Değeri",
    "OpenAI API key": "OpenAI API anahtarı",
    "Optimization": "Optimizasyon",
    "Optimization Enabled": "Optimizasyon Etkin",
    "Optimization Runs": "Optimizasyon Çalışmaları",
    "Order": "Sıra",
    "Others": "Diğerleri",
    "Ours isn’t just a grand vision. We back it up with the company and culture tailored for realizing it.": "Bizimki sadece büyük bir vizyon değil. Onu gerçekleştirmek için uygun olan şirket ve kültürle destekliyoruz.",
    "Our team will review your request and contact you using an official": "Ekibimiz talebinizi gözden geçirecek ve resmi bir şekilde sizinle iletişime geçecektir",
    "Overview": "Genel Bakış",
    "Owner": "Sahip",
    "Owners": "Sahipler",
    "Page": "Sayfa",
    "Parent Hash": "Üst Hash",
    "Pending Transaction": "Bekleyen İşlem",
    "Pending Transactions": "Bekleyen İşlemler",
    "Pending...": "Beklemede...",
    "Percentage": "Yüzde",
    "Perks and Benefits": "Avantajlar ve Faydalar",
    "Personal Information: Data that personally identifies you, such as your name, email address, and payment details, provided at registration or during the use of our services.": "Kişisel Bilgiler: Sizi kişisel olarak tanımlayan veriler, adınız, e-posta adresiniz ve ödeme detaylarınız gibi, kayıt sırasında veya hizmetlerimizi kullanırken sağlanan veriler.",
    "Phone": "Telefon",
    "Platform": "Platform",
    "Please Select Your Message Subject": "Lütfen Mesaj Konunuzu Seçin",
    "Please understand that we can only assist with issues directly related to the block explorer services. Before writing in, please be informed that": "Sadece blok gezgini hizmetleri ile doğrudan ilgili sorunlara yardımcı olabileceğimizi anlamanızı rica ederiz. Yazmadan önce lütfen bilgilendirilmiş olun",
    "Please use this form to get in touch for priority support or featured listing with our team.": "Lütfen ekibimizle öncelikli destek veya öne çıkan listeleme için iletişim kurmak için bu formu kullanın.",
    "Position": "Pozisyon",
    "Post-London Upgrade, this represents the minimum gas Used multiplier required for a tx to be included in a block.": "Londra Güncellemesi'nden Sonra, bu bir işlemin bir bloğa dahil edilmesi için gereken minimum gaz Kullanılan çarpanını temsil eder.",
    "Post-London Upgrade, this represents the part of the tx fee that is burnt: baseFeePerGas * gasUsed.": "Londra Güncellemesi'nden Sonra, bu, yakılan işlem ücretinin bir parçasını temsil eder: baseFeePerGas * gasUsed.",
    "Power": "Güç",
    "Powered by BlocksScan": `${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"} tarafından desteklenmektedir`,
    "BlocksScan is a Block Explorer and Analytics Platform for": `${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"}, bir Blok Gezgini ve Analitik Platformudur`,
    "Present": "Şu An",
    "Preserve": "Koruma",
    "Press": "Basın",
    "Price": "Fiyat",
    "Primary compiled Vyper contract. Only add the main contract here whose bytecode has been deployed, all other files can be uploaded to the interfaces box below": "Birincil derlenmiş Vyper sözleşmesi. Sadece bytecode'ı dağıtılmış ana sözleşmeyi buraya ekleyin, diğer tüm dosyalar aşağıdaki arayüz kutusuna yüklenebilir",
    "Privacy": "Gizlilik",
    "Privacy Policy": "Gizlilik Politikası",
    "Private Name Tags": "Özel İsim Etiketleri",
    "Processing": "İşleniyor",
    "Products": "Ürünler",
    "Profile": "Profil",
    "Programs": "Programlar",
    "Project": "Proje",
    "Properties": "Özellikler",
    "Proposed On": "Önerildiği Tarih",
    "Proxy": "Vekil",
    "Publish": "Yayınla",
    "Published": "Yayınlandı",
    "Quantity": "Miktar",
    "Rank": "Sıra",
    "Read": "Oku",
    "Read Contract": "Sözleşmeyi Oku",
    "Read Proxy": "Vekili Oku",
    "Real-time": "Gerçek Zamanlı",
    "Receipt": "Fiş",
    "Recent": "Son",
    "Record": "Kayıt",
    "Recover": "Kurtar",
    "Recovery": "Kurtarma",
    "Recycle": "Geri Dönüşüm",
    "Redo": "Yeniden Yap",
    "Refresh": "Yenile",
    "Register": "Kayıt Ol",
    "Registration": "Kayıt",
    "Related": "İlgili",
    "Release": "Yayımla",
    "Reload": "Yeniden Yükle",
    "Removal": "Kaldırma",
    "Remove": "Kaldır",
    "Removed": "Kaldırıldı",
    "Removing": "Kaldırılıyor",
    "Renew": "Yenile",
    "Replace": "Değiştir",
    "Request Removal of Name Tag": "İsim Etiketinin Kaldırılmasını İste",
    "Request": "İstek",
    "Requester Email": "İsteyenin E-Postası",
    "Requester Name": "İsteyenin Adı",
    "Requests": "İstekler",
    "Resend": "Yeniden Gönder",
    "Reset": "Sıfırla",
    "Resources": "Kaynaklar",
    "Response": "Yanıt",
    "Responses": "Yanıtlar",
    "Restore": "Geri Yükle",
    "Restrict": "Kısıtla",
    "Result": "Sonuç",
    "Results": "Sonuçlar",
    "Resume": "Devam Et",
    "Retain": "Sakla",
    "Retrieve": "Al",
    "Return": "Geri Dön",
    "Returned": "Geri Dönüldü",
    "Returning": "Geri Dönülüyor",
    "Review": "İncele",
    "Rewards": "Ödüller",
    "Rewrite": "Yeniden Yaz",
    "Rich": "Zengin",
    "Right": "Doğru",
    "Rights": "Haklar",
    "Role": "Rol",
    "Roles": "Roller",
    "Rollback": "Geri Al",
    "Root": "Kök",
    "Route": "Rota",
    "Routing": "Yönlendirme",
    "Row": "Satır",
    "Rules": "Kurallar",
    "Run": "Çalıştır",
    "Running": "Çalışıyor",
    "Sales": "Satışlar",
    "Save Changes": "Değişiklikleri Kaydet",
    "Saved Filters": "Kaydedilmiş Filtreler",
    "Saving": "Kaydediliyor",
    "Scenario": "Senaryo",
    "Schemas": "Şemalar",
    "Schedule": "Zamanlama",
    "Scheduled": "Zamanlandı",
    "Scheduling": "Zamanlama",
    "Schema": "Şema",
    "Scope": "Kapsam",
    "Screen": "Ekran",
    "Scripts": "Betikler",
    "Search": "Ara",
    "Ask AI | Search by Address | Txn Hash | Block | Token... ": "Adres / işlem hash / blok / jeton ile arama yap...",
    "Searches": "Aramalar",
    "Searching": "Aranıyor",
    "Second": "Saniye",
    "Seconds": "Saniyeler",
    "Sec": "Sn",
    "Secret": "Sır",
    "Section": "Bölüm",
    "Secure": "Güvenli",
    "Securing": "Güvenliğini Sağlama",
    "Security": "Güvenlik",
    "Security Audit": "Güvenlik Denetimi",
    "See more details": "Daha fazla detayı gör",
    "See preview of the transaction details.": "İşlem detaylarının önizlemesini gör.",
    "Select": "Seç",
    "Selected": "Seçilen",
    "Selection": "Seçim",
    "Send": "Gönder",
    "Send Message": "Mesaj Gönder",
    "Sender": "Gönderen",
    "Sending": "Gönderiliyor",
    "Send native": "Doğal Gönder",
    "Sequence": "Sıra",
    "Server": "Sunucu",
    "Service": "Hizmet",
    "Services": "Hizmetler",
    "Session": "Oturum",
    "Sessions": "Oturumlar",
    "Set": "Ayarla",
    "Setting": "Ayar",
    "Settings": "Ayarlar",
    "Setup": "Kurulum",
    "Share": "Paylaş",
    "Shared": "Paylaşılan",
    "Sharing": "Paylaşım",
    "Show": "Göster",
    "Show More Chains": "Daha Fazla Zincir Göster",
    "Show less": "Daha Az Göster",
    "Show more": "Daha Fazla Göster",
    "Showing": "Gösteriliyor",
    "Signer": "İmzalayan",
    "Similar Contract Search": "Benzer Sözleşme Araması",
    "Simple steps for getting Priority Support.": "Öncelikli Destek Almak İçin Basit Adımlar.",
    "Size": "Boyut",
    "Slow": "Yavaş",
    "Slot and epoch this block is proposed on.": "Bu bloğun önerildiği yuva ve çağ.",
    "Smart Contract": "Akıllı Sözleşme",
    "Smart Contract Search": "Akıllı Sözleşme Araması",
    "Smart Contracts": "Akıllı Sözleşmeler",
    "Snapshot": "Anlık Görüntü",
    "Snapshots": "Anlık Görüntüler",
    "Social": "Sosyal",
    "Software": "Yazılım",
    "Solidity": "Sağlamlık",
    "Solution": "Çözüm",
    "Solutions": "Çözümler",
    "Source Code": "Kaynak Kodu",
    "Sources *.sol or *.yul files": "Kaynaklar *.sol veya *.yul dosyaları",
    "Sources and Metadata JSON": "Kaynaklar ve Meta Veri JSON",
    "Span": "Aralık",
    "Special": "Özel",
    "Specific": "Belirli",
    "Specification": "Özellik",
    "Specifications": "Özellikler",
    "Specified": "Belirtilen",
    "Specify": "Belirtmek",
    "Specs": "Teknik özellikler",
    "Sponsor": "Sponsor",
    "Sponsored": "Sponsorlu",
    "Sponsoring": "Sponsorluk",
    "Sponsors": "Sponsorlar",
    "Spotlight": "Reflektör",
    "Stable": "Kararlı",
    "Staging": "Sahneleme",
    "Standard JSON Input": "Standart JSON Girişi",
    "Start": "Başlat",
    "Starting": "Başlatılıyor",
    "State": "Durum",
    "State Difference": "Durum Farkı",
    "State Root": "Durum Kökü",
    "StateRoot": "Durum Kökü",
    "State of the art coffee machine and experienced baristas in the house for the coffee lovers to thrive.": "Kahve tutkunlarının gelişimi için evde son teknoloji kahve makinesi ve deneyimli baristalar.",
    "Statistics": "İstatistikler",
    "Status": "Durum",
    "Step 1": "Adım 1",
    "Step 2": "Adım 2",
    "Step 3": "Adım 3",
    "Steps": "Adımlar",
    "Storage Of Assets": "Varlıkların Depolanması",
    "Store": "Depolamak",
    "Stored": "Depolanmış",
    "Storing": "Depolama",
    "Stream": "Akıntı",
    "Streaming": "Akıntı",
    "Streams": "Akıntılar",
    "Strength": "Güç",
    "String": "Dize",
    "Strings": "Dizeler",
    "Structure": "Yapı",
    "Structures": "Yapılar",
    "Style": "Tarz",
    "Styles": "Stiller",
    "Submit": "Gönder",
    "Submitted": "Gönderildi",
    "Submitting": "Gönderiliyor",
    "Subscription": "Abonelik",
    "Subscriptions": "Abonelikler",
    "Subsequent": "Sonrası",
    "Success": "Başarı",
    "Successful": "Başarılı",
    "Successfully": "Başarılı bir şekilde",
    "Suggest Transaction Action": "İşlem Eylemini Öner",
    "Suggestions": "Öneriler",
    "Summary": "Özet",
    "Support": "Destek",
    "Supported": "Desteklenen",
    "Supporting": "Destekleme",
    "Supports": "Destekler",
    "Supply": "Tedarik",
    "Surface": "Yüzey",
    "Survey": "Anket",
    "Surveys": "Anketler",
    "Suspend": "Askıya Al",
    "Suspended": "Askıya Alınmış",
    "Suspending": "Askıya Alma",
    "Swap": "Değiştirmek",
    "Swapping": "Değiştirme",
    "Switch": "Değiştirmek",
    "Switching": "Değiştirme",
    "Symbol": "Sembol",
    "Symbols": "Simgeler",
    "Sync": "Senkronizasyon",
    "Synchronize": "Senkronize Et",
    "Synchronized": "Senkronize Edilmiş",
    "Synchronizing": "Senkronize Ediliyor",
    "System": "Sistem",
    "Systems": "Sistemler",
    "TV": "TV",
    "Table": "Tablo",
    "Tables": "Tablolar",
    "Tag": "Etiket",
    "Tags": "Etiketler",
    "Take": "Almak",
    "Taken": "Alınmış",
    "Taking": "Alma",
    "Target": "Hedef",
    "Targets": "Hedefler",
    "Task": "Görev",
    "Tasks": "Görevler",
    "Team": "Ekip",
    "Teams": "Ekipler",
    "Technical": "Teknik",
    "Technique": "Teknik",
    "Techniques": "Teknikler",
    "Technology": "Teknoloji",
    "Template": "Şablon",
    "Templates": "Şablonlar",
    "Temporary": "Geçici",
    "Terminate": "Sonlandırmak",
    "Terminated": "Sonlandırılmış",
    "Terminating": "Sonlandırma",
    "Terms": "Şartlar",
    "Test": "Test",
    "Tested": "Test Edilmiş",
    "Testing": "Test Etme",
    "Tests": "Testler",
    "Text": "Metin",
    "Theme": "Tema",
    "Themes": "Temalar",
    "Therapy": "Terapi",
    "This block is finalized.": "Bu blok tamamlandı.",
    "This block is finilized.": "Bu blok tamamlandı.",
    "This page allows you to customize your browsing experience on Blocksscan.": "Bu sayfa, Blocksscan'daki gezinme deneyiminizi özelleştirmenize olanak tanır.",
    "This NFT's unique token ID.": "Bu NFT'nin benzersiz token kimliği.",
    "Thread": "İplik",
    "Threads": "İplikler",
    "Threshold": "Eşik",
    "Thresholds": "Eşikler",
    "Ticket": "Bilet",
    "Tickets": "Biletler",
    "Time": "Zaman",
    "Timestamp": "Zaman Damgası",
    "Title": "Başlık",
    "Titles": "Başlıklar",
    "To": "Kime",
    "Token Contract": "Token Sözleşmesi",
    "Token Contract Address": "Token Sözleşme Adresi",
    "Token Creation": "Token Oluşturma",
    "Token Holding": "Token Tutma",
    "Token ID": "Token Kimliği",
    "Token Inventory": "Token Envanteri",
    "Token Name": "Token Adı",
    "Token Standard": "Token Standartı",
    "Token Transactions": "Token İşlemleri",
    "Token Transfers": "Token Transferleri",
    "Token Update": "Token Güncelleme",
    "Token creation": "Token oluşturma",
    "Token transfer": "Token transferi",
    "Tokens": "Tokenlar",
    "Tokens Transferred": "Transfer Edilen Tokenlar",
    "Tokens burnt": "Yakılan Tokenlar",
    "Tokens minted": "Basılan Tokenlar",
    "Tokens transferred in the transaction.": "İşlemde transfer edilen tokenlar.",
    "ToDo": "Yapılacaklar",
    "To clipboard": "Panoa Kopyala",
    "Toggle": "Değiştirme",
    "Toggled": "Değiştirildi",
    "Toggling": "Değiştirme",
    "Tool": "Araç",
    "Tools": "Araçlar",
    "Top Accounts": "En İyi Hesaplar",
    "Top Contract": "En İyi Sözleşme",
    "Top Contracts": "En İyi Sözleşmeler",
    "Top Mints": "En İyi Damgalar",
    "Top NFT": "En İyi NFT",
    "Top NFTs": "En İyi NFT'ler",
    "Top Statistics": "En İyi İstatistikler",
    "Top Token": "En İyi Token",
    "Top Tokens": "En İyi Tokenlar",
    "Topic": "Konu",
    "Topics": "Konular",
    "Total": "Toplam",
    "Total Accounts": "Toplam Hesaplar",
    "Total Assets": "Toplam Varlıklar",
    "Total Difficulty": "Bu bloğa kadar zincirdeki toplam zorluk.",
    "Total Holders": "Toplam Sahipler",
    "Total Transfers": "Toplam Transferler",
    "Total amount of ETH burnt from this tx & total fees saved from the amount the user was willing to pay for this tx.": "Bu işlemde yakılan ETH'nin toplam tutarı ve kullanıcının bu işlem için ödemeye istekli olduğu toplam ücretten tasarruf edilen miktar.",
    "Total gas limit provided by all transactions in the block.": "Bloktaki tüm işlemler tarafından sağlanan toplam gaz limiti.",
    "Total gas used in the block and its percentage of gas filled in the block.": "Bloktaki toplam kullanılan gaz ve bloktaki gazın doluluk yüzdesi.",
    "Total transactions": "Toplam işlemler",
    "Trace": "İz",
    "Traces": "İzler",
    "Tracking": "Takip",
    "Trade": "Ticaret",
    "Trades": "Ticaretler",
    "Trading": "Ticaret",
    "Transaction": "İşlem",
    "Transaction Action": "İşlem Eylemi",
    "Transaction Details": "İşlem Detayları",
    "Transaction Fee": "İşlem Ücreti",
    "Transaction Hash": "İşlem Hash'i",
    "Transaction Type": "İşlem Türü",
    "Transactions": "İşlemler",
    "Transactions Internal": "Dahili İşlemler",
    "Transcript": "Transkript",
    "Transcripts": "Transkriptler",
    "Transfer": "Transfer",
    "Transferred": "Transfer Edildi",
    "Transferring": "Transfer Etme",
    "Transfers": "Transferler",
    "Translate": "Çevir",
    "Translated": "Çevrildi",
    "Translating": "Çevirme",
    "Translation": "Çeviri",
    "Translations": "Çeviriler",
    "Trending": "Trendler",
    "Trends": "Trendler",
    "Trigger": "Tetikleyici",
    "Triggered": "Tetiklendi",
    "Triggering": "Tetikleme",
    "Triggers": "Tetikleyiciler",
    "Troubleshoot": "Sorun Giderme",
    "Troubleshooting": "Sorun Giderme",
    "Trust": "Güven",
    "Trusted": "Güvenilir",
    "Trusting": "Güvenme",
    "Try": "Dene",
    "Trying": "Deneniyor",
    "Tx Fee": "İşlem Ücreti",
    "Tx Hash": "İşlem Hash'i",
    "Tx Hash|Block|Age": "İşlem Hash'i|Blok|Yaş",
    "Tx row trace to clipboard": "İşlem satır izini panoya kopyala",
    "Tx": "İşlem",
    "Txn": "İşlem",
    "Txn Savings": "İşlem Tasarrufları",
    "Txn Type": "İşlem Türü",
    "Txns": "İşlemler",
    "Type": "Tür",
    "Type here ...": "Buraya yazın ...",
    "Type of Backing": "Destek Türü",
    "Type of Peg": "Sabitleme Türü",
    "Types": "Türler",
    "UI": "Kullanıcı Arayüzü",
    "URL": "URL",
    "URLs": "URL'ler",
    "Undo": "Geri Al",
    "Undone": "Geri Alındı",
    "Unfinished": "Tamamlanmamış",
    "Unit": "Birim",
    "Units": "Birimler",
    "Universe": "Evren",
    "Unknown": "Bilinmeyen",
    "Unlink": "Bağlantıyı Kaldır",
    "Unlinked": "Bağlantı Kaldırıldı",
    "Unlinking": "Bağlantı Kaldırılıyor",
    "Unlock": "Kilidi Aç",
    "Unlocked": "Kilidi Açıldı",
    "Unlocking": "Kilidi Açılıyor",
    "Unread": "Okunmamış",
    "Unresolved": "Çözümlenmemiş",
    "Unseen": "Görülmemiş",
    "Unsubscribe": "Abonelikten Çık",
    "Unsubscribed": "Abonelikten Çıkıldı",
    "Unsubscribing": "Abonelikten Çıkılıyor",
    "Untagged": "Etiketlenmemiş",
    "Untagging": "Etiket Kaldırılıyor",
    "Update": "Güncelle",
    "Update Proxy Contract's Implementation Address": "Proxy Sözleşme Uygulama Adresini Güncelle",
    "Update Token Info": "Token Bilgisini Güncelle",
    "Updated": "Güncellendi",
    "Updating": "Güncelleniyor",
    "Upgrade": "Yükselt",
    "Upgraded": "Yükseltildi",
    "Upgrading": "Yükseltiliyor",
    "Upload": "Yükle",
    "Upload all Solidity contract source files and JSON metadata file(s) created during contract compilation.": "Sözleşme derleme sırasında oluşturulan tüm Solidity sözleşme kaynak dosyalarını ve JSON meta veri dosyalarını yükleyin.",
    "Upload all Solidity or Yul contract source files": "Tüm Solidity veya Yul sözleşme kaynak dosyalarını yükleyin",
    "Upload the standard input JSON file created during contract compilation": "Sözleşme derleme sırasında oluşturulan standart giriş JSON dosyasını yükleyin",
    "Uploaded": "Yüklendi",
    "Uploading": "Yükleniyor",
    "Uptime": "Çalışma Süresi",
    "Us": "Biz",
    "Usage": "Kullanım",
    "Use": "Kullan",
    "Used": "Kullanılan",
    "User": "Kullanıcı",
    "Users": "Kullanıcılar",
    "Using": "Kullanma",
    "Utilities": "Araçlar",
    "Utility": "Fayda",
    "Validate": "Doğrula",
    "Validated": "Doğrulandı",
    "Validating": "Doğrulanıyor",
    "Validation": "Doğrulama",
    "Value": "Değer",
    "Values": "Değerler",
    "Variable": "Değişken",
    "Variables": "Değişkenler",
    "Variant": "Çeşit",
    "Variants": "Çeşitler",
    "Varying": "Değişkenlik",
    "Vault": "Kasa",
    "Vaults": "Kasalar",
    "Vector": "Vektör",
    "Vectors": "Vektörler",
    "Verification": "Doğrulama",
    "Verification Method": "Doğrulama Yöntemi",
    "Verified": "Doğrulandı",
    "Verified At": "Doğrulama Tarihi",
    "Verified Contracts": "Doğrulanmış Sözleşmeler",
    "Verified Smart Contract": "Doğrulanmış Akıllı Sözleşme",
    "Verifying": "Doğrulanıyor",
    "Version": "Sürüm",
    "Versions": "Sürümler",
    "Verify & Publish": "Doğrula ve Yayınla",
    "Verify And Publish": "Doğrula ve Yayınla",
    "Verify Contract": "Sözleşmeyi Doğrula",
    "View": "Görünüm",
    "View Blocks": "Blok Görünümü",
    "Views": "Görünümler",
    "Viewing": "Görünüyor",
    "Visible": "Görünür",
    "Visit": "Ziyaret Et",
    "Visiting": "Ziyaret Ediliyor",
    "Visitor": "Ziyaretçi",
    "Visitors": "Ziyaretçiler",
    "Visual": "Görsel",
    "Visuals": "Görseller",
    "Volume": "Hacim",
    "Volume 24h": "24 Saatlik Hacim",
    "Volumes": "Hacimler",
    "Vote": "Oy Ver",
    "Voted": "Oy Verildi",
    "Voter": "Seçmen",
    "Voters": "Seçmenler",
    "Voting": "Oylama",
    "Votes": "Oylar",
    "Vyper Online Compiler": "Vyper Çevrimiçi Derleyicisi",
    "WAITING": "BEKLEME",
    "Wallet": "Cüzdan",
    "Wallet / Exchange / Project related issues": "Cüzdan / Borsa / Proje ile ilgili sorunlar",
    "Wallets": "Cüzdanlar",
    "Warning": "Uyarı",
    "Warnings": "Uyarılar",
    "Watch": "İzle",
    "Watching": "İzleniyor",
    "Web": "Web",
    "Webpage": "Web sayfası",
    "Webpages": "Web sayfaları",
    "Website": "Web sitesi",
    "Websites": "Web siteleri",
    "Welcome": "Hoş geldiniz",
    "Welcome to Blocksscan Explorer Services": "Blocksscan Explorer Hizmetlerine Hoş Geldiniz",
    "Wellness": "İyi olma",
    "We're Hiring": "İşe Alıyoruz",
    "What are the functions or methods available in this contract?": "Bu sözleşmede hangi işlevler veya yöntemler mevcut?",
    "What does this contract do?": "Bu sözleşme ne işe yarıyor?",
    "What is the logical structure of this contract's code?": "Bu sözleşmenin kodunun mantıksal yapısı nedir?",
    "Where to find my API Key?": "API Anahtarımı nereden bulabilirim?",
    "While we try our best to respond in a timely manner, we often have a backlog of inquiries. Please do not": "Mümkün olan en kısa sürede yanıt vermeye çalışırken, genellikle bir soru yığını oluşur. Lütfen",
    "White": "Beyaz",
    "Who": "Kim",
    "Whole": "Tüm",
    "Wholesale": "Toptan",
    "Widget": "Araç",
    "Widgets": "Araçlar",
    "Width": "Genişlik",
    "Widths": "Genişlikler",
    "Wiki": "Wiki",
    "Wikis": "Wikiler",
    "Willing": "İstekli",
    "Win": "Kazan",
    "Window": "Pencere",
    "Windows": "Pencereler",
    "Winning": "Kazanma",
    "Wins": "Kazanıyor",
    "Wire": "Tel",
    "Wires": "Telller",
    "Wiring": "Teller",
    "Wisdom": "Bilgelik",
    "Wish": "Dilek",
    "Wished": "Dilek",
    "Wishes": "Dilekler",
    "Wishing": "Dileme",
    "Withdraw": "Çekil",
    "Withdrawal": "Çekilme",
    "Withdrawals": "Çekilmeler",
    "WithdrawalsRoot": "Çekilmeler Kökü",
    "Withdrawing": "Çekiliyor",
    "Withdrawn": "Çekildi",
    "With": "İle",
    "Within": "İçinde",
    "Without": "Olmadan",
    "Witness": "Şahit",
    "Witnessed": "Tanık",
    "Witnesses": "Tanıklar",
    "Witnessing": "Tanıklık",
    "Work": "Çalışma",
    "Worked": "Çalıştı",
    "Working": "Çalışıyor",
    "Works": "Çalışır",
    "Workspace": "Çalışma alanı",
    "Workspaces": "Çalışma alanları",
    "World": "Dünya",
    "Worldwide": "Dünya çapında",
    "Worry": "Endişe",
    "Worried": "Endişeli",
    "Worries": "Endişeler",
    "Worrying": "Endişelenme",
    "Worth": "Değer",
    "Write": "Yaz",
    "Write Contract": "Sözleşme Yaz",
    "Write Proxy": "Vekil Yaz",
    "Write me a smart contract of voting.": "Bana bir oylama akıllı sözleşmesi yaz.",
    "Writes": "Yazıyor",
    "Writing": "Yazma",
    "Written": "Yazılmış",
    "Wrong": "Yanlış",
    "Wrote": "Yazdı",
    "X (Twitter)": "X (Twitter)",
    "XML": "XML",
    "XRay": "XRay",
    "Xrays": "X-rays",
    "Year": "Yıl",
    "Years": "Yıllar",
    "Yellow": "Sarı",
    "Yes": "Evet",
    "Yesterday": "Dün",
    "Yield": "Getiri",
    "Yielding": "Verimli",
    "Yields": "Verimler",
    "You can Lookup the Account": "Hesabı Arayabilirsiniz",
    "Your browser does not support the video tag.": "Tarayıcınız video etiketini desteklemiyor.",
    "Your private keys with anyone.": "Özel anahtarlarınızı kimseyle paylaşmayın.",
    "Your Rights": "Haklarınız",
    "Youth": "Gençlik",
    "Zone": "Bölge",
    "Zones": "Bölgeler",
    "Zoning": "Bölgeleme",
    "Zoom": "Yakınlaştırma",
    "Zoomed": "Yakınlaştırıldı",
    "Zooming": "Yakınlaştırma",
    "Zooms": "Yakınlaştırır",
    "rsync-builder in 12 secs": "rsync-builder 12 saniyede",
    "rsyncbuilder": "rsync-builder",
    "sec": "Saniye",
    "tokens with a value of": "değerindeki jetonlar",
    "to clipboard": "panoya",
    "tokens transferred in the transaction.": "işlemde transfer edilen jetonlar.",
    "withdrawals in this block": "bu blokta çekilmeler",
    "we": "Biz",
    "us": "Bize",
    "our": "Bizim",
  }}