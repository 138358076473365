import React, {FC} from 'react';

interface BSkeletonProps {
    height?: any;
    width?: any;
    className?: any;
}

const BSkeleton: FC<BSkeletonProps> = ({height, width, className}) => {
    const classNames = () => {
        let classes = 'bs-skeleton';

        if (className) {
            classes += ` ${className}`;
        }

        return classes;
    }

    return (
        <div className={classNames()} style={{width: width, height: height}}>
            <div className="bs-loading"/>
        </div>
    );
};

BSkeleton.defaultProps = {
    height: '16px',
    width: '',
    className: ''
}

export default BSkeleton;
