import React, {useState} from 'react';
import {ConnectButton} from '@rainbow-me/rainbowkit';
import useWallet from '../../../common/hooks/useWallet';
import MSK from '../../../images/MetaMask_Fox.svg';
import {common} from "../../../store/slicer/common";
import {useDispatch} from "react-redux";

const MetaMaskButton: React.FC = () => {
    const dispatch = useDispatch();
    const {
        isWalletConnected,
        addressHash,
        connect,
        disconnect,
    } = useWallet();
    const [isRequestPending, setIsRequestPending] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleConnectClick = async () => {
        if (isWalletConnected) {
            dispatch(common.actions.actionAccount(null))
            disconnect();
        } else {
            setIsRequestPending(true);
            setErrorMessage(null);

            try {
                await connect();
                setIsRequestPending(false);
            } catch (error: any) {
                setErrorMessage('An error occurred during connection. Please try again.');
                setIsRequestPending(false);
            }
        }
    };

    return (
        <div className="mb-3 mt-2">
            <ConnectButton.Custom>
                {({
                      account,
                      mounted,
                  }) => {
                    const connected = mounted && account;

                    return (
                        <React.Fragment>
              <button
                  type="button"
                  className="btn btn-sm bs-btn-cust"
                  onClick={handleConnectClick}
                  disabled={isRequestPending || !mounted}
              >
                <span style={{marginRight: '5px'}}>
                  <img
                      src={MSK}
                      alt="MetaMask Icon"
                      height={16}
                      width={16}
                      style={{marginBottom: '2px'}}
                  />
                </span>
                  {connected ? `Disconnect: ${account.displayName}` : 'Add to MetaMask'}
              </button>
                            {errorMessage && (
                                <div className="mt-2 text-danger">
                                    {errorMessage}
                                </div>
                            )}
                            {isRequestPending && (
                                <div className="mt-2 text-warning">
                                    Please complete the MetaMask connection.
                                </div>
                            )}
                        </React.Fragment>
                    );
                }}
            </ConnectButton.Custom>
        </div>
    );
};

export default MetaMaskButton;
