export const es ={
    translations:{
    "ABI for the implementation contract at": "ABI para el contrato de implementación en",
    "ABI-encoded": "ABI codificado",
    "API Documentation": "Documentación de API",
    "API Plans": "Planes de API",
    "API Support": "Soporte de API",
    "About Us": "Sobre Nosotros",
    "Account": "Cuenta",
    "Account Balance Checker": "Verificador de Saldo de Cuenta",
    "Action": "Acción",
    "Add Name Tag/Label": "Agregar Etiqueta/Nombre",
    "Add one more": "Agregar uno más",
    "Additional Info": "Información Adicional",
    "Additional Notes": "Notas Adicionales",
    "Address": "Dirección",
    "Address of this NFT contract.": "Dirección de este contrato NFT.",
    "Address that deployed this NFT contract.": "Dirección que desplegó este contrato NFT.",
    "Advertising": "Publicidad",
    "Age": "Edad",
    "All Rights Reserved": "Todos los Derechos Reservados",
    "All Transactions": "Todas las Transacciones",
    "Amount": "Cantidad",
    "Analytics": "Análisis",
    "Annual Trips": "Viajes Anuales",
    "Are you the contract creator": "¿Eres el creador del contrato?",
    "Back to Top": "Volver Arriba",
    "Balance": "Balance",
    "Balance Checker": "Verificador de Balance",
    "Base": "Base",
    "Base Fee": "Tarifa Base",
    "Base Fee Per Gas": "Tarifa Base Por Gas",
    "Best Coffee in Town": "Mejor Café en la Ciudad",
    "Block": "Bloque",
    "Block Height": "Altura del Bloque",
    "Block Number": "Número de Bloque",
    "Block Reward": "Recompensa de Bloque",
    "Block header withdrawal root hash.": "Hash raíz de retirada de encabezado de bloque.",
    "Block nonce is a value used during mining to demonstrate proof of work for a block.": "El nonce de bloque es un valor utilizado durante la minería para demostrar la prueba de trabajo para un bloque.",
    "Block proposed on epoch": "Bloque propuesto en el época",
    "Blocksscan is an independent": "Blocksscan es un independiente",
    "Blockchain": "Blockchain",
    "Blocks": "Bloques",
    "Broadcast Transaction": "Transmisión de Transacción",
    "Brand Assets": "Activos de Marca",
    "Bridge": "Puente",
    "Burnt": "Quemado",
    "Burnt & Txn Savings Fees": "Tarifas de Ahorro de Transacciones Quemadas",
    "Byte Code": "Código Byte",
    "Bytecode to Opcode": "Bytecode a Opcode",
    "By using Blocksscan Explorer Services, you consent to our Privacy Policy.": "Al utilizar los Servicios de Explorador de Blocksscan, usted acepta nuestra Política de Privacidad.",
    "CSV Export": "Exportación CSV",
    "Call": "Llamada",
    "Capacity": "Capacidad",
    "Careers": "Carreras",
    "Careers at BlocksScan": "Carreras en BlocksScan",
    "Change Fiat Currency": "Cambiar Moneda Fiat",
    "Change": "Cambio",
    "Charts & stats": "Gráficos y estadísticas",
    "Charts And Stats": "Gráficos y Estadísticas",
    "Choose Priority Service": "Elegir Servicio Prioritario",
    "Choose desired language": "Elegir idioma deseado",
    "Choose the theme": "Elegir el tema",
    "Circulating Market Cap": "Capitalización de Mercado Circulante",
    "Circulating Supply": "Suministro Circulante",
    "Circulating Supply Market Cap": "Capitalización de Mercado de Suministro Circulante",
    "Classification of NFT based on how the image is stored: On-Chain refers to those with data stored on the blockchain while Off-Chain refers to those with data stored elsewhere on the Internet.": "Clasificación de NFT basada en cómo se almacena la imagen: On-Chain se refiere a aquellos con datos almacenados en la blockchain mientras que Off-Chain se refiere a aquellos con datos almacenados en otro lugar en Internet.",
    "Classification": "Clasificación",
    "Click here to": "Haga clic aquí para",
    "Click to show less": "Haga clic para mostrar menos",
    "Click to show more": "Haga clic para mostrar más",
    "Close": "Cerrar",
    "Code": "Código",
    "Code Reader": "Lector de Código",
    "CodeRunAI": "CodeRunAI",
    "Coin transfer": "Transferencia de Moneda",
    "Collater­alization": "Colateralización",
    "Collection": "Colección",
    "Collection Name": "Nombre de la Colección",
    "Commute Allowance": "Subsidio de Viaje",
    "Community": "Comunidad",
    "Community Support": "Soporte de Comunidad",
    "Company": "Compañía",
    "Compiler": "Compilador",
    "Compiler Type": "Tipo de Compilador",
    "Compiler Version": "Versión del Compilador",
    "COMPILER TYPE AND VERSION SELECTION": "SELECCIÓN DE TIPO Y VERSIÓN DE COMPILADOR",
    "ConfirmationToken": "Confirmación",
    "Confirmation": "Confirmación",
    "Confirmed within": "Confirmado dentro de",
    "Confirming": "Confirmando",
    "Contact Us": "Contáctenos",
    "Contact Information": "Información de Contacto",
    "Contract": "Contrato",
    "Contract Address": "Dirección del Contrato",
    "Contract Code": "Código del Contrato",
    "Contract Diff Checker": "Verificador de Diferencias de Contrato",
    "Contract File Path": "Ruta del Archivo de Contrato",
    "Contract Internal Transactions": "Transacciones Internas del Contrato",
    "Contract Licence": "Licencia del Contrato",
    "Contract Name": "Nombre del Contrato",
    "Contract Source Code Verified": "Código Fuente del Contrato Verificado",
    "Contract Verification": "Verificación de Contrato",
    "Contract call": "Llamada de Contrato",
    "Contract creation": "Creación de Contrato",
    "Contracts": "Contratos",
    "Contracts Internal": "Contratos Internos",
    "Cost per unit of gas spent for the transaction, in Ether and Gwei.": "Costo por unidad de gas gastada para la transacción, en Ether y Gwei.",
    "Creator": "Creador",
    "Created": "Creado",
    "Current owner of this NFT.": "Propietario actual de este NFT.",
    "Custom Card": "Tarjeta Personalizada",
    "Customize": "Personalizar",
    "Customize this card by selecting one of the options below": "Personaliza esta tarjeta seleccionando una de las opciones a continuación",
    "Daily Transactions": "Transacciones Diarias",
    "Dark": "Oscuro",
    "Dark Mode": "Modo Oscuro",
    "Data": "Datos",
    "Data Security": "Seguridad de Datos",
    "Date for the snapshot": "Fecha para la instantánea",
    "Decode input data": "Decodificar datos de entrada",
    "Details": "Detalles",
    "Development": "Desarrollo",
    "Dim": "Tenue",
    "Directory": "Directorio",
    "Display All Chains": "Mostrar Todas las Cadenas",
    "Displaying current Value; Click to show Value on Day of Txn": "Mostrando Valor actual; Haz clic para mostrar el Valor en el Día de la Transacción",
    "Donations": "Donaciones",
    "Drag & drop files here, or click to select files": "Arrastra y suelta archivos aquí, o haz clic para seleccionar archivos",
    "Download": "Descargar",
    "Downloaded": "Descargado",
    "EVM Version": "Versión EVM",
    "Encode": "Codificar",
    "Encoded": "Codificado",
    "Encoding": "Codificación",
    "Enter Source Code...": "Ingresa el Código Fuente...",
    "Enter name here...": "Ingresa el nombre aquí...",
    "Ensure that token information is submitted. Check our token update guidelines": "Asegúrate de que se haya enviado la información del token. Consulta nuestras pautas de actualización de tokens",
    "Event Logs": "Registros de Eventos",
    "Events": "Eventos",
    "Ex. John Doe": "Ej. Juan Pérez",
    "Example questions": "Preguntas de ejemplo",
    "Explorer-as-a-Service": "Explorador como Servicio",
    "Export": "Exportar",
    "Extra Data": "Datos Extra",
    "Extra lunch money provided along with the occasional team lunch and dinners.": "Dinero extra para almuerzos junto con almuerzos y cenas de equipo ocasionales.",
    "FAQ": "Preguntas Frecuentes",
    "Failed": "Fallido",
    "Fast": "Rápido",
    "Fee": "Tarifa",
    "Fee Recipient": "Beneficiario de la Tarifa",
    "Feedback Form": "Formulario de Retroalimentación",
    "File": "Archivo",
    "Files": "Archivos",
    "Filter": "Filtrar",
    "Filters": "Filtros",
    "Filtered": "Filtrado",
    "Finalized": "Finalizado",
    "Flex Benefits": "Beneficios Flexibles",
    "Flexible working hours": "Horarios de trabajo flexibles",
    "Food Allowance": "Asignación de Alimentos",
    "For": "Para",
    "For almost anything that improves your life - training courses, fitness plans... even beauty facials!": "Para casi cualquier cosa que mejore tu vida: cursos de capacitación, planes de fitness... ¡incluso tratamientos faciales de belleza!",
    "For best practices, all contract source code holders, publishers and authors are encouraged to also specify the accompanying license for their verified contract source code provided.": "Para las mejores prácticas, se recomienda a todos los titulares de código fuente de contratos, editores y autores que también especifiquen la licencia acompañante para su código fuente de contrato verificado proporcionado.",
    "For each block, the block producer is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block.": "Para cada bloque, el productor de bloque es recompensado con una cantidad finita de Ether además de las tarifas pagadas por todas las transacciones en el bloque.",
    "Forums": "Foros",
    "Free Flow Drinks & Snacks": "Bebidas y Snacks de Flujo Libre",
    "From": "De",
    "Fully Diluted Market Cap": "Capitalización de Mercado Total Diluida",
    "Functionality": "Funcionalidad",
    "Functions": "Funciones",
    "Gas": "Gas",
    "Gas Fees": "Tarifas de Gas",
    "Gas Limit": "Límite de Gas",
    "Gas Limit & Usage by Txn": "Límite de Gas y Uso por Txn",
    "Gas Price": "Precio del Gas",
    "Gas Target": "Objetivo de Gas",
    "Gas Tracker": "Rastreador de Gas",
    "Gas Used": "Gas Utilizado",
    "Gas price history": "Historial de precios del gas",
    "Gas tracker": "Rastreador de gas",
    "Gas tracker overview": "Resumen del rastreador de gas",
    "General Inquiry": "Consulta General",
    "Github": "Github",
    "Get Started": "Empezar",
    "Help & Support": "Ayuda y Soporte",
    "Helpdesk Ticket Number": "Número de Ticket de Ayuda",
    "Hex": "Hex",
    "Hide All Chains": "Ocultar Todas las Cadenas",
    "Hide Chains": "Ocultar Cadenas",
    "Highlighted events of the transaction.": "Eventos destacados de la transacción.",
    "Historical Balance at a specific Block No or Date.": "Saldo Histórico en un Bloque Específico o Fecha.",
    "Home": "Inicio",
    "How We Use Your Information": "Cómo Usamos Tu Información",
    "How to Start": "Cómo Empezar",
    "I can help you in the following scenarios": "Puedo ayudarte en los siguientes escenarios",
    "I don't have an API Key": "No tengo una Clave API",
    "I have an API Key": "Tengo una Clave API",
    "ID": "ID",
    "If using the Solidity compiler, run solc —version to check.": "Si estás usando el compilador Solidity, ejecuta solc —version para verificar.",
    "If you have any additional notes, please leave them here": "Si tienes alguna nota adicional, por favor déjala aquí",
    "Implementation": "Implementación",
    "In addition to expediting token updates, we also provide priority support for other urgent or technical matters, such as contract verification.": "Además de acelerar las actualizaciones de tokens, también proporcionamos soporte prioritario para otros asuntos urgentes o técnicos, como la verificación de contratos.",
    "Indexed": "Indexado",
    "Information We Collect": "Información que Recopilamos",
    "Input Data": "Datos de Entrada",
    "Instagram": "Instagram",
    "Internal Transactions": "Transacciones Internas",
    "Internal transaction is transactions as a result of contract execution that involves Ether value.": "La transacción interna es aquella como resultado de la ejecución de un contrato que involucra valor de Ether.",
    "Is Yul Contract": "¿Es Contrato Yul?",
    "Item": "Item",
    "It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you and for periodically visiting our website and this privacy policy to check for any changes.": "Es nuestra política publicar cualquier cambio que hagamos en nuestra política de privacidad en esta página. La fecha en que se revisó por última vez la política de privacidad se identifica en la parte superior de la página. Usted es responsable de asegurarse de que tengamos una dirección de correo electrónico actualizada, activa y válida para usted y de visitar periódicamente nuestro sitio web y esta política de privacidad para verificar cualquier cambio.",
    "Join BlocksScan and enhance the world's leading blockchain explorer and search, API & analytics platform.": "Únete a BlocksScan y mejora el explorador de bloques líder mundial y la plataforma de búsqueda, API y análisis de blockchain.",
    "Keep up with the latest breakthroughs and best practices in the industry with learned and experienced teammates.": "Mantente al día con los últimos avances y las mejores prácticas en la industria con compañeros de equipo experimentados y conocedores.",
    "Kindly follow the simple steps below to get your support prioritized.": "Por favor, sigue los simples pasos a continuación para priorizar tu soporte.",
    "Kindly reach out to your wallet service provider, exchanges or project/contract owner for further support as they are in a better position to assist you on the issues related to and from their platforms.": "Por favor, ponte en contacto con tu proveedor de servicios de billetera, intercambios o propietario de proyectos/contratos para obtener más soporte, ya que están en una mejor posición para ayudarte con los problemas relacionados con y desde sus plataformas.",
    "Knowledge Base": "Base de Conocimientos",
    "Last Sale": "Última Venta",
    "Last Traded": "Último Intercambiado",
    "Last Updated": "Última Actualización",
    "Last updated": "Última actualización",
    "Latest Block": "Último Bloque",
    "Latest Blocks": "Últimos Bloques",
    "Latest Mints": "Últimas Acuñaciones",
    "Latest Signed Block": "Último Bloque Firmado",
    "Latest Transaction": "Última Transacción",
    "Latest Transactions": "Últimas Transacciones",
    "Latest Trades": "Últimos Intercambios",
    "Latest Transfers": "Últimas Transferencias",
    "Learn more about": "Aprende más sobre",
    "Learning Sessions": "Sesiones de Aprendizaje",
    "Legacy": "Herencia",
    "License": "Licencia",
    "Light": "Ligero",
    "List of": "Lista de",
    "List of {{_tokenType}}20 tokens transferred in the transaction.": "Lista de tokens {{_tokenType}}20 transferidos en la transacción.",
    "List of tokens burnt transferred in the transaction.": "Lista de tokens quemados transferidos en la transacción.",
    "List of tokens mint transferred in the transaction.": "Lista de tokens acuñados transferidos en la transacción.",
    "Load": "Cargar",
    "Loading": "Cargando",
    "Loading...": "Cargando...",
    "Logdata to clipboard": "Datos de registro al portapapeles",
    "Logs": "Registros",
    "Look up": "Buscar",
    "Lookup": "Búsqueda",
    "Low load": "Carga Baja",
    "MED GAS PRICE": "Precio Medio del Gas",
    "Main Interfaces (.vy or .json)": "Interfaces Principales (.vy o .json)",
    "Market": "Mercado",
    "Market Cap": "Capitalización de Mercado",
    "Market Capitalization": "Capitalización de Mercado",
    "Marketplaces": "Mercados",
    "Marketplaces trading this NFT.": "Mercados que negocian este NFT.",
    "Max": "Máximo",
    "Max Priority": "Prioridad Máxima",
    "Max Price": "Precio Máximo",
    "Max Total Supply": "Suministro Total Máximo",
    "Media & Press": "Medios de Comunicación y Prensa",
    "Medical Benefits": "Beneficios Médicos",
    "Method ID": "ID del Método",
    "Method Name": "Nombre del Método",
    "Method": "Método",
    "Method id": "ID del Método",
    "Metadata": "Metadatos",
    "Min Price": "Precio Mínimo",
    "Missing": "Faltante",
    "Mode": "Modo",
    "More Details": "Más Detalles",
    "More Info": "Más Información",
    "Multichain Info": "Información Multicadena",
    "Multichain Portfolio": "Cartera Multicadena",
    "Name": "Nombre",
    "Name Tag": "Etiqueta de Nombre",
    "NEVER": "Nunca",
    "NEVER send us these.": "NUNCA nos envíes esto.",
    "NEW": "Nuevo",
    "NFT Address": "Dirección del NFT",
    "NFT Latest Mints": "Últimas Emisiones del NFT",
    "NFT Top Mints": "Mejores Emisiones del NFT",
    "NFT Transfer": "Transferencia de NFT",
    "NFT Transfers": "Transferencias de NFT",
    "NFT's unique token ID.": "ID de Token Único del NFT.",
    "NFT": "NFT",
    "Node": "Nodo",
    "Nonce": "Número Aleatorio",
    "Non-Personal Information: Data that does not personally identify you but is linked to your usage of our services, such as IP addresses, browser types, and access times.": "Información No Personal: Datos que no te identifican personalmente pero están vinculados a tu uso de nuestros servicios, como direcciones IP, tipos de navegador y horarios de acceso.",
    "Normal": "Normal",
    "Note: Filtered by  totalSupply(), method returned a zero value.": "Nota: Filtrado por totalSupply(), el método devolvió un valor cero.",
    "Note": "Nota",
    "NOT": "No",
    "No Record Found": "No Se Encontraron Registros",
    "No transactions found.": "No se encontraron transacciones.",
    "Number": "Número",
    "Number of beacon withdrawals in this block.": "Número de retiros de balizas en este bloque.",
    "Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was produced.": "Número del bloque en el que se registra la transacción. Las confirmaciones de bloque indican cuántos bloques se han agregado desde que se produjo la transacción.",
    "OAuth": "OAuth",
    "Office Entertainment": "Entretenimiento de Oficina",
    "Onchain Market Cap": "Capitalización de Mercado en Cadena",
    "OpenAI API key": "Clave de API de OpenAI",
    "Optimization": "Optimización",
    "Optimization Enabled": "Optimización Habilitada",
    "Optimization Runs": "Ejecuciones de Optimización",
    "Order": "Orden",
    "Others": "Otros",
    "Ours isn’t just a grand vision. We back it up with the company and culture tailored for realizing it.": "El nuestro no es solo una gran visión. Lo respaldamos con la empresa y la cultura adaptadas para realizarlo.",
    "Our team will review your request and contact you using an official": "Nuestro equipo revisará tu solicitud y se pondrá en contacto contigo utilizando un",
    "Overview": "Resumen",
    "Owner": "Propietario",
    "Owners": "Propietarios",
    "Page": "Página",
    "Parent Hash": "Hash Padre",
    "Pending Transaction": "Transacción Pendiente",
    "Pending Transactions": "Transacciones Pendientes",
    "Pending...": "Pendiente...",
    "Percentage": "Porcentaje",
    "Perks and Benefits": "Beneficios y Ventajas",
    "Personal Information: Data that personally identifies you, such as your name, email address, and payment details, provided at registration or during the use of our services.": "Información Personal: Datos que te identifican personalmente, como tu nombre, dirección de correo electrónico y detalles de pago, proporcionados durante el registro o durante el uso de nuestros servicios.",
    "Phone": "Teléfono",
    "Platform": "Plataforma",
    "Please Select Your Message Subject": "Por Favor Selecciona el Asunto de tu Mensaje",
    "Please understand that we can only assist with issues directly related to the block explorer services. Before writing in, please be informed that": "Por favor, comprende que solo podemos ayudar con problemas directamente relacionados con los servicios de explorador de bloques. Antes de escribir, ten en cuenta que",
    "Please use this form to get in touch for priority support or featured listing with our team.": "Utiliza este formulario para ponerte en contacto para obtener soporte prioritario o listar con nuestro equipo.",
    "Position": "Posición",
    "Post-London Upgrade, this represents the minimum gas Used multiplier required for a tx to be included in a block.": "Después de la Actualización de Londres, esto representa el multiplicador mínimo de gas utilizado requerido para que una tx se incluya en un bloque.",
    "Post-London Upgrade, this represents the part of the tx fee that is burnt: baseFeePerGas * gasUsed.": "Después de la Actualización de Londres, esto representa la parte de la tarifa de tx que se quema: baseFeePerGas * gasUsed.",
    "Power": "Potencia",
    "Powered by BlocksScan": `Potenciado por ${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"}`,
    "BlocksScan is a Block Explorer and Analytics Platform for": `${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"} es un Explorador de Bloques y Plataforma de Análisis para`,
    "Present": "Presente",
    "Preserve": "Preservar",
    "Press": "Prensa",
    "Price": "Precio",
    "Primary compiled Vyper contract. Only add the main contract here whose bytecode has been deployed, all other files can be uploaded to the interfaces box below": "Contrato Vyper compilado principal. Solo agrega aquí el contrato principal cuyo código de bytes ha sido desplegado, todos los demás archivos se pueden cargar en el cuadro de interfaces a continuación",
    "Privacy": "Privacidad",
    "Privacy Policy": "Política de Privacidad",
    "Private Name Tags": "Etiquetas de Nombre Privadas",
    "Processing": "Procesando",
    "Products": "Productos",
    "Profile": "Perfil",
    "Programs": "Programas",
    "Project": "Proyecto",
    "Properties": "Propiedades",
    "Proposed On": "Propuesto En",
    "Proxy": "Proxy",
    "Publish": "Publicar",
    "Published": "Publicado",
    "Quantity": "Cantidad",
    "Rank": "Rango",
    "Read": "Leer",
    "Read Contract": "Leer Contrato",
    "Read Proxy": "Leer Proxy",
    "Real-time": "Tiempo Real",
    "Receipt": "Recibo",
    "Recent": "Reciente",
    "Record": "Registro",
    "Recover": "Recuperar",
    "Recovery": "Recuperación",
    "Recycle": "Reciclar",
    "Redo": "Rehacer",
    "Refresh": "Actualizar",
    "Register": "Registrar",
    "Registration": "Registro",
    "Related": "Relacionado",
    "Release": "Liberar",
    "Reload": "Recargar",
    "Removal": "Eliminación",
    "Remove": "Eliminar",
    "Removed": "Eliminado",
    "Removing": "Eliminando",
    "Renew": "Renovar",
    "Replace": "Reemplazar",
    "Request Removal of Name Tag": "Solicitar Eliminación de Etiqueta de Nombre",
    "Request": "Solicitud",
    "Requester Email": "Correo Electrónico del Solicitante",
    "Requester Name": "Nombre del Solicitante",
    "Requests": "Solicitudes",
    "Resend": "Reenviar",
    "Reset": "Restablecer",
    "Resources": "Recursos",
    "Response": "Respuesta",
    "Responses": "Respuestas",
    "Restore": "Restaurar",
    "Restrict": "Restringir",
    "Result": "Resultado",
    "Results": "Resultados",
    "Resume": "Reanudar",
    "Retain": "Retener",
    "Retrieve": "Recuperar",
    "Return": "Retorno",
    "Returned": "Devuelto",
    "Returning": "Devolviendo",
    "Review": "Revisión",
    "Rewards": "Recompensas",
    "Rewrite": "Reescribir",
    "Rich": "Rico",
    "Right": "Derecho",
    "Rights": "Derechos",
    "Role": "Rol",
    "Roles": "Roles",
    "Rollback": "Reversión",
    "Root": "Raíz",
    "Route": "Ruta",
    "Routing": "Enrutamiento",
    "Row": "Fila",
    "Rules": "Reglas",
    "Run": "Ejecutar",
    "Running": "Ejecutando",
    "Sales": "Ventas",
    "Save Changes": "Guardar Cambios",
    "Saved Filters": "Filtros Guardados",
    "Saving": "Guardando",
    "Scenario": "Escenario",
    "Schemas": "Esquemas",
    "Schedule": "Programar",
    "Scheduled": "Programado",
    "Scheduling": "Programación",
    "Schema": "Esquema",
    "Scope": "Alcance",
    "Screen": "Pantalla",
    "Scripts": "Scripts",
    "Search": "Buscar",
    "Ask AI | Search by Address | Txn Hash | Block | Token... ": "Buscar por dirección / hash de transacción / bloque / token... ",
    "Searches": "Búsquedas",
    "Searching": "Buscando",
    "Second": "Segundo",
    "Seconds": "Segundos",
    "Sec": "Seg",
    "Secret": "Secreto",
    "Section": "Sección",
    "Secure": "Seguro",
    "Securing": "Asegurando",
    "Security": "Seguridad",
    "Security Audit": "Auditoría de Seguridad",
    "See more details": "Ver más detalles",
    "See preview of the transaction details.": "Ver vista previa de los detalles de la transacción.",
    "Select": "Seleccionar",
    "Selected": "Seleccionado",
    "Selection": "Selección",
    "Send": "Enviar",
    "Send Message": "Enviar Mensaje",
    "Sender": "Remitente",
    "Sending": "Enviando",
    "Send native": "Enviar nativo",
    "Sequence": "Secuencia",
    "Server": "Servidor",
    "Service": "Servicio",
    "Services": "Servicios",
    "Session": "Sesión",
    "Sessions": "Sesiones",
    "Set": "Establecer",
    "Setting": "Configuración",
    "Settings": "Configuraciones",
    "Setup": "Configuración",
    "Share": "Compartir",
    "Shared": "Compartido",
    "Sharing": "Compartiendo",
    "Show": "Mostrar",
    "Show More Chains": "Mostrar Más Cadenas",
    "Show less": "Mostrar menos",
    "Show more": "Mostrar más",
    "Showing": "Mostrando",
    "Signer": "Firmante",
    "Similar Contract Search": "Búsqueda de Contrato Similar",
    "Simple steps for getting Priority Support.": "Pasos simples para obtener Soporte Prioritario.",
    "Size": "Tamaño",
    "Slow": "Lento",
    "Slot and epoch this block is proposed on.": "Ranura y época en la que se propone este bloque.",
    "Smart Contract": "Contrato Inteligente",
    "Smart Contract Search": "Búsqueda de Contrato Inteligente",
    "Smart Contracts": "Contratos Inteligentes",
    "Snapshot": "Instantánea",
    "Snapshots": "Instantáneas",
    "Social": "Social",
    "Software": "Software",
    "Solidity": "Solidity",
    "Solution": "Solución",
    "Solutions": "Soluciones",
    "Source Code": "Código Fuente",
    "Sources *.sol or *.yul files": "Archivos *.sol o *.yul de Fuentes",
    "Sources and Metadata JSON": "Fuentes y Metadata JSON",
    "Span": "Espacio",
    "Special": "Especial",
    "Specific": "Específico",
    "Specification": "Especificación",
    "Specifications": "Especificaciones",
    "Specified": "Especificado",
    "Specify": "Especificar",
    "Specs": "Especificaciones",
    "Sponsor": "Patrocinador",
    "Sponsored": "Patrocinado",
    "Sponsoring": "Patrocinando",
    "Sponsors": "Patrocinadores",
    "Spotlight": "Destacado",
    "Stable": "Estable",
    "Staging": "Escenario",
    "Standard JSON Input": "Entrada JSON Estándar",
    "Start": "Comienzo",
    "Starting": "Comenzando",
    "State": "Estado",
    "State Difference": "Diferencia de Estado",
    "State Root": "Raíz del Estado",
    "StateRoot": "Raíz del Estado",
    "State of the art coffee machine and experienced baristas in the house for the coffee lovers to thrive.": "Máquina de café de última generación y baristas experimentados en la casa para que los amantes del café prosperen.",
    "Statistics": "Estadísticas",
    "Status": "Estado",
    "Step 1": "Paso 1",
    "Step 2": "Paso 2",
    "Step 3": "Paso 3",
    "Steps": "Pasos",
    "Storage Of Assets": "Almacenamiento de Activos",
    "Store": "Almacenar",
    "Stored": "Almacenado",
    "Storing": "Almacenando",
    "Stream": "Transmisión",
    "Streaming": "Transmitiendo",
    "Streams": "Transmisiones",
    "Strength": "Fuerza",
    "String": "Cadena",
    "Strings": "Cadenas",
    "Structure": "Estructura",
    "Structures": "Estructuras",
    "Style": "Estilo",
    "Styles": "Estilos",
    "Submit": "Enviar",
    "Submitted": "Enviado",
    "Submitting": "Enviando",
    "Subscription": "Suscripción",
    "Subscriptions": "Suscripciones",
    "Subsequent": "Subsecuente",
    "Success": "Éxito",
    "Successful": "Exitoso",
    "Successfully": "Exitosamente",
    "Suggest Transaction Action": "Sugerir Acción de Transacción",
    "Suggestions": "Sugerencias",
    "Summary": "Resumen",
    "Support": "Soporte",
    "Supported": "Soportado",
    "Supporting": "Apoyando",
    "Supports": "Soporta",
    "Supply": "Suministro",
    "Surface": "Superficie",
    "Survey": "Encuesta",
    "Surveys": "Encuestas",
    "Suspend": "Suspender",
    "Suspended": "Suspendido",
    "Suspending": "Suspensión",
    "Swap": "Intercambio",
    "Swapping": "Intercambiando",
    "Switch": "Cambiar",
    "Switching": "Cambiando",
    "Symbol": "Símbolo",
    "Symbols": "Símbolos",
    "Sync": "Sincronización",
    "Synchronize": "Sincronizar",
    "Synchronized": "Sincronizado",
    "Synchronizing": "Sincronizando",
    "System": "Sistema",
    "Systems": "Sistemas",
    "TV": "Televisión",
    "Table": "Tabla",
    "Tables": "Tablas",
    "Tag": "Etiqueta",
    "Tags": "Etiquetas",
    "Take": "Tomar",
    "Taken": "Tomado",
    "Taking": "Tomando",
    "Target": "Objetivo",
    "Targets": "Objetivos",
    "Task": "Tarea",
    "Tasks": "Tareas",
    "Team": "Equipo",
    "Teams": "Equipos",
    "Technical": "Técnico",
    "Technique": "Técnica",
    "Techniques": "Técnicas",
    "Technology": "Tecnología",
    "Template": "Plantilla",
    "Templates": "Plantillas",
    "Temporary": "Temporal",
    "Terminate": "Terminar",
    "Terminated": "Terminado",
    "Terminating": "Terminación",
    "Terms": "Términos",
    "Test": "Prueba",
    "Tested": "Probado",
    "Testing": "Pruebas",
    "Tests": "Pruebas",
    "Text": "Texto",
    "Theme": "Tema",
    "Themes": "Temas",
    "Therapy": "Terapia",
    "This block is finalized.": "Este bloque está finalizado.",
    "This block is finilized.": "Este bloque está finalizado.",
    "This page allows you to customize your browsing experience on Blocksscan.": "Esta página le permite personalizar su experiencia de navegación en Blocksscan.",
    "This NFT's unique token ID.": "ID de token único de este NFT.",
    "Thread": "Hilo",
    "Threads": "Hilos",
    "Threshold": "Umbral",
    "Thresholds": "Límites",
    "Ticket": "Boleto",
    "Tickets": "Boletos",
    "Time": "Tiempo",
    "Timestamp": "Marca de tiempo",
    "Title": "Título",
    "Titles": "Títulos",
    "To": "A",
    "Token Contract": "Contrato de Token",
    "Token Contract Address": "Dirección del Contrato de Token",
    "Token Creation": "Creación de Token",
    "Token Holding": "Tenencia de Token",
    "Token ID": "ID de Token",
    "Token Inventory": "Inventario de Token",
    "Token Name": "Nombre de Token",
    "Token Standard": "Estándar de Token",
    "Token Transactions": "Transacciones de Token",
    "Token Transfers": "Transferencias de Token",
    "Token Update": "Actualización de Token",
    "Token creation": "Creación de Token",
    "Token transfer": "Transferencia de Token",
    "Tokens": "Tokens",
    "Tokens Transferred": "Tokens Transferidos",
    "Tokens burnt": "Tokens quemados",
    "Tokens minted": "Tokens acuñados",
    "Tokens transferred in the transaction.": "Tokens transferidos en la transacción.",
    "ToDo": "Por hacer",
    "To clipboard": "Al portapapeles",
    "Toggle": "Alternar",
    "Toggled": "Alternado",
    "Toggling": "Alternando",
    "Tool": "Herramienta",
    "Tools": "Herramientas",
    "Top Accounts": "Principales Cuentas",
    "Top Contract": "Contrato Principal",
    "Top Contracts": "Contratos Principales",
    "Top Mints": "Principales Acuñaciones",
    "Top NFT": "Principales NFT",
    "Top NFTs": "Principales NFTs",
    "Top Statistics": "Estadísticas Principales",
    "Top Token": "Token Principal",
    "Top Tokens": "Tokens Principales",
    "Topic": "Tema",
    "Topics": "Temas",
    "Total": "Total",
    "Total Accounts": "Total de Cuentas",
    "Total Assets": "Total de Activos",
    "Total Difficulty": "Dificultad Total de la Cadena hasta este Bloque",
    "Total Holders": "Total de Tenedores",
    "Total Transfers": "Total de Transferencias",
    "Total amount of ETH burnt from this tx & total fees saved from the amount the user was willing to pay for this tx.": "Cantidad total de ETH quemado en esta transacción y total de tarifas ahorradas de la cantidad que el usuario estaba dispuesto a pagar por esta transacción.",
    "Total gas limit provided by all transactions in the block.": "Límite total de gas proporcionado por todas las transacciones en el bloque.",
    "Total gas used in the block and its percentage of gas filled in the block.": "Gas total utilizado en el bloque y su porcentaje de gas llenado en el bloque.",
    "Total transactions": "Total de transacciones",
    "Trace": "Rastro",
    "Traces": "Rastreos",
    "Tracking": "Rastreo",
    "Trade": "Comercio",
    "Trades": "Comercios",
    "Trading": "Negociación",
    "Transaction": "Transacción",
    "Transaction Action": "Acción de Transacción",
    "Transaction Details": "Detalles de Transacción",
    "Transaction Fee": "Tarifa de Transacción",
    "Transaction Hash": "Hash de Transacción",
    "Transaction Type": "Tipo de Transacción",
    "Transactions": "Transacciones",
    "Transactions Internal": "Transacciones Internas",
    "Transcript": "Transcripción",
    "Transcripts": "Transcripciones",
    "Transfer": "Transferencia",
    "Transferred": "Transferido",
    "Transferring": "Transfiriendo",
    "Transfers": "Transferencias",
    "Translate": "Traducir",
    "Translated": "Traducido",
    "Translating": "Traduciendo",
    "Translation": "Traducción",
    "Translations": "Traducciones",
    "Trending": "Tendencia",
    "Trends": "Tendencias",
    "Trigger": "Activador",
    "Triggered": "Activado",
    "Triggering": "Activando",
    "Triggers": "Disparadores",
    "Troubleshoot": "Solucionar Problemas",
    "Troubleshooting": "Solución de Problemas",
    "Trust": "Confianza",
    "Trusted": "Confiable",
    "Trusting": "Confiable",
    "Try": "Intentar",
    "Trying": "Intentando",
    "Tx Fee": "Tarifa de Tx",
    "Tx Hash": "Hash de Tx",
    "Tx Hash|Block|Age": "Hash de Tx|Bloque|Edad",
    "Tx row trace to clipboard": "Rastro de fila de Tx al portapapeles",
    "Tx": "Tx",
    "Txn": "Txn",
    "Txn Savings": "Ahorros de Txn",
    "Txn Type": "Tipo de Txn",
    "Txns": "Txns",
    "Type": "Tipo",
    "Type here ...": "Escriba aquí ...",
    "Type of Backing": "Tipo de Respaldo",
    "Type of Peg": "Tipo de Anclaje",
    "Types": "Tipos",
    "UI": "Interfaz de Usuario",
    "URL": "URL",
    "URLs": "URLs",
    "Undo": "Deshacer",
    "Undone": "Deshacer",
    "Unfinished": "Inacabado",
    "Unit": "Unidad",
    "Units": "Unidades",
    "Universe": "Universo",
    "Unknown": "Desconocido",
    "Unlink": "Desvincular",
    "Unlinked": "Desvinculado",
    "Unlinking": "Desvinculando",
    "Unlock": "Desbloquear",
    "Unlocked": "Desbloqueado",
    "Unlocking": "Desbloqueando",
    "Unread": "No Leído",
    "Unresolved": "Sin Resolver",
    "Unseen": "No Visto",
    "Unsubscribe": "Cancelar Suscripción",
    "Unsubscribed": "Suscripción Cancelada",
    "Unsubscribing": "Cancelando Suscripción",
    "Untagged": "Sin Etiquetar",
    "Untagging": "Quitando Etiqueta",
    "Update": "Actualizar",
    "Update Proxy Contract's Implementation Address": "Actualizar la Dirección de Implementación del Contrato Proxy",
    "Update Token Info": "Actualizar Información de Token",
    "Updated": "Actualizado",
    "Updating": "Actualizando",
    "Uptime": "Uptime",
    "Us": "Nosotros",
    "Usage": "Uso",
    "Use": "Utilizar",
    "Used": "Utilizado",
    "User": "Usuario",
    "Users": "Usuarios",
    "Using": "Utilizando",
    "Utilities": "Utilidades",
    "Utility": "Utilidad",
    "Validate": "Validar",
    "Validated": "Validado",
    "Validating": "Validando",
    "Validation": "Validación",
    "Value": "Valor",
    "Values": "Valores",
    "Variable": "Variable",
    "Variables": "Variables",
    "Variant": "Variante",
    "Variants": "Variantes",
    "Varying": "Variable",
    "Vault": "Cámara",
    "Vaults": "Cámaras",
    "Vector": "Vector",
    "Vectors": "Vectores",
    "Verification": "Verificación",
    "Verification Method": "Método de Verificación",
    "Verified": "Verificado",
    "Verified At": "Verificado en",
    "Verified Contracts": "Contratos Verificados",
    "Verified Smart Contract": "Contrato Inteligente Verificado",
    "Verifying": "Verificando",
    "Version": "Versión",
    "Versions": "Versiones",
    "Verify & Publish": "Verificar y Publicar",
    "Verify And Publish": "Verificar y Publicar",
    "Verify Contract": "Verificar Contrato",
    "View": "Ver",
    "View Blocks": "Ver Bloques",
    "Views": "Vistas",
    "Viewing": "Visualizando",
    "Visible": "Visible",
    "Visit": "Visitar",
    "Visiting": "Visitando",
    "Visitor": "Visitante",
    "Visitors": "Visitantes",
    "Visual": "Visual",
    "Visuals": "Visuales",
    "Volume": "Volumen",
    "Volume 24h": "Volumen 24h",
    "Volumes": "Volúmenes",
    "Vote": "Votar",
    "Voted": "Votado",
    "Voter": "Votante",
    "Voters": "Votantes",
    "Voting": "Votación",
    "Votes": "Votos",
    "Vyper Online Compiler": "Compilador en línea Vyper",
    "WAITING": "ESPERANDO",
    "Wallet": "Billetera",
    "Wallet / Exchange / Project related issues": "Problemas relacionados con Billetera / Exchange / Proyecto",
    "Wallets": "Billeteras",
    "Warning": "Advertencia",
    "Warnings": "Advertencias",
    "Watch": "Observar",
    "Watching": "Observando",
    "Web": "Web",
    "Webpage": "Página web",
    "Webpages": "Páginas web",
    "Website": "Sitio web",
    "Websites": "Sitios web",
    "Welcome": "Bienvenido",
    "Welcome to Blocksscan Explorer Services": "Bienvenido a los Servicios de Exploración de Blocksscan",
    "Wellness": "Bienestar",
    "We're Hiring": "Estamos Contratando",
    "What are the functions or methods available in this contract?": "¿Cuáles son las funciones o métodos disponibles en este contrato?",
    "What does this contract do?": "¿Qué hace este contrato?",
    "What is the logical structure of this contract's code?": "¿Cuál es la estructura lógica del código de este contrato?",
    "Where to find my API Key?": "¿Dónde encontrar mi Clave API?",
    "While we try our best to respond in a timely manner, we often have a backlog of inquiries. Please do not": "Aunque intentamos responder de manera oportuna, a menudo tenemos un retraso en las consultas. Por favor, no",
    "White": "Blanco",
    "Who": "Quién",
    "Whole": "Todo",
    "Wholesale": "Venta al por mayor",
    "Widget": "Widget",
    "Widgets": "Widgets",
    "Width": "Ancho",
    "Widths": "Anchos",
    "Wiki": "Wiki",
    "Wikis": "Wikis",
    "Willing": "Dispuesto",
    "Win": "Ganar",
    "Window": "Ventana",
    "Windows": "Ventanas",
    "Winning": "Ganador",
    "Wins": "Ganancias",
    "Wire": "Cable",
    "Wires": "Cables",
    "Wiring": "Cableado",
    "Wisdom": "Sabiduría",
    "Wish": "Deseo",
    "Wished": "Deseado",
    "Wishes": "Deseos",
    "Wishing": "Deseando",
    "Withdraw": "Retirar",
    "Withdrawal": "Retiro",
    "Withdrawals": "Retiros",
    "WithdrawalsRoot": "Raíz de Retiros",
    "Withdrawing": "Retirando",
    "Withdrawn": "Retirado",
    "With": "Con",
    "Within": "Dentro de",
    "Without": "Sin",
    "Witness": "Testigo",
    "Witnessed": "Testificado",
    "Witnesses": "Testigos",
    "Witnessing": "Presenciando",
    "Work": "Trabajo",
    "Worked": "Trabajado",
    "Working": "Trabajando",
    "Works": "Trabajos",
    "Workspace": "Espacio de trabajo",
    "Workspaces": "Espacios de trabajo",
    "World": "Mundo",
    "Worldwide": "En todo el mundo",
    "Worry": "Preocupación",
    "Worried": "Preocupado",
    "Worries": "Preocupaciones",
    "Worrying": "Preocupante",
    "Worth": "Valor",
    "Write": "Escribir",
    "Write Contract": "Escribir contrato",
    "Write Proxy": "Escribir proxy",
    "Write me a smart contract of voting.": "Escríbeme un contrato inteligente de votación.",
    "Writes": "Escribe",
    "Writing": "Escribiendo",
    "Written": "Escrito",
    "Wrong": "Incorrecto",
    "Wrote": "Escribió",
    "X (Twitter)": "X (Twitter)",
    "XML": "XML",
    "XRay": "Rayos X",
    "Xrays": "Rayos X",
    "Year": "Año",
    "Years": "Años",
    "Yellow": "Amarillo",
    "Yes": "Sí",
    "Yesterday": "Ayer",
    "Yield": "Rendimiento",
    "Yielding": "Rendimiento",
    "Yields": "Rendimientos",
    "You can Lookup the Account": "Puedes buscar la cuenta",
    "Your browser does not support the video tag.": "Tu navegador no admite la etiqueta de vídeo.",
    "Your private keys with anyone.": "Tus claves privadas con nadie.",
    "Your Rights": "Tus derechos",
    "Youth": "Juventud",
    "Zone": "Zona",
    "Zones": "Zonas",
    "Zoning": "Zonificación",
    "Zoom": "Zoom",
    "Zoomed": "Hizo zoom",
    "Zooming": "Haciendo zoom",
    "Zooms": "Zooms",
    "rsync-builder in 12 secs": "Constructor de rsync en 12 segundos",
    "rsyncbuilder": "Constructor de rsync",
    "sec": "Seg",
    "tokens with a value of": "Tokens con un valor de",
    "to clipboard": "al portapapeles",
    "tokens transferred in the transaction.": "tokens transferidos en la transacción.",
    "withdrawals in this block": "retiros en este bloque",
    "we": "Nosotros",
    "us": "Nosotros",
    "our": "Nuestro"
}
}