import React, {FC} from 'react';
import {centerTruncate, truncate} from "../../../common/utils/function";
import useCommon from "../../../common/hooks/useCommon";
import BTooltip from "../../../components/b-tooltip";
import {useDispatch, useSelector} from "react-redux";
import {common} from "../../../store/slicer/common";

interface TxStatusProps {
    data?: any;
}

// @ts-ignore
const AddressName: FC<TxStatusProps> = React.memo((props) => {
    const dispatch = useDispatch();
    const {getXDC} = useCommon();
    const hoverAddress = useSelector((e: Object) => (e as any)?.common?.hoverAddress);

    return <span
        onMouseEnter={() => dispatch(common.actions.actionHoverAddress(props?.data?.hash))}
        onMouseLeave={() => dispatch(common.actions.actionHoverAddress(""))}
        className={hoverAddress === props?.data?.hash ? 'h_highlight bs_address' : 'bs_address'}
    >
        {props?.data?.is_contract && <BTooltip text="Contract"><i className="bi bi-file-text me-1 gray"></i></BTooltip>}
        {props?.data?.name ? truncate(props?.data?.name, 16) : (props?.data?.ens_domain_name ? truncate(props?.data?.ens_domain_name, 16) : centerTruncate(getXDC(props?.data?.hash)))}

    </span>;
});

export default AddressName;
