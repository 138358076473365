import React, {lazy, Suspense, useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import {useReadLocalStorage} from 'usehooks-ts';
import {useDispatch} from "react-redux";
import {common} from "./store/slicer/common";
import axios from "axios";
import {Bounce, ToastContainer} from 'react-toastify';
import GasDetails from './pages/gas-details';
import BSkeletonPage from "./components/b-skeleton/b-skeleton-page";
import DEXTracker from './pages/dex-tracker';
import DexChart from './pages/dex-tracker/dexChart';
import AdvancedFilter from './pages/advanced-filter';
import TabLayout from "./pages/common/layout/tab-layout";
import ReactGA from "react-ga4";
import Header from "./pages/common/layout/header";
import Footer from "./pages/common/layout/footer";
import B404 from "./components/b-404";
import ProfileLayout from "./pages/common/layout/profile-layout";


const MyAccount = lazy(() => import('./pages/myaccount'));
const MySettings = lazy(() => import('./pages/myaccount/mysettings'));
const MyAPIKey = lazy(() => import('./pages/myapikey'));
const MyWatchList = lazy(() => import('./pages/mywatchlist'));
const PrivateTag = lazy(() => import('./pages/private-nameTag'));
const TxnPrivateNotes = lazy(() => import('./pages/txn-private-notes'));
const ContactSection = lazy(() => import('./pages/contactus'));
const PrivacyPolicyPage = lazy(() => import('./pages/privacyPolicy'));
const Careers = lazy(() => import('./pages/careers'));
const PrioritySupport = lazy(() => import('./pages/prioritySupport'));
const APIPlanPage = lazy(() => import('./pages/common/api-plan'));
const PublicProfile = lazy(() => import('./pages/public-profile'));

const Dashboard = lazy(() => import('./pages/dashboard/bs-dashboard'));
const BNBDashboard = lazy(() => import('./pages/dashboard/bnb-dashboard'));
const ETHDashboard = lazy(() => import('./pages/dashboard/eth-dashboard'));

const TransactionList = lazy(() => import('./pages/transaction-list'));
const PendingTransactionList = lazy(() => import('./pages/pending-transaction-list'));
const TransactionDetails = lazy(() => import('./pages/transaction-details'));
const InternalTransaction = lazy(() => import('./pages/txsInternal'));
const BlockList = lazy(() => import('./pages/block-list'));
const BlockDetails = lazy(() => import('./pages/block-details'));

const AccountList = lazy(() => import('./pages/all-account-list'));
const AllMasterNodes = lazy(() => import('./pages/all-masternodes'));
const AllMasterNodesDetails = lazy(() => import('./pages/all-masternodes/masternodes-details'));
const VerifiedContractList = lazy(() => import('./pages/verified-contracts'));
const AccountDetails = lazy(() => import('./pages/account-details'));
const Download = lazy(() => import('./pages/download'));

const TokenDetails = lazy(() => import('./pages/token-details'));
const XRC20TokenList = lazy(() => import('./pages/xrc20-token-list'));
const XRC20TransferList = lazy(() => import('./pages/xrc20-transfer-list'));
const XRC404TokenList = lazy(() => import('./pages/xrc404-token-list'));
const XRC404TransferList = lazy(() => import('./pages/xrc404-transfer-list'));

const TopNFTs = lazy(() => import('./pages/top-nfts'));
const LatestTransferList = lazy(() => import('./pages/latest-transfer-list'));
const NFTDetails = lazy(() => import('./pages/nft-details'));
const TopMints = lazy(() => import('./pages/top-mints'));
const LatestTMintsList = lazy(() => import('./pages/latest-mints-list'));

const ChartStatsDetails = lazy(() => import('./pages/resources/charts-stats'));
const TopStatisticsDetails = lazy(() => import('./pages/resources/top-statistics'));
const XDCSupplyGrowthChart = lazy(() => import("./pages/resources/charts-stats/xdc-supply-growth"));
const GasTracker = lazy(() => import('./pages/gas-tracker'));
const XDCPriceChartDetails = lazy(() => import("./pages/resources/charts-stats/xdc-price"));
const XDCStatSupplyDetail = lazy(() => import("./pages/resources/charts-stats/xdc-stats-supply"));

const VerifyContract = lazy(() => import('./pages/developers/verify-contract'));
const CodeReader = lazy(() => import('./pages/code-reader'));

const TokenConverter = lazy(() => import('./pages/token-unit-converter'));
const BaseUnitConversion = lazy (() => import('./pages/number-base-conversion'));
const StringBytesConversion = lazy(() => import('./pages/string-bytes32-conversion'));
const EpochUtcConversion = lazy(() => import('./pages/epoch-utc-conversion'));
const TxDecoder = lazy(() => import('./pages/tx-decoder'));
const ConstructorDecoder = lazy(() => import('./pages/constructor_decoder'));
const EventDecoder = lazy(() => import('./pages/event-decoder'));
const CallDataDecoder = lazy(() => import('./pages/calldata-decoder'));
const VanityAddressGenerator = lazy(() => import('./pages/vanity-address-generator'));

const BalanceChecker = lazy(() => import('./pages/balance-checker'));
const TokenSupplyChecker = lazy(() => import('./pages/token-supply-checker'));
const TokenStandardChecker = lazy(() => import('./pages/token-standard-checker'));
const Settings = lazy(() => import('./pages/settings'));
const StyleGuide = lazy(() => import('./pages/style-guide'));
const BComingSoon = lazy(() => import('./components/b-coming-soon'));
const UnitConverter = lazy(() => import('./pages/unit-converter'));
const LabelCloud = lazy(() => import('./pages/lableCloud/index'));
const TokenHoldingChart = lazy(() => import('./pages/common/xrc20-token-holding-chart'));
const ContractLicensesTypes = lazy(() => import('./pages/common/contract-license-type'));
const BlockCountdown = lazy(() => import('./pages/block-details/block-countdown'));

const NodeTracker = lazy(() => import('./pages/node-tracker'));
const NodeList = lazy(() => import('./pages/node-tracker/node-list'));
const NodeDetails = lazy(() => import('./pages/node-tracker/node-list/common/node-detail'));

const lightTheme = "light-mode";
const dimTheme = "dim-mode";
const darkTheme = "dark-mode";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || "G-07BC9Z7FEJ");

const App = () => {
    // const {isDarkMode} = useDarkMode();
    const dispatch = useDispatch();

    const darkMode = useReadLocalStorage('isDarkMode') || "light"

    const getPrice = async () => {
        try {
            axios.get("https://xdc.network/api/setting/fiat").then(resFiat => {
                dispatch(common.actions.actionFiatList(resFiat.data))
            }).catch(() => '')
            // axios.get(`https://jsonblob.com/api/jsonBlob/1244239664025296896`).then(res => {
            axios.get(`${process.env.REACT_APP_BASE_URL}/eaas/config/${process.env.REACT_APP_BASE_UNIT}.json`).then(res => {
                dispatch(common.actions.actionConfig(res.data))
            }).catch(() => '')
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getPrice();
        const isRelativeTimeFormat = localStorage.getItem("isRelativeTimeFormat") === "true" ? true : false;
        const isUTCTimeZoneFormat = localStorage.getItem("isUTCTimeZoneFormat") === "true" ? true : false;
        ;
        dispatch(common.actions.actionIsRelativeTimeFormat(isRelativeTimeFormat));
        dispatch(common.actions.actionIsUTCTimeZoneFormat(isUTCTimeZoneFormat));
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Home Page" });
    }, [])

    return (
        <React.Fragment>
            <div className={darkMode == 'dark' ? darkTheme : darkMode == 'dim' ? dimTheme : lightTheme}>
                <div className="bs-layout">
                    <Header/>
                    <main className="bs-body">
                        <Suspense fallback={<BSkeletonPage/>}>
                            <Routes>
                                <Route path="/" element={<Dashboard/>}/>
                                <Route path="/dashboard" element={<Dashboard/>}/>
                                <Route path="/dashboard/bnb" element={<BNBDashboard/>}/>
                                <Route path="/dashboard/eth" element={<ETHDashboard/>}/>

                                <Route path="/txs" element={<TransactionList/>}/>
                                <Route path="/tx" element={<TransactionList/>}/>
                                <Route path="/txs/:hash" element={<TransactionDetails/>}/>
                                <Route path="/tx/:hash" element={<TransactionDetails/>}/>


                                <Route path="/blocks" element={<BlockList/>}/>
                                <Route path="/block/:block" element={<BlockDetails/>}/>
                                <Route path="/blocks/countdown/:block" element={<BlockCountdown/>}/>

                                <Route path="/accounts" element={<AccountList/>}/>
                                <Route path="/masternodes" element={<AllMasterNodes/>}/>
                                <Route path="/masternodes/:candidate" element={<AllMasterNodesDetails/>}/>
                                <Route path="/contracts/verify" element={<VerifyContract/>}/>
                                <Route path="/contracts" element={<VerifiedContractList/>}/>
                                <Route path="/address/:address" element={<AccountDetails/>}/>

                                <Route path="/trending-token" element={<XRC20TokenList/>}/>

                                <Route path="/token/:token" element={<TokenDetails/>}/>
                                <Route path="/tokens/:token" element={<TokenDetails/>}/>

                                <Route path="/tokens" element={<XRC20TokenList/>}/>
                                <Route path="/token" element={<XRC20TokenList/>}/>
                                <Route path="/tokentxns" element={<XRC20TransferList/>}/>
                                <Route path="/tokens/xrc404" element={<XRC404TokenList/>}/>
                                <Route path="/tokentxns/xrc404" element={<XRC404TransferList/>}/>
                                <Route path="/token/tokenholderchart/:token" element={<TokenHoldingChart/>}/>

                                <Route path="/charts" element={<ChartStatsDetails/>}/>
                                {/* <Route path="/topstatistics" element={<TopStatisticsDetails />} /> */}
                                 {/*<Route path="/chart/xdcprice" element={<XDCPriceChartDetails />}/>*/}
                                <Route path="/contactus" element={<ContactSection/>}/>

                                <Route path="/privacyPolicy" element={<PrivacyPolicyPage/>}/>
                                <Route path="/careers" element={<Careers/>}/>
                                <Route path="/prioritySupport" element={<BComingSoon/>}/>
                                <Route path="/chart/:chartType" element={<XDCPriceChartDetails/>}/>
                                <Route path="/stats/supply" element={<XDCStatSupplyDetail/>}/>
                                <Route path="/supply/growth" element={<XDCSupplyGrowthChart/>}/>
                                <Route path="/contract-license-types" element={<ContractLicensesTypes/>}/>

                                <Route path="/settings" element={<Settings/>}/>
                                <Route path="/styleguide" element={<StyleGuide/>}/>
                                <Route path="/gastracker" element={<GasTracker/>}/>
                                <Route path="/gasdetails" element={<GasDetails/>}/>

                                <Route path="/txsPending" element={<PendingTransactionList/>}/>
                                <Route path="/txsInternal" element={<InternalTransaction/>}/>
                                <Route path="/txsBeaconDeposit" element={<BComingSoon/>}/>
                                <Route path="/txsBeaconWithdrawal" element={<BComingSoon/>}/>
                                <Route path="/blocks_forked" element={<BComingSoon/>}/>
                                <Route path="/uncles" element={<BComingSoon/>}/>
                                <Route path="/nft-top-contracts" element={<TopNFTs/>}/>
                                <Route path="/nft-top-mints" element={<TopMints/>}/>
                                <Route path="/nft-trades" element={<BComingSoon/>}/>
                                <Route path="/nft-transfers" element={<LatestTransferList/>}/>
                                <Route path="/nft-latest-mints" element={<LatestTMintsList/>}/>
                                <Route path="/nft/:token/:id" element={<NFTDetails/>}/>
                                <Route path="/topstat" element={<TopStatisticsDetails/>}/>
                                <Route path="/leaderboard/advanced-filter" element={<BComingSoon/>}/>
                                <Route path="/directory" element={<BComingSoon/>}/>
                                <Route path="/newsletters" element={<BComingSoon/>}/>
                                <Route path="/apis" element={<APIPlanPage/>}/>
                                <Route path="/code-reader" element={<BComingSoon/>}/>

                                <Route path="/searchcontract" element={<BComingSoon/>}/>
                                <Route path="/contractdiffchecker" element={<BComingSoon/>}/>

                                <Route path="/AI" element={<CodeReader/>}/>
                                <Route path="/dex" element={<DEXTracker />}/>
                                <Route path="/nodetracker" element={<NodeTracker />}/>
                                <Route path="/nodetracker/nodes" element={<NodeList />}/>
                                <Route path="/nodetracker/node/:node" element={<NodeDetails />}/>
                                <Route path="/charts/dex" element={<DexChart />}/>

                                <Route path="/labelcloud" element={<LabelCloud/>}/>

                                <Route path="/advanced-filter" element={<AdvancedFilter/>}/>
                                <Route element={<TabLayout/>}>
                                    <Route path="/exportData" element={<Download/>}/>
                                    <Route path="/balancecheck-tool" element={<BalanceChecker/>}/>
                                    <Route path="/tokencheck-tool" element={<TokenSupplyChecker/>}/>
                                    <Route path="/tokentracker" element={<TokenStandardChecker/>}/>
                                    <Route path="/find-similar-contracts" element={<BComingSoon/>}/>
                                    <Route path="/vyper" element={<BComingSoon/>}/>
                                    <Route path="/opcode-tool" element={<BComingSoon/>}/>
                                    <Route path="/pushTx" element={<BComingSoon/>}/>
                                    <Route path="/unitconverter" element={<UnitConverter/>}/>
                                    <Route path="/tokenunitconverter" element={<TokenConverter/>}/>
                                    <Route path="/baseunitconversion" element={<BaseUnitConversion/>}/>
                                    <Route path="/stringbytesConversion" element={<StringBytesConversion/>}/>
                                    <Route path="/epochtoutc" element={<EpochUtcConversion/>}/>
                                    <Route path="/txdecoder" element={<TxDecoder/>}/>
                                    <Route path="/constructordecoder" element={<ConstructorDecoder/>}/>
                                    <Route path="/eventdecoder" element={<EventDecoder/>}/>
                                    <Route path="/calldatadecoder" element={<CallDataDecoder/>}/>
                                    <Route path="/vanityaddressgenerator" element={<VanityAddressGenerator/>}/>
                                </Route>
                                <Route element={<ProfileLayout/>}>
                                    <Route path="/myaccount" element={<MyAccount/>}/>
                                    <Route path="/mysettings" element={<MySettings/>}/>
                                    <Route path="/my-public-profile" element={<PublicProfile/>}/>
                                    <Route path="/myaddress" element={<MyWatchList/>}/>
                                    <Route path="/mynotes_address" element={<PrivateTag/>}/>
                                    <Route path="/mynotes_tx" element={<TxnPrivateNotes/>}/>
                                    <Route path="/mytokenignore" element={<BComingSoon/>}/>
                                    <Route path="/myadvancedfilters" element={<BComingSoon/>}/>
                                    <Route path="/myapikey" element={<MyAPIKey/>}/>
                                    <Route path="/myverify_address" element={<BComingSoon/>}/>
                                    <Route path="/mycustomabi" element={<BComingSoon/>}/>
                                </Route>
                                <Route path='*' element={<B404/>} />
                            </Routes>
                        </Suspense>
                    </main>
                    <Footer/>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    transition={Bounce}
                />
                <ToastContainer/>
            </div>
            <div id="popup-root" className={darkMode == 'dark' ? darkTheme : darkMode == 'dim' ? dimTheme : lightTheme}/>
        </React.Fragment>
    );
}

export default App;
