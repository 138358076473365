export const vi = {
  translations: {
    "ABI for the implementation contract at": "ABI cho hợp đồng triển khai tại",
    "ABI-encoded": "Mã hóa ABI",
    "API Documentation": "Tài liệu API",
    "API Plans": "Gói API",
    "API Support": "Hỗ trợ API",
    "About Us": "Về chúng tôi",
    "Account": "Tài khoản",
    "Account Balance Checker": "Kiểm tra số dư tài khoản",
    "Action": "Hành động",
    "Add Name Tag/Label": "Thêm thẻ tên/nhãn",
    "Add one more": "Thêm một cái nữa",
    "Additional Info": "Thông tin bổ sung",
    "Additional Notes": "Ghi chú bổ sung",
    "Address": "Địa chỉ",
    "Address of this NFT contract.": "Địa chỉ của hợp đồng NFT này.",
    "Address that deployed this NFT contract.": "Địa chỉ đã triển khai hợp đồng NFT này.",
    "Advertising": "Quảng cáo",
    "Age": "Tuổi",
    "All Rights Reserved": "Bảo lưu mọi quyền",
    "All Transactions": "Tất cả giao dịch",
    "Amount": "Số tiền",
    "Analytics": "Phân tích",
    "Annual Trips": "Chuyến đi hàng năm",
    "Are you the contract creator": "Bạn có phải là người tạo hợp đồng",
    "Back to Top": "Trở về đầu trang",
    "Balance": "Số dư",
    "Balance Checker": "Kiểm tra số dư",
    "Base": "Cơ sở",
    "Base Fee": "Phí cơ bản",
    "Base Fee Per Gas": "Phí cơ bản trên mỗi Gas",
    "Best Coffee in Town": "Cà phê ngon nhất trong thành phố",
    "Block": "Khối",
    "Block Height": "Chiều cao khối",
    "Block Number": "Số khối",
    "Block Reward": "Phần thưởng khối",
    "Block header withdrawal root hash.": "Hash gốc rút tiền của tiêu đề khối.",
    "Block nonce is a value used during mining to demonstrate proof of work for a block.": "Nonce của khối là một giá trị được sử dụng trong quá trình khai thác để chứng minh công việc cho một khối.",
    "Block proposed on epoch": "Khối được đề xuất vào kỷ nguyên",
    "Blocksscan is an independent": "Blocksscan là một trang độc lập",
    "Blockchain": "Chuỗi khối",
    "Blocks": "Các khối",
    "Broadcast Transaction": "Phát sóng giao dịch",
    "Brand Assets": "Tài sản thương hiệu",
    "Bridge": "Cầu nối",
    "Burnt": "Đốt",
    "Burnt & Txn Savings Fees": "Phí tiết kiệm đốt & Txn",
    "Byte Code": "Mã Byte",
    "Bytecode to Opcode": "Mã Byte thành Opcode",
    "By using Blocksscan Explorer Services, you consent to our Privacy Policy.": "Bằng cách sử dụng Dịch vụ Explorer Blocksscan, bạn đồng ý với Chính sách Bảo mật của chúng tôi.",
    "CSV Export": "Xuất CSV",
    "Call": "Gọi",
    "Capacity": "Dung lượng",
    "Careers": "Nghề nghiệp",
    "Careers at BlocksScan": "Nghề nghiệp tại BlocksScan",
    "Change Fiat Currency": "Thay đổi tiền tệ Fiat",
    "Change": "Thay đổi",
    "Charts & stats": "Biểu đồ & số liệu",
    "Charts And Stats": "Biểu đồ và số liệu",
    "Choose Priority Service": "Chọn dịch vụ ưu tiên",
    "Choose desired language": "Chọn ngôn ngữ mong muốn",
    "Choose the theme": "Chọn chủ đề",
    "Circulating Market Cap": "Vốn hóa thị trường lưu hành",
    "Circulating Supply": "Nguồn cung lưu hành",
    "Circulating Supply Market Cap": "Vốn hóa thị trường lưu hành",
    "Classification of NFT based on how the image is stored: On-Chain refers to those with data stored on the blockchain while Off-Chain refers to those with data stored elsewhere on the Internet.": "Phân loại NFT dựa trên cách lưu trữ hình ảnh: On-Chain đề cập đến những dữ liệu được lưu trữ trên blockchain trong khi Off-Chain đề cập đến những dữ liệu được lưu trữ ở nơi khác trên Internet.",
    "Classification": "Phân loại",
    "Click here to": "Bấm vào đây để",
    "Click to show less": "Nhấp để hiển thị ít hơn",
    "Click to show more": "Nhấp để hiển thị nhiều hơn",
    "Close": "Đóng",
    "Code": "Mã",
    "Code Reader": "Đọc mã",
    "CodeRunAI": "CodeRunAI",
    "Coin transfer": "Chuyển tiền xu",
    "Collater­alization": "Thế chấp",
    "Collection": "Bộ sưu tập",
    "Collection Name": "Tên bộ sưu tập",
    "Commute Allowance": "Phụ cấp đi lại",
    "Community": "Cộng đồng",
    "Community Support": "Hỗ trợ cộng đồng",
    "Company": "Công ty",
    "Compiler": "Trình biên dịch",
    "Compiler Type": "Loại trình biên dịch",
    "Compiler Version": "Phiên bản trình biên dịch",
    "COMPILER TYPE AND VERSION SELECTION": "LỰA CHỌN LOẠI VÀ PHIÊN BẢN TRÌNH BIÊN DỊCH",
    "ConfirmationToken": "Xác nhận",
    "Confirmation": "Xác nhận",
    "Confirmed within": "Xác nhận trong vòng",
    "Confirming": "Đang xác nhận",
    "Contact Us": "Liên hệ chúng tôi",
    "Contact Information": "Thông tin liên hệ",
    "Contract": "Hợp đồng",
    "Contract Address": "Địa chỉ hợp đồng",
    "Contract Code": "Mã hợp đồng",
    "Contract Diff Checker": "Kiểm tra sự khác biệt hợp đồng",
    "Contract File Path": "Đường dẫn tập tin hợp đồng",
    "Contract Internal Transactions": "Giao dịch nội bộ hợp đồng",
    "Contract Licence": "Giấy phép hợp đồng",
    "Contract Name": "Tên hợp đồng",
    "Contract Source Code Verified": "Mã nguồn hợp đồng đã xác minh",
    "Contract Verification": "Xác minh hợp đồng",
    "Contract call": "Gọi hợp đồng",
    "Contract creation": "Tạo hợp đồng",
    "Contracts": "Hợp đồng",
    "Contracts Internal": "Hợp đồng nội bộ",
    "Cost per unit of gas spent for the transaction, in Ether and Gwei.": "Chi phí mỗi đơn vị gas cho giao dịch, bằng Ether và Gwei.",
    "Creator": "Người tạo",
    "Created": "Đã tạo",
    "Current owner of this NFT.": "Chủ sở hữu hiện tại của NFT này.",
    "Custom Card": "Thẻ tùy chỉnh",
    "Customize": "Tùy chỉnh",
    "Customize this card by selecting one of the options below": "Tùy chỉnh thẻ này bằng cách chọn một trong các tùy chọn bên dưới",
    "Daily Transactions": "Giao dịch hàng ngày",
    "Dark": "Tối",
    "Dark Mode": "Chế độ tối",
    "Data": "Dữ liệu",
    "Data Security": "Bảo mật dữ liệu",
    "Date for the snapshot": "Ngày cho snapshot",
    "Decode input data": "Giải mã dữ liệu đầu vào",
    "Details": "Chi tiết",
    "Development": "Phát triển",
    "Dim": "Mờ",
    "Directory": "Thư mục",
    "Display All Chains": "Hiển thị tất cả chuỗi",
    "Displaying current Value; Click to show Value on Day of Txn": "Hiển thị giá trị hiện tại; Bấm để hiển thị giá trị vào Ngày của Txn",
    "Donations": "Quyên góp",
    "Drag & drop files here, or click to select files": "Kéo & thả tập tin vào đây, hoặc bấm để chọn tập tin",
    "Download": "Tải xuống",
    "Downloaded": "Đã tải xuống",
    "EVM Version": "Phiên bản EVM",
    "Encode": "Mã hóa",
    "Encoded": "Đã mã hóa",
    "Encoding": "Mã hóa",
    "Enter Source Code...": "Nhập mã nguồn...",
    "Enter name here...": "Nhập tên ở đây...",
    "Ensure that token information is submitted. Check our token update guidelines": "Đảm bảo thông tin mã token được gửi đi. Kiểm tra hướng dẫn cập nhật mã token của chúng tôi",
    "Event Logs": "Nhật ký sự kiện",
    "Events": "Sự kiện",
    "Ex. John Doe": "Ví dụ: John Doe",
    "Example questions": "Câu hỏi mẫu",
    "Explorer-as-a-Service": "Explorer-as-a-Service",
    "Export": "Xuất",
    "Extra Data": "Dữ liệu bổ sung",
    "Extra lunch money provided along with the occasional team lunch and dinners.": "Cung cấp tiền ăn trưa bổ sung cùng với các bữa ăn trưa và bữa tối nhóm thỉnh thoảng.",
    "FAQ": "Câu hỏi thường gặp",
    "Failed": "Thất bại",
    "Fast": "Nhanh",
    "Fee": "Phí",
    "Fee Recipient": "Người nhận phí",
    "Feedback Form": "Biểu mẫu phản hồi",
    "File": "Tập tin",
    "Files": "Tập tin",
    "Filter": "Bộ lọc",
    "Filters": "Bộ lọc",
    "Filtered": "Đã lọc",
    "Finalized": "Đã hoàn tất",
    "Flex Benefits": "Lợi ích linh hoạt",
    "Flexible working hours": "Giờ làm việc linh hoạt",
    "Food Allowance": "Phụ cấp ăn uống",
    "For": "Cho",
    "For almost anything that improves your life - training courses, fitness plans... even beauty facials!": "Cho hầu hết mọi thứ giúp cải thiện cuộc sống của bạn - khóa học đào tạo, kế hoạch tập thể dục... thậm chí là làm đẹp!",
    "For best practices, all contract source code holders, publishers and authors are encouraged to also specify the accompanying license for their verified contract source code provided.": "Để thực hành tốt nhất, tất cả các nhà giữ mã nguồn hợp đồng, nhà xuất bản và tác giả được khuyến khích cũng chỉ định giấy phép đi kèm cho mã nguồn hợp đồng đã xác minh của họ.",
    "For each block, the block producer is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block.": "Mỗi khối, nhà sản xuất khối được thưởng một lượng Ether hữu hạn trên tổng số phí được trả cho tất cả các giao dịch trong khối.",
    "Forums": "Diễn đàn",
    "Free Flow Drinks & Snacks": "Đồ uống & đồ ăn nhẹ miễn phí",
    "From": "Từ",
    "Fully Diluted Market Cap": "Vốn hóa thị trường pha loãng hoàn toàn",
    "Functionality": "Chức năng",
    "Functions": "Chức năng",
    "Gas": "Gas",
    "Gas Fees": "Phí Gas",
    "Gas Limit": "Giới hạn Gas",
    "Gas Limit & Usage by Txn": "Giới hạn & Sử dụng Gas bởi Txn",
    "Gas Price": "Giá Gas",
    "Gas Target": "Mục tiêu Gas",
    "Gas Tracker": "Theo dõi Gas",
    "Gas Used": "Gas đã sử dụng",
    "Gas price history": "Lịch sử giá Gas",
    "Gas tracker": "Theo dõi Gas",
    "Gas tracker overview": "Tổng quan theo dõi Gas",
    "General Inquiry": "Yêu cầu chung",
    "Github": "Github",
    "Get Started": "Bắt đầu",
    "Help & Support": "Hỗ trợ & giúp đỡ",
    "Helpdesk Ticket Number": "Số vé hỗ trợ",
    "Hex": "Hex",
    "Hide All Chains": "Ẩn tất cả chuỗi",
    "Hide Chains": "Ẩn chuỗi",
    "Highlighted events of the transaction.": "Các sự kiện nổi bật của giao dịch.",
    "Historical Balance at a specific Block No or Date.": "Số dư lịch sử tại một số khối hoặc ngày cụ thể.",
    "Home": "Trang chủ",
    "How We Use Your Information": "Chúng tôi sử dụng thông tin của bạn như thế nào",
    "How to Start": "Cách bắt đầu",
    "I can help you in the following scenarios": "Tôi có thể giúp bạn trong các tình huống sau",
    "I don't have an API Key": "Tôi không có khóa API",
    "I have an API Key": "Tôi có khóa API",
    "ID": "ID",
    "If using the Solidity compiler, run solc —version to check.": "Nếu sử dụng trình biên dịch Solidity, chạy solc —version để kiểm tra.",
    "If you have any additional notes, please leave them here": "Nếu bạn có ghi chú bổ sung, vui lòng để lại ở đây",
    "Implementation": "Thực hiện",
    "In addition to expediting token updates, we also provide priority support for other urgent or technical matters, such as contract verification.": "Ngoài việc đẩy nhanh cập nhật mã token, chúng tôi cũng cung cấp hỗ trợ ưu tiên cho các vấn đề khẩn cấp hoặc kỹ thuật khác, chẳng hạn như xác minh hợp đồng.",
    "Indexed": "Đã lập chỉ mục",
    "Information We Collect": "Thông tin chúng tôi thu thập",
    "Input Data": "Dữ liệu đầu vào",
    "Instagram": "Instagram",
    "Internal Transactions": "Giao dịch nội bộ",
    "Internal transaction is transactions as a result of contract execution that involves Ether value.": "Giao dịch nội bộ là các giao dịch kết quả của việc thực hiện hợp đồng liên quan đến giá trị Ether.",
    "Is Yul Contract": "Có phải là hợp đồng Yul",
    "Item": "Mục",
    "It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you and for periodically visiting our website and this privacy policy to check for any changes.": "Chính sách của chúng tôi là đăng bất kỳ thay đổi nào mà chúng tôi thực hiện đối với chính sách bảo mật của chúng tôi trên trang này. Ngày mà chính sách bảo mật được sửa đổi lần cuối được xác định ở đầu trang. Bạn có trách nhiệm đảm bảo rằng chúng tôi có một địa chỉ email hiện tại, hoạt động và có thể nhận được cho bạn và định kỳ truy cập trang web của chúng tôi và chính sách bảo mật này để kiểm tra bất kỳ thay đổi nào.",
    "Join BlocksScan and enhance the world's leading blockchain explorer and search, API & analytics platform.": "Tham gia BlocksScan và cải thiện nền tảng khám phá và tìm kiếm blockchain hàng đầu thế giới, API & phân tích.",
    "Keep up with the latest breakthroughs and best practices in the industry with learned and experienced teammates.": "Cập nhật các đột phá và thực tiễn tốt nhất mới nhất trong ngành với các đồng đội có kinh nghiệm và học hỏi.",
    "Kindly follow the simple steps below to get your support prioritized.": "Vui lòng làm theo các bước đơn giản dưới đây để được ưu tiên hỗ trợ.",
    "Kindly reach out to your wallet service provider, exchanges or project/contract owner for further support as they are in a better position to assist you on the issues related to and from their platforms.": "Vui lòng liên hệ với nhà cung cấp dịch vụ ví của bạn, các sàn giao dịch hoặc chủ dự án/hợp đồng để được hỗ trợ thêm vì họ ở vị trí tốt hơn để hỗ trợ bạn về các vấn đề liên quan đến và từ các nền tảng của họ.",
    "Knowledge Base": "Cơ sở tri thức",
    "Last Sale": "Bán hàng cuối cùng",
    "Last Traded": "Giao dịch cuối cùng",
    "Last Updated": "Cập nhật lần cuối",
    "Last updated": "Cập nhật lần cuối",
    "Latest Block": "Khối mới nhất",
    "Latest Blocks": "Các khối mới nhất",
    "Latest Mints": "Các sản phẩm đúc mới nhất",
    "Latest Signed Block": "Khối đã ký mới nhất",
    "Latest Transaction": "Giao dịch mới nhất",
    "Latest Transactions": "Các giao dịch mới nhất",
    "Latest Trades": "Các giao dịch mới nhất",
    "Latest Transfers": "Các chuyển khoản mới nhất",
    "Learn more about": "Tìm hiểu thêm về",
    "Learning Sessions": "Các buổi học",
    "Legacy": "Di sản",
    "License": "Giấy phép",
    "Light": "Sáng",
    "List of": "Danh sách",
    "List of {{_tokenType}}20 tokens transferred in the transaction.": "Danh sách {{_tokenType}}20 mã token được chuyển trong giao dịch.",
    "List of tokens burnt transferred in the transaction.": "Danh sách mã token đã đốt được chuyển trong giao dịch.",
    "List of tokens mint transferred in the transaction.": "Danh sách mã token đã đúc được chuyển trong giao dịch.",
    "Load": "Tải",
    "Loading": "Đang tải",
    "Loading...": "Đang tải...",
    "Logdata to clipboard": "Dữ liệu nhật ký vào clipboard",
    "Logs": "Nhật ký",
    "Look up": "Tra cứu",
    "Lookup": "Tra cứu",
    "Low load": "Tải nhẹ",
    "MED GAS PRICE": "GIÁ GAS TRUNG BÌNH",
    "Main Interfaces (.vy or .json)": "Giao diện chính (.vy hoặc .json)",
    "Market": "Thị trường",
    "Market Cap": "Vốn hóa thị trường",
    "Market Capitalization": "Vốn hóa thị trường",
    "Marketplaces": "Các sàn giao dịch",
    "Marketplaces trading this NFT.": "Các sàn giao dịch mua bán NFT này.",
    "Max": "Tối đa",
    "Max Priority": "Ưu tiên tối đa",
    "Max Price": "Giá tối đa",
    "Max Total Supply": "Tổng nguồn cung tối đa",
    "Media & Press": "Truyền thông & Báo chí",
    "Medical Benefits": "Phúc lợi y tế",
    "Method ID": "ID Phương thức",
    "Method Name": "Tên Phương thức",
    "Method": "Phương thức",
    "Method id": "ID Phương thức",
    "Metadata": "Siêu dữ liệu",
    "Min Price": "Giá tối thiểu",
    "Missing": "Thiếu",
    "Mode": "Chế độ",
    "More Details": "Chi tiết thêm",
    "More Info": "Thông tin thêm",
    "Multichain Info": "Thông tin đa chuỗi",
    "Multichain Portfolio": "Danh mục đầu tư đa chuỗi",
    "Name": "Tên",
    "Name Tag": "Thẻ tên",
    "NEVER": "KHÔNG BAO GIỜ",
    "NEVER send us these.": "KHÔNG BAO GIỜ gửi cho chúng tôi những thứ này.",
    "NEW": "MỚI",
    "NFT Address": "Địa chỉ NFT",
    "NFT Latest Mints": "NFT Đúc mới nhất",
    "NFT Top Mints": "NFT Đúc hàng đầu",
    "NFT Transfer": "Chuyển NFT",
    "NFT Transfers": "Các chuyển NFT",
    "NFT's unique token ID.": "ID token độc nhất của NFT.",
    "NFT": "NFT",
    "Node": "Nút",
    "Nonce": "Nonce",
    "Non-Personal Information: Data that does not personally identify you but is linked to your usage of our services, such as IP addresses, browser types, and access times.": "Thông tin không cá nhân: Dữ liệu không xác định cá nhân bạn nhưng liên quan đến việc sử dụng dịch vụ của bạn, chẳng hạn như địa chỉ IP, loại trình duyệt và thời gian truy cập.",
    "Normal": "Bình thường",
    "Note: Filtered by totalSupply() , method returned a zero value.": "Lưu ý: Được lọc bởi totalSupply(), phương thức trả về giá trị bằng không.",
    "Note": "Ghi chú",
    "NOT": "KHÔNG",
    "No Record Found": "Không tìm thấy bản ghi",
    "No transactions found.": "Không tìm thấy giao dịch.",
    "Number": "Số",
    "Number of beacon withdrawals in this block.": "Số lần rút tiền từ beacon trong khối này.",
    "Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was produced.": "Số của khối trong đó giao dịch được ghi lại. Các xác nhận khối chỉ ra có bao nhiêu khối đã được thêm kể từ khi giao dịch được tạo ra.",
    "OAuth": "OAuth",
    "Office Entertainment": "Giải trí văn phòng",
    "Onchain Market Cap": "Vốn hóa thị trường Onchain",
    "OpenAI API key": "Khóa API OpenAI",
    "Optimization": "Tối ưu hóa",
    "Optimization Enabled": "Đã bật tối ưu hóa",
    "Optimization Runs": "Lần chạy tối ưu hóa",
    "Order": "Đơn hàng",
    "Others": "Khác",
    "Ours isn’t just a grand vision. We back it up with the company and culture tailored for realizing it.": "Tầm nhìn của chúng tôi không chỉ là lớn lao. Chúng tôi hỗ trợ nó bằng công ty và văn hóa phù hợp để thực hiện.",
    "Our team will review your request and contact you using an official": "Đội ngũ của chúng tôi sẽ xem xét yêu cầu của bạn và liên hệ với bạn bằng cách chính thức",
    "Overview": "Tổng quan",
    "Owner": "Chủ sở hữu",
    "Owners": "Các chủ sở hữu",
    "Page": "Trang",
    "Parent Hash": "Hash gốc",
    "Pending Transaction": "Giao dịch đang chờ",
    "Pending Transactions": "Các giao dịch đang chờ",
    "Pending...": "Đang chờ...",
    "Percentage": "Phần trăm",
    "Perks and Benefits": "Ưu đãi và phúc lợi",
    "Personal Information: Data that personally identifies you, such as your name, email address, and payment details, provided at registration or during the use of our services.": "Thông tin cá nhân: Dữ liệu nhận dạng cá nhân bạn, chẳng hạn như tên, địa chỉ email và chi tiết thanh toán, được cung cấp khi đăng ký hoặc trong quá trình sử dụng dịch vụ của chúng tôi.",
    "Phone": "Điện thoại",
    "Platform": "Nền tảng",
    "Please Select Your Message Subject": "Vui lòng chọn chủ đề tin nhắn của bạn",
    "Please understand that we can only assist with issues directly related to the block explorer services. Before writing in, please be informed that": "Vui lòng hiểu rằng chúng tôi chỉ có thể hỗ trợ các vấn đề liên quan trực tiếp đến dịch vụ khám phá khối. Trước khi viết vào, vui lòng được thông báo rằng",
    "Please use this form to get in touch for priority support or featured listing with our team.": "Vui lòng sử dụng biểu mẫu này để liên hệ để được hỗ trợ ưu tiên hoặc danh sách nổi bật với đội ngũ của chúng tôi.",
    "Position": "Vị trí",
    "Post-London Upgrade, this represents the minimum gas Used multiplier required for a tx to be included in a block.": "Sau nâng cấp London, điều này đại diện cho hệ số gas tối thiểu được sử dụng cần thiết để một giao dịch được bao gồm trong một khối.",
    "Post-London Upgrade, this represents the part of the tx fee that is burnt: baseFeePerGas * gasUsed.": "Sau nâng cấp London, điều này đại diện cho phần phí giao dịch bị đốt: baseFeePerGas * gasUsed.",
    "Power": "Quyền lực",
    "Powered by BlocksScan": `Được cung cấp bởi ${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"}`,
    "BlocksScan is a Block Explorer and Analytics Platform for": `${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"} là một nền tảng khám phá và phân tích khối cho`,
    "Present": "Hiện tại",
    "Preserve": "Bảo tồn",
    "Press": "Báo chí",
    "Price": "Giá",
    "Primary compiled Vyper contract. Only add the main contract here whose bytecode has been deployed, all other files can be uploaded to the interfaces box below": "Hợp đồng Vyper đã biên dịch chính. Chỉ thêm hợp đồng chính tại đây có mã byte đã được triển khai, tất cả các tệp khác có thể được tải lên hộp giao diện bên dưới",
    "Privacy": "Quyền riêng tư",
    "Privacy Policy": "Chính sách bảo mật",
    "Private Name Tags": "Thẻ tên riêng tư",
    "Processing": "Đang xử lý",
    "Products": "Sản phẩm",
    "Profile": "Hồ sơ",
    "Programs": "Chương trình",
    "Project": "Dự án",
    "Properties": "Tính chất",
    "Proposed On": "Đề xuất vào",
    "Proxy": "Proxy",
    "Publish": "Xuất bản",
    "Published": "Đã xuất bản",
    "Quantity": "Số lượng",
    "Rank": "Hạng",
    "Read": "Đọc",
    "Read Contract": "Đọc hợp đồng",
    "Read Proxy": "Đọc Proxy",
    "Real-time": "Thời gian thực",
    "Receipt": "Biên lai",
    "Recent": "Gần đây",
    "Record": "Bản ghi",
    "Recover": "Phục hồi",
    "Recovery": "Khôi phục",
    "Recycle": "Tái chế",
    "Redo": "Làm lại",
    "Refresh": "Làm mới",
    "Register": "Đăng ký",
    "Registration": "Đăng ký",
    "Related": "Liên quan",
    "Release": "Phát hành",
    "Reload": "Tải lại",
    "Removal": "Loại bỏ",
    "Remove": "Gỡ bỏ",
    "Removed": "Đã gỡ bỏ",
    "Removing": "Đang gỡ bỏ",
    "Renew": "Gia hạn",
    "Replace": "Thay thế",
    "Request Removal of Name Tag": "Yêu cầu loại bỏ thẻ tên",
    "Request": "Yêu cầu",
    "Requester Email": "Email người yêu cầu",
    "Requester Name": "Tên người yêu cầu",
    "Requests": "Yêu cầu",
    "Resend": "Gửi lại",
    "Reset": "Đặt lại",
    "Resources": "Tài nguyên",
    "Response": "Phản hồi",
    "Responses": "Các phản hồi",
    "Restore": "Khôi phục",
    "Restrict": "Hạn chế",
    "Result": "Kết quả",
    "Results": "Các kết quả",
    "Resume": "Tiếp tục",
    "Retain": "Giữ lại",
    "Retrieve": "Lấy lại",
    "Return": "Trả lại",
    "Returned": "Đã trả lại",
    "Returning": "Đang trả lại",
    "Review": "Xem xét",
    "Rewards": "Phần thưởng",
    "Rewrite": "Viết lại",
    "Rich": "Giàu",
    "Right": "Phải",
    "Rights": "Quyền",
    "Role": "Vai trò",
    "Roles": "Các vai trò",
    "Rollback": "Hoàn tác",
    "Root": "Gốc",
    "Route": "Đường dẫn",
    "Routing": "Định tuyến",
    "Row": "Hàng",
    "Rules": "Quy tắc",
    "Run": "Chạy",
    "Running": "Đang chạy",
    "Sales": "Doanh số",
    "Save Changes": "Lưu thay đổi",
    "Saved Filters": "Các bộ lọc đã lưu",
    "Saving": "Đang lưu",
    "Scenario": "Kịch bản",
    "Schemas": "Các lược đồ",
    "Schedule": "Lịch trình",
    "Scheduled": "Đã lên lịch",
    "Scheduling": "Đang lên lịch",
    "Schema": "Lược đồ",
    "Scope": "Phạm vi",
    "Screen": "Màn hình",
    "Scripts": "Các kịch bản",
    "Search": "Tìm kiếm",
    "Ask AI | Search by Address | Txn Hash | Block | Token... ": "Tìm kiếm theo địa chỉ / txn hash / khối / mã token... ",
    "Searches": "Các tìm kiếm",
    "Searching": "Đang tìm kiếm",
    "Second": "Thứ hai",
    "Seconds": "Giây",
    "Sec": "Giây",
    "Secret": "Bí mật",
    "Section": "Phần",
    "Secure": "An toàn",
    "Securing": "Đang bảo đảm",
    "Security": "Bảo mật",
    "Security Audit": "Kiểm toán bảo mật",
    "See more details": "Xem thêm chi tiết",
    "See preview of the transaction details.": "Xem trước chi tiết giao dịch.",
    "Select": "Chọn",
    "Selected": "Đã chọn",
    "Selection": "Sự lựa chọn",
    "Send": "Gửi",
    "Send Message": "Gửi tin nhắn",
    "Sender": "Người gửi",
    "Sending": "Đang gửi",
    "Send native": "Gửi bản địa",
    "Sequence": "Chuỗi",
    "Server": "Máy chủ",
    "Service": "Dịch vụ",
    "Services": "Các dịch vụ",
    "Session": "Phiên",
    "Sessions": "Các phiên",
    "Set": "Đặt",
    "Setting": "Đang đặt",
    "Settings": "Cài đặt",
    "Setup": "Thiết lập",
    "Share": "Chia sẻ",
    "Shared": "Đã chia sẻ",
    "Sharing": "Đang chia sẻ",
    "Show": "Hiển thị",
    "Show More Chains": "Hiển thị thêm chuỗi",
    "Show less": "Hiển thị ít hơn",
    "Show more": "Hiển thị thêm",
    "Showing": "Đang hiển thị",
    "Signer": "Người ký",
    "Similar Contract Search": "Tìm kiếm hợp đồng tương tự",
    "Simple steps for getting Priority Support.": "Các bước đơn giản để nhận được hỗ trợ ưu tiên.",
    "Size": "Kích thước",
    "Slow": "Chậm",
    "Slot and epoch this block is proposed on.": "Slot và epoch mà khối này được đề xuất.",
    "Smart Contract": "Hợp đồng thông minh",
    "Smart Contract Search": "Tìm kiếm hợp đồng thông minh",
    "Smart Contracts": "Các hợp đồng thông minh",
    "Snapshot": "Ảnh chụp nhanh",
    "Snapshots": "Các ảnh chụp nhanh",
    "Social": "Xã hội",
    "Software": "Phần mềm",
    "Solidity": "Solidity",
    "Solution": "Giải pháp",
    "Solutions": "Các giải pháp",
    "Source Code": "Mã nguồn",
    "Sources *.sol or *.yul files": "Nguồn *.sol hoặc *.yul",
    "Sources and Metadata JSON": "Nguồn và siêu dữ liệu JSON",
    "Span": "Khoảng",
    "Special": "Đặc biệt",
    "Specific": "Cụ thể",
    "Specification": "Đặc tả",
    "Specifications": "Các đặc tả",
    "Specified": "Đã chỉ định",
    "Specify": "Chỉ định",
    "Specs": "Thông số kỹ thuật",
    "Sponsor": "Nhà tài trợ",
    "Sponsored": "Được tài trợ",
    "Sponsoring": "Tài trợ",
    "Sponsors": "Các nhà tài trợ",
    "Spotlight": "Điểm nổi bật",
    "Stable": "Ổn định",
    "Staging": "Dàn dựng",
    "Standard JSON Input": "Đầu vào JSON chuẩn",
    "Start": "Bắt đầu",
    "Starting": "Đang bắt đầu",
    "State": "Trạng thái",
    "State Diffrence": "Khác biệt trạng thái",
    "State Root": "Gốc trạng thái",
    "StateRoot": "Gốc trạng thái",
    "State of the art coffee machine and experienced baristas in the house for the coffee lovers to thrive.": "Máy pha cà phê hiện đại và các barista có kinh nghiệm trong nhà dành cho những người yêu cà phê phát triển.",
    "Statistics": "Thống kê",
    "Status": "Trạng thái",
    "Step 1": "Bước 1",
    "Step 2": "Bước 2",
    "Step 3": "Bước 3",
    "Steps": "Các bước",
    "Storage Of Assets": "Lưu trữ tài sản",
    "Store": "Lưu trữ",
    "Stored": "Đã lưu trữ",
    "Storing": "Đang lưu trữ",
    "Stream": "Luồng",
    "Streaming": "Đang phát",
    "Streams": "Các luồng",
    "Strength": "Sức mạnh",
    "String": "Chuỗi",
    "Strings": "Các chuỗi",
    "Structure": "Cấu trúc",
    "Structures": "Các cấu trúc",
    "Style": "Phong cách",
    "Styles": "Các phong cách",
    "Submit": "Gửi",
    "Submitted": "Đã gửi",
    "Submitting": "Đang gửi",
    "Subscription": "Đăng ký",
    "Subscriptions": "Các đăng ký",
    "Subsequent": "Tiếp theo",
    "Success": "Thành công",
    "Successful": "Thành công",
    "Successfully": "Thành công",
    "Suggest Transaction Action": "Đề xuất hành động giao dịch",
    "Suggestions": "Các gợi ý",
    "Summary": "Tóm tắt",
    "Support": "Hỗ trợ",
    "Supported": "Đã hỗ trợ",
    "Supporting": "Đang hỗ trợ",
    "Supports": "Hỗ trợ",
    "Supply": "Nguồn cung",
    "Surface": "Bề mặt",
    "Survey": "Khảo sát",
    "Surveys": "Các khảo sát",
    "Suspend": "Đình chỉ",
    "Suspended": "Đã đình chỉ",
    "Suspending": "Đang đình chỉ",
    "Swap": "Hoán đổi",
    "Swapping": "Đang hoán đổi",
    "Switch": "Chuyển đổi",
    "Switching": "Đang chuyển đổi",
    "Symbol": "Biểu tượng",
    "Symbols": "Các biểu tượng",
    "Sync": "Đồng bộ",
    "Synchronize": "Đồng bộ hóa",
    "Synchronized": "Đã đồng bộ hóa",
    "Synchronizing": "Đang đồng bộ hóa",
    "System": "Hệ thống",
    "Systems": "Các hệ thống",
    "TV": "TV",
    "Table": "Bảng",
    "Tables": "Các bảng",
    "Tag": "Thẻ",
    "Tags": "Các thẻ",
    "Take": "Lấy",
    "Taken": "Đã lấy",
    "Taking": "Đang lấy",
    "Target": "Mục tiêu",
    "Targets": "Các mục tiêu",
    "Task": "Nhiệm vụ",
    "Tasks": "Các nhiệm vụ",
    "Team": "Nhóm",
    "Teams": "Các nhóm",
    "Technical": "Kỹ thuật",
    "Technique": "Kỹ thuật",
    "Techniques": "Các kỹ thuật",
    "Technology": "Công nghệ",
    "Template": "Mẫu",
    "Templates": "Các mẫu",
    "Temporary": "Tạm thời",
    "Terminate": "Chấm dứt",
    "Terminated": "Đã chấm dứt",
    "Terminating": "Đang chấm dứt",
    "Terms": "Điều khoản",
    "Test": "Kiểm tra",
    "Tested": "Đã kiểm tra",
    "Testing": "Đang kiểm tra",
    "Tests": "Các kiểm tra",
    "Text": "Văn bản",
    "Theme": "Chủ đề",
    "Themes": "Các chủ đề",
    "Therapy": "Liệu pháp",
    "This block is finalized.": "Khối này đã hoàn tất.",
    "This block is finilized.": "Khối này đã hoàn tất.",
    "This page allows you to customize your browsing experience on Blocksscan.": "Trang này cho phép bạn tùy chỉnh trải nghiệm duyệt web của mình trên Blocksscan.",
    "This NFT's unique token ID.": "ID token độc nhất của NFT này.",
    "Thread": "Chuỗi",
    "Threads": "Các chuỗi",
    "Threshold": "Ngưỡng",
    "Thresholds": "Các ngưỡng",
    "Ticket": "Vé",
    "Tickets": "Các vé",
    "Time": "Thời gian",
    "Timestamp": "Dấu thời gian",
    "Title": "Tiêu đề",
    "Titles": "Các tiêu đề",
    "To": "Đến",
    "Token Contract": "Hợp đồng mã token",
    "Token Contract Address": "Địa chỉ hợp đồng mã token",
    "Token Creation": "Tạo mã token",
    "Token Holding": "Giữ mã token",
    "Token ID": "ID mã token",
    "Token Inventory": "Hàng tồn kho mã token",
    "Token Name": "Tên mã token",
    "Token Standard": "Tiêu chuẩn mã token",
    "Token Transactions": "Các giao dịch mã token",
    "Token Transfers": "Các chuyển mã token",
    "Token Update": "Cập nhật mã token",
    "Token creation": "Tạo mã token",
    "Token transfer": "Chuyển mã token",
    "Tokens": "Các mã token",
    "Tokens Transferred": "Các mã token đã chuyển",
    "Tokens burnt": "Các mã token bị đốt",
    "Tokens minted": "Các mã token đã đúc",
    "Tokens transferred in the transaction.": "Các mã token đã chuyển trong giao dịch.",
    "ToDo": "Công việc cần làm",
    "To clipboard": "Vào clipboard",
    "Toggle": "Chuyển đổi",
    "Toggled": "Đã chuyển đổi",
    "Toggling": "Đang chuyển đổi",
    "Tool": "Công cụ",
    "Tools": "Các công cụ",
    "Top Accounts": "Các tài khoản hàng đầu",
    "Top Contract": "Hợp đồng hàng đầu",
    "Top Contracts": "Các hợp đồng hàng đầu",
    "Top Mints": "Các sản phẩm đúc hàng đầu",
    "Top NFT": "NFT hàng đầu",
    "Top NFTs": "Các NFT hàng đầu",
    "Top Statistics": "Thống kê hàng đầu",
    "Top Token": "Mã token hàng đầu",
    "Top Tokens": "Các mã token hàng đầu",
    "Topic": "Chủ đề",
    "Topics": "Các chủ đề",
    "Total": "Tổng",
    "Total Accounts": "Tổng tài khoản",
    "Total Assets": "Tổng tài sản",
    "Total Difficulty": "Độ khó tổng thể của chuỗi cho đến khối này.",
    "Total Holders": "Tổng số người nắm giữ",
    "Total Transfers": "Tổng số chuyển khoản",
    "Total amount of ETH burnt from this tx & total fees saved from the amount the user was willing to pay for this tx.": "Tổng số ETH bị đốt từ giao dịch này & tổng phí tiết kiệm từ số tiền mà người dùng sẵn sàng trả cho giao dịch này.",
    "Total gas limit provided by all transactions in the block.": "Giới hạn gas tổng cộng được cung cấp bởi tất cả các giao dịch trong khối.",
    "Total gas used in the block and its percentage of gas filled in the block.": "Tổng gas đã sử dụng trong khối và phần trăm gas đã được lấp đầy trong khối.",
    "Total transactions": "Tổng số giao dịch",
    "Trace": "Dấu vết",
    "Traces": "Các dấu vết",
    "Tracking": "Đang theo dõi",
    "Trade": "Giao dịch",
    "Trades": "Các giao dịch",
    "Trading": "Giao dịch",
    "Transaction": "Giao dịch",
    "Transaction Action": "Hành động giao dịch",
    "Transaction Details": "Chi tiết giao dịch",
    "Transaction Fee": "Phí giao dịch",
    "Transaction Hash": "Hash giao dịch",
    "Transaction Type": "Loại giao dịch",
    "Transactions": "Các giao dịch",
    "Transactions Internal": "Các giao dịch nội bộ",
    "Transcript": "Bản ghi",
    "Transcripts": "Các bản ghi",
    "Transfer": "Chuyển khoản",
    "Transferred": "Đã chuyển",
    "Transferring": "Đang chuyển",
    "Transfers": "Các chuyển khoản",
    "Translate": "Dịch",
    "Translated": "Đã dịch",
    "Translating": "Đang dịch",
    "Translation": "Bản dịch",
    "Translations": "Các bản dịch",
    "Trending": "Xu hướng",
    "Trends": "Các xu hướng",
    "Trigger": "Kích hoạt",
    "Triggered": "Đã kích hoạt",
    "Triggering": "Đang kích hoạt",
    "Triggers": "Các kích hoạt",
    "Troubleshoot": "Khắc phục sự cố",
    "Troubleshooting": "Đang khắc phục sự cố",
    "Trust": "Tin cậy",
    "Trusted": "Được tin cậy",
    "Trusting": "Đang tin cậy",
    "Try": "Thử",
    "Trying": "Đang thử",
    "Tx Fee": "Phí giao dịch",
    "Tx Hash": "Hash giao dịch",
    "Tx Hash|Block|Age": "Hash giao dịch|Khối|Thời gian",
    "Tx row trace to clipboard": "Dấu vết dòng giao dịch vào clipboard",
    "Tx": "Giao dịch",
    "Txn": "Giao dịch",
    "Txn Savings": "Tiết kiệm giao dịch",
    "Txn Type": "Loại giao dịch",
    "Txns": "Giao dịch",
    "Type": "Loại",
    "Type here ...": "Nhập ở đây ...",
    "Type of Backing": "Loại hỗ trợ",
    "Type of Peg": "Loại chốt",
    "Types": "Các loại",
    "UI": "Giao diện",
    "URL": "Đường dẫn URL",
    "URLs": "Các đường dẫn URL",
    "Undo": "Hoàn tác",
    "Undone": "Đã hoàn tác",
    "Unfinished": "Chưa hoàn thành",
    "Unit": "Đơn vị",
    "Units": "Các đơn vị",
    "Universe": "Vũ trụ",
    "Unknown": "Không xác định",
    "Unlink": "Hủy liên kết",
    "Unlinked": "Đã hủy liên kết",
    "Unlinking": "Đang hủy liên kết",
    "Unlock": "Mở khóa",
    "Unlocked": "Đã mở khóa",
    "Unlocking": "Đang mở khóa",
    "Unread": "Chưa đọc",
    "Unresolved": "Chưa được giải quyết",
    "Unseen": "Chưa thấy",
    "Unsubscribe": "Hủy đăng ký",
    "Unsubscribed": "Đã hủy đăng ký",
    "Unsubscribing": "Đang hủy đăng ký",
    "Untagged": "Không có thẻ",
    "Untagging": "Đang gỡ thẻ",
    "Update": "Cập nhật",
    "Update Proxy Contract's Implementation Address": "Cập nhật địa chỉ triển khai của hợp đồng Proxy",
    "Update Token Info": "Cập nhật thông tin mã token",
    "Updated": "Đã cập nhật",
    "Updating": "Đang cập nhật",
    "Upgrade": "Nâng cấp",
    "Upgraded": "Đã nâng cấp",
    "Upgrading": "Đang nâng cấp",
    "Upload": "Tải lên",
    "Upload all Solidity contract source files and JSON metadata file(s) created during contract compilation.": "Tải lên tất cả các tệp nguồn hợp đồng Solidity và các tệp siêu dữ liệu JSON được tạo trong quá trình biên dịch hợp đồng.",
    "Upload all Solidity or Yul contract source files": "Tải lên tất cả các tệp nguồn hợp đồng Solidity hoặc Yul",
    "Upload the standard input JSON file created during contract compilation": "Tải lên tệp JSON đầu vào tiêu chuẩn được tạo trong quá trình biên dịch hợp đồng",
    "Uploaded": "Đã tải lên",
    "Uploading": "Đang tải lên",
    "Uptime": "Thời gian hoạt động",
    "Us": "Chúng tôi",
    "Usage": "Sử dụng",
    "Use": "Sử dụng",
    "Used": "Đã sử dụng",
    "User": "Người dùng",
    "Users": "Các người dùng",
    "Using": "Đang sử dụng",
    "Utilities": "Tiện ích",
    "Utility": "Tiện ích",
    "Validate": "Xác thực",
    "Validated": "Đã xác thực",
    "Validating": "Đang xác thực",
    "Validation": "Xác thực",
    "Value": "Giá trị",
    "Values": "Các giá trị",
    "Variable": "Biến",
    "Variables": "Các biến",
    "Variant": "Biến thể",
    "Variants": "Các biến thể",
    "Varying": "Đa dạng",
    "Vault": "Kho",
    "Vaults": "Các kho",
    "Vector": "Vector",
    "Vectors": "Các vector",
    "Verification": "Xác minh",
    "Verification Method": "Phương pháp xác minh",
    "Verified": "Đã xác minh",
    "Verified At": "Đã xác minh vào lúc",
    "Verified Contracts": "Các hợp đồng đã xác minh",
    "Verified Smart Contract": "Hợp đồng thông minh đã xác minh",
    "Verifying": "Đang xác minh",
    "Version": "Phiên bản",
    "Versions": "Các phiên bản",
    "Verify & Publish": "Xác minh & Xuất bản",
    "Verify And Publish": "Xác minh và Xuất bản",
    "Verify Contract": "Xác minh hợp đồng",
    "View": "Xem",
    "View Blocks": "Xem các khối",
    "Views": "Các lượt xem",
    "Viewing": "Đang xem",
    "Visible": "Có thể nhìn thấy",
    "Visit": "Thăm",
    "Visiting": "Đang thăm",
    "Visitor": "Khách thăm",
    "Visitors": "Các khách thăm",
    "Visual": "Hình ảnh",
    "Visuals": "Các hình ảnh",
    "Volume": "Khối lượng",
    "Volume 24h": "Khối lượng 24 giờ",
    "Volumes": "Các khối lượng",
    "Vote": "Bầu chọn",
    "Voted": "Đã bầu chọn",
    "Voter": "Người bầu chọn",
    "Voters": "Các người bầu chọn",
    "Voting": "Đang bầu chọn",
    "Votes": "Các bầu chọn",
    "Vyper Online Compiler": "Trình biên dịch Vyper trực tuyến",
    "WAITING": "ĐANG CHỜ",
    "Wallet": "Ví",
    "Wallet / Exchange / Project related issues": "Các vấn đề liên quan đến Ví / Sàn giao dịch / Dự án",
    "Wallets": "Các ví",
    "Warning": "Cảnh báo",
    "Warnings": "Các cảnh báo",
    "Watch": "Xem",
    "Watching": "Đang xem",
    "Web": "Web",
    "Webpage": "Trang web",
    "Webpages": "Các trang web",
    "Website": "Trang web",
    "Websites": "Các trang web",
    "Welcome": "Chào mừng",
    "Welcome to Blocksscan Explorer Services": "Chào mừng đến với dịch vụ khám phá Blocksscan",
    "Wellness": "Sức khỏe",
    "We're Hiring": "Chúng tôi đang tuyển dụng",
    "What are the functions or methods available in this contract?": "Các chức năng hoặc phương pháp có sẵn trong hợp đồng này là gì?",
    "What does this contract do?": "Hợp đồng này làm gì?",
    "What is the logical structure of this contract's code?": "Cấu trúc logic của mã hợp đồng này là gì?",
    "Where to find my API Key?": "Tìm khóa API của tôi ở đâu?",
    "While we try our best to respond in a timely manner, we often have a backlog of inquiries. Please do not": "Mặc dù chúng tôi cố gắng hết sức để phản hồi kịp thời, chúng tôi thường có một lượng lớn yêu cầu. Vui lòng không",
    "White": "Trắng",
    "Who": "Ai",
    "Whole": "Toàn bộ",
    "Wholesale": "Bán buôn",
    "Widget": "Tiện ích",
    "Widgets": "Các tiện ích",
    "Width": "Chiều rộng",
    "Widths": "Các chiều rộng",
    "Wiki": "Wiki",
    "Wikis": "Các Wiki",
    "Willing": "Sẵn lòng",
    "Win": "Chiến thắng",
    "Window": "Cửa sổ",
    "Windows": "Các cửa sổ",
    "Winning": "Đang chiến thắng",
    "Wins": "Các chiến thắng",
    "Wire": "Dây",
    "Wires": "Các dây",
    "Wiring": "Đấu dây",
    "Wisdom": "Trí tuệ",
    "Wish": "Ước muốn",
    "Wished": "Đã ước muốn",
    "Wishes": "Các ước muốn",
    "Wishing": "Đang ước muốn",
    "Withdraw": "Rút",
    "Withdrawal": "Rút tiền",
    "Withdrawals": "Các khoản rút tiền",
    "WithdrawalsRoot": "Gốc rút tiền",
    "Withdrawing": "Đang rút tiền",
    "Withdrawn": "Đã rút",
    "With": "Với",
    "Within": "Trong vòng",
    "Without": "Không có",
    "Witness": "Nhân chứng",
    "Witnessed": "Đã chứng kiến",
    "Witnesses": "Các nhân chứng",
    "Witnessing": "Đang chứng kiến",
    "Work": "Công việc",
    "Worked": "Đã làm việc",
    "Working": "Đang làm việc",
    "Works": "Các công việc",
    "Workspace": "Không gian làm việc",
    "Workspaces": "Các không gian làm việc",
    "World": "Thế giới",
    "Worldwide": "Toàn cầu",
    "Worry": "Lo lắng",
    "Worried": "Đã lo lắng",
    "Worries": "Các lo lắng",
    "Worrying": "Đang lo lắng",
    "Worth": "Giá trị",
    "Write": "Viết",
    "Write Contract": "Viết hợp đồng",
    "Write Proxy": "Viết Proxy",
    "Write me a smart contract of voting.": "Viết cho tôi một hợp đồng thông minh về bầu cử.",
    "Writes": "Các ghi chép",
    "Writing": "Đang viết",
    "Written": "Đã viết",
    "Wrong": "Sai",
    "Wrote": "Đã viết",
    "X (Twitter)": "X (Twitter)",
    "XML": "XML",
    "XRay": "X-Ray",
    "Xrays": "Các X-Ray",
    "Year": "Năm",
    "Years": "Các năm",
    "Yellow": "Vàng",
    "Yes": "Có",
    "Yesterday": "Hôm qua",
    "Yield": "Lợi tức",
    "Yielding": "Đang mang lại",
    "Yields": "Các lợi tức",
    "You can Lookup the Account": "Bạn có thể tra cứu tài khoản",
    "Your browser does not support the video tag.": "Trình duyệt của bạn không hỗ trợ thẻ video.",
    "Your private keys with anyone.": "Các khóa riêng tư của bạn với bất kỳ ai.",
    "Your Rights": "Quyền của bạn",
    "Youth": "Thanh niên",
    "Zone": "Khu vực",
    "Zones": "Các khu vực",
    "Zoning": "Phân khu",
    "Zoom": "Thu phóng",
    "Zoomed": "Đã thu phóng",
    "Zooming": "Đang thu phóng",
    "Zooms": "Các lần thu phóng",
    "rsync-builder in 12 secs": "rsync-builder trong 12 giây",
    "rsyncbuilder": "rsync-builder",
    "sec": "giây",
    "tokens with a value of": "các mã token với giá trị",
    "to clipboard": "vào clipboard",
    "tokens transferred in the transaction.": "các mã token đã chuyển trong giao dịch.",
    "withdrawals in this block": "các khoản rút tiền trong khối này",
    "we": "chúng tôi",
    "us": "chúng tôi",
    "our": "của chúng tôi"
  }
}
