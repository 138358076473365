import React, {FC} from 'react';
import BSkeleton from "./index";

interface BSkeletonTableProps {
    length: number;
    col: number;
}

const BSkeletonTable: FC<BSkeletonTableProps> = ({length, col}) => {


    return (
        <React.Fragment>
            {
                Array.from(Array(length ? length : 10).keys()).map(a_number => {
                    return (
                        <div className="bs-table-row" key={a_number}>
                            {
                                Array.from(Array(col ? col : 1).keys()).map(number => {
                                    return (
                                        <div className="bs-table-cell" key={number}>
                                            <BSkeleton height="18px" width=""/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </React.Fragment>
    );
};

export default BSkeletonTable;
