import React from "react";
import {ConnectButton} from "@rainbow-me/rainbowkit";
import BAvatar from "../../../../components/b-avatar";
import useWallet from '../../../../common/hooks/useWallet';
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

interface WalletButtonData {
    className?: string
}

const WalletButton: React.FC<WalletButtonData> = (props) => {
    const {isWalletConnected, addressHash, connect, disconnect} = useWallet();
    const _account = useSelector((e: Object) => (e as any)?.common?.account);

    const signOut = () => {
        disconnect()
    }

    return (
        <ConnectButton.Custom>
            {({
                  account,
                  openAccountModal,
                  openConnectModal,
                  mounted,
              }) => {
                const connected = mounted && account;

                return (
                    <React.Fragment>
                        <div className="d-flex align-items-center">
                            <button
                                type="button"
                                className={`btn btn-sm ${props?.className}`}
                                onClick={async () => {
                                    if (!connected) openConnectModal();
                                    else if (_account?.signature) openAccountModal();
                                }}
                                disabled={!mounted}
                            >
                                {connected ? (
                                    <div className="d-flex align-items-center gap-2">
                                        {account.ensAvatar ? (
                                            <img src={account.ensAvatar} alt={account.displayName} style={{width: 24, height: 24, borderRadius: '50%', marginRight: 8}}/>
                                        ) : (
                                            <BAvatar address={account.address} diameter={20}/>
                                        )}
                                        <span>{account.displayName}</span>
                                    </div>
                                ) : (<React.Fragment><i className="bi bi-person-circle" style={{padding: '5px'}}></i>Sign In</React.Fragment>)}
                            </button>
                        </div>

                        {isWalletConnected && (
                            <ul className="dropdown-menu">
                                <li>
                                    <Link className="dropdown-item" to="/myaccount">My Profile&nbsp;</Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider"/>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/myaddress">Watch List&nbsp;</Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/mynotes_tx">Txn Private Notes&nbsp;</Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/mynotes_address">Private Name Tags&nbsp;</Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/mytokenignore">Token Ignore List&nbsp;</Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider"/>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/myapikey">API Keys&nbsp;</Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/myverify_address">Verified Address&nbsp;</Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/mycustomabi">Custom ABI&nbsp;</Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/myadvancedfilters">Advanced Filter&nbsp;</Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider"/>
                                </li>
                                <li>
                                    <a className="dropdown-item" onClick={signOut} href="javaScript:void(0)">Sign Out</a>
                                </li>
                            </ul>
                        )}
                    </React.Fragment>
                );
            }}
        </ConnectButton.Custom>
    );
};

export default WalletButton;