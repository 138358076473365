export const kr = {
  translations: {
    welcome: "한국어",
    Settings: "설정",
    "This page allows you to customize your browsing experience on Blocksscan.":
      "이 페이지에서는 블록스캔에서 브라우징 경험을 사용자 정의할 수 있습니다.",
    Address: "주소",
    Overview: "개요",
    Balance: "잔액",
    Value: "가치",
    "More Info": "더 많은 정보",
    "Private Name Tags": "개인 이름 태그",
    Add: "추가",
    "LAST TXN SENT": "마지막 TXN 전송",
    from: "에서",
    "FIRST TXN SENT": "첫 번째 TXN 전송",
    "Multichain Info": "멀티체인 정보",
    day: "일",
    days: "일",
    hour: "시간",
    hours: "시간",
    minute: "분",
    minutes: "분",
    second: "초",
    seconds: "초",
    ago: "전",
    "Multichain Portfolio": "멀티체인 포트폴리오",
    Transactions: "트랜잭션",
    "Internal Transactions": "내부 트랜잭션",
    Transfers: "전송",
    "NFT Transfers": "NFT 전송",
    "Token Holding": "토큰 보유",
    "Token Inventory": "토큰 인벤토리",
    Events: "이벤트",
    Contract: "계약",
    Rewards: "보상",
    Analytics: "분석",
    Download: "다운로드",
    "CSV Export": "CSV 내보내기",
    Tokens: "토큰",
    Chains: "체인",
    "Hide Chains": "체인 숨기기",
    "Show More Chains": "더 많은 체인 표시",
    "Hide All Chains": "모든 체인 숨기기",
    "Display All Chains": "모든 체인 표시",
    "Name Tag": "이름 태그",
    Percentage: "백분율",
    Capacity: "용량",
    Owner: "소유자",
    "Latest Signed Block": "최신 서명된 블록",
    Status: "상태",
    "Contract Name": "계약 이름",
    Compiler: "컴파일러",
    Version: "버전",
    Txns: "트랜잭션",
    Verified: "확인됨",
    Audit: "감사",
    License: "라이선스",
    "New Smart Contracts": "새로운 스마트 계약",
    "New Verified Contracts": "새로운 확인된 계약",
    "Smart Contracts": "스마트 계약",
    "Verified Smart Contract": "확인된 스마트 계약",
    Optimization: "최적화",
    "Constructor args": "생성자 인수",
    "Verified Contracts": "확인된 계약",
    Solidity: "솔리디티",
    Accounts: "계정",
    "Block Height": "블록 높이",
    ToDo: "할 일",
    Finalized: "확정됨",
    Timestamp: "타임스탬프",
    "Proposed On": "제안 날짜",
    "Block proposed on epoch": "에포크에서 제안된 블록",
    Withdrawals: "출금",
    "withdrawals in this block": "이 블록의 출금",
    Fee: "수수료",
    "Fee Recipient": "수수료 수취인",
    "rsync-builder in 12 secs": "12초 동안 rsync-빌더",
    "Block Reward": "블록 보상",
    "Total Difficulty": "총 난이도",
    Size: "크기",
    bytes: "바이트",
    "Gas Used": "사용된 가스",
    "Gas Target": "가스 대상",
    "Gas Limit": "가스 한도",
    "Base Fee Per Gas": "가스 당 기본 수수료",
    "Burnt Fees": "소비된 수수료",
    "Extra Data": "추가 데이터",
    rsyncbuilder: "rsync 빌더",
    Hash: "해시",
    "Parent Hash": "부모 해시",
    StateRoot: "상태 루트",
    WithdrawalsRoot: "출금 루트",
    Nonce: "논스",
    "More Details": "자세히 보기",
    "Click to show less": "더 적게 보기",
    "Click to show more": "더 보기",
    "Also known as Block Number. The block height, which indicates the length of the blockchain, increases after the addition of the new block.":
      "블록 번호로도 알려집니다. 블록체인의 길이를 나타내는 블록 높이는 새로운 블록 추가 후에 증가합니다.",
    "The finality status of the block.": "블록의 최종 상태입니다.",
    "This block is finilized.": "이 블록은 완료되었습니다.",
    "The date and time at which a block is produced.":
      "블록이 생성된 날짜와 시간입니다.",
    "Slot and epoch this block is proposed on.":
      "이 블록이 제안된 슬롯 및 에포크입니다.",
    "The number of transactions in the block. Internal transaction is transactions as a result of contract execution that involves Ether value.":
      "블록 내의 트랜잭션 수입니다. 내부 트랜잭션은 이더 가치를 포함하는 계약 실행의 결과인 트랜잭션입니다.",
    "Number of beacon withdrawals in this block.":
      "이 블록의 비컨 출금 수입니다.",
    "Address receiving fees from transactions in this block.":
      "이 블록의 트랜잭션에서 수수료를 받는 주소입니다.",
    "For each block, the block producer is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block.":
      "각 블록마다 블록 프로듀서는 블록 내 모든 트랜잭션에 대한 수수료 외에도 유한량의 이더를 보상받습니다.",
    "Total difficulty of the chain until this block.":
      "이 블록까지의 전체 체인 난이도입니다.",
    "The block size is actually determined by the block's gas limit.":
      "블록 크기는 실제로 블록의 가스 제한에 의해 결정됩니다.",
    "The total gas used in the block and its percentage of gas filled in the block.":
      "블록에서 사용된 총 가스 및 블록에 충전된 가스의 백분율입니다.",
    "Total gas limit provided by all transactions in the block.":
      "블록 내 모든 트랜잭션에서 제공된 총 가스 한도입니다.",
    "Post-London Upgrade, this represents the minimum gas Used multiplier required for a tx to be included in a block.":
      "런던 업그레이드 이후, 이는 블록에 포함되기 위해 필요한 최소 가스 사용 배수를 나타냅니다.",
    "Post-London Upgrade, this represents the part of the tx fee that is burnt: baseFeePerGas * gasUsed.":
      "런던 업그레이드 이후, 이는 소비되는 TX 수수료의 일부를 나타냅니다: 기본 가스당 수수료 * 사용된 가스.",
    "Any data that can be included by the block producer in the block.":
      "블록 프로듀서가 블록에 포함할 수 있는 모든 데이터입니다.",
    "The hash of the block header of the current block.":
      "현재 블록의 블록 헤더 해시입니다.",
    "The hash of the block from which this block was generated, also known as its parent block.":
      "이 블록이 생성된 블록의 해시, 즉 부모 블록입니다.",
    "The root of the state trie.": "상태 트리의 루트입니다.",
    "Block header withdrawal root hash.": "블록 헤더 인출 루트 해시입니다.",
    "Block nonce is a value used during mining to demonstrate proof of work for a block.":
      "블록 논스는 채굴 중에 블록의 작업 증명을 보여주는 값입니다.",
    Block: "블록",
    Blocks: "블록",
    Age: "나이",
    Node: "노드",
    "Base Fee": "기본 수수료",
    "See preview of the transaction details.":
      "트랜잭션 세부 정보 미리보기를 확인하세요.",
    "Additional Info": "추가 정보",
    "Transaction Action": "트랜잭션 액션",
    Transfer: "전송",
    To: "에",
    "Transaction Fee": "트랜잭션 수수료",
    "Gas Info": "가스 정보",
    "See more details": "자세히 보기",
    "Tx Hash": "Tx 해시",
    Type: "유형",
    From: "에서",
    "Tx Fee": "Tx 수수료",
    "in the position": "위치에",
    "A TxHash or transaction hash is a unique 66-character identifier that is generated whenever a transaction is executed.":
      "TxHash 또는 트랜잭션 해시는 트랜잭션이 실행될 때마다 생성되는 고유한 66자 식별자입니다.",
    "Transaction Hash": "트랜잭션 해시",
    "TxHash to clipboard": "TxHash를 클립보드에 복사",
    "The status of the transaction.": "트랜잭션의 상태입니다.",
    "Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was produced.":
      "트랜잭션이 기록된 블록 번호입니다. 블록 확인은 트랜잭션이 생성된 후 추가된 블록 수를 나타냅니다.",
    Confirmation: "확인",
    "Pending...": "보류 중...",
    "The date and time at which a transaction is produced.":
      "트랜잭션이 생성된 날짜와 시간입니다.",
    "Confirmed within": "내에서 확인됨",
    sec: "초",
    "Highlighted events of the transaction.":
      "트랜잭션의 하이라이트된 이벤트입니다.",
    "The sending party of the transaction.": "트랜잭션의 송신 당사자입니다.",
    "The receiving party of the transaction (could be a contract address).":
      "트랜잭션의 수신 당사자 (계약 주소일 수 있음)입니다.",
    created: "생성된",
    "List of {{_tokenType}}20 tokens transferred in the transaction.":
      "트랜잭션에서 전송된 {{_tokenType}}20 토큰의 목록입니다.",
    "Tokens Transferred": "전송된 토큰",
    For: "대상",
    " tokens transferred in the transaction.":
      "트랜잭션에서 전송된 토큰입니다.",
    "List of": "의 목록",
    "Token ID": "토큰 ID",
    "List of tokens mint transferred in the transaction.":
      "트랜잭션에서 전송된 토큰의 목록입니다.",
    "Tokens minted": "생성된 토큰",
    "List of tokens burnt transferred in the transaction.":
      "트랜잭션에서 전송된 소멸된 토큰의 목록입니다.",
    "Tokens burnt": "소멸된 토큰",
    "The value being transacted in Ether and fiat value. Note: You can click the fiat value (if available) to see historical value at the time of transaction.":
      "이더 및 법정 통화 가치로 처리되는 값입니다. 참고: 트랜잭션 시점의 과거 값을 확인하려면 법정 통화 값을 클릭할 수 있습니다.",
    "Amount paid to process the transaction in Ether and fiat value.":
      "트랜잭션을 처리하기 위해 이더리움 및 법정 통화 가치로 지불된 금액입니다.",
    "Actual Tx Cost/Fee": "실제 Tx 비용/수수료",
    "Cost per unit of gas spent for the transaction, in Ether and Gwei.":
      "트랜잭션에 사용된 가스 1 단위당 비용입니다. 단위는 이더리움 및 Gwei입니다.",
    "Gas Price": "가스 가격",
    "Maximum amount of gas allocated for the transaction & the amount eventually used. Normal ETH transfers involve 21,000 gas units while contracts involve higher values.":
      "트랜잭션에 할당된 최대 가스량 및 실제 사용된 양입니다. 일반적인 ETH 전송에는 21,000 가스 단위가 포함되지만, 계약에는 더 높은 값이 포함됩니다.",
    "Gas Limit & Usage by Txn": "트랜잭션별 가스 한도 및 사용량",
    "Base Fee refers to the network Base Fee at the time of the block, while Max Fee & Max Priority Fee refer to the max amount a user is willing to pay for their tx & to give to the block producer respectively.":
      "기본 수수료는 블록 시점의 네트워크 기본 수수료를 나타내며, 최대 수수료 및 최대 우선 수수료는 사용자가 자신의 트랜잭션에 대해 지불하려는 최대 금액 및 블록 프로듀서에게 제공하려는 최대 금액을 나타냅니다.",
    "Gas Fees": "가스 수수료",
    Max: "最大",
    "Max Priority": "最大優先度",
    Base: "ベース",
    "Total amount of ETH burnt from this tx & total fees saved from the amount the user was willing to pay for this tx.":
      "このトランザクションで消費されたETHの合計額と、ユーザーがこのトランザクションのために支払うことを望んでいた金額から節約された手数料の合計額。",
    "Burnt & Txn Savings Fees": "バーン＆トランザクションセービング手数料",
    Burnt: "焼けた",
    "Txn Savings": "トランザクションの節約",
    "Other data related to this transaction.":
      "この取引に関連するその他のデータ。",
    "Other Attributes": "その他の属性",
    "Txn Type": "取引タイプ",
    Position: "位置",
    Legacy: "遺産",
    "Input Data": "入力データ",
    Hex: "ヘックス",
    "to clipboard": "クリップボードに",
    "Decode input data": "入力データをデコードする",
    "Method id": "メソッドID",
    Call: "コール",
    Name: "名称",
    Data: "データ",
    "Logdata to clipboard": "ログデータをクリップボードへ",
    "Address to clipboard": "クリップボードへのアドレス",
    Indexed: "インデックス付き",
    Topics: "トピック",
    Text: "テキスト",
    Number: "番号",
    TO: "へ",
    number: "番号",
    "Tx row trace to clipboard":
      "トランザクション行のトレースをクリップボードへ",
    "No trace entries found.": "トレースエントリーは見つからなかった。",
    Before: "前",
    After: "その後",
    "State Diffrence": "州の違い",
    Token: "トークン",
    "Transaction Details": "取引内容",
    "Token transfers": "トークン・トランスファー",
    Logs: "過去ログ",
    state: "状態",
    "Raw trace": "生のトレース",
    Method: "方法",
    "Careers at BlocksScan": "BlocksScan でのキャリア",
    "Join BlocksScan and enhance the world's leading blockchain explorer and search, API &amp; analytics platform.":
      "BlocksScanに参加し、世界をリードするブロックチェーンエクスプローラー、検索、API、分析プラットフォームを強化しましょう。",
    "Perks and Benefits": "特典とメリット",
    "Ours isn’t just a grand vision. We back it up with the company and culture tailored for realizing it.":
      "私たちのビジョンは単なる壮大なビジョンではありません。それを実現するための企業と文化でバックアップします。",
    "Free Flow Drinks &amp; Snacks": "フリーフロードリンク＆ドリンクおやつ",
    "Never worry about a lack of refreshments or tasty morsels to munch on while adding value to the team.":
      "チームに価値をもたらしながら、軽食やおいしい食べ物が不足することを心配する必要はありません。",
    "Best Coffee in Town": "町で最高のコーヒー",
    "State-of-the-art coffee machine and experienced baristas in the house for the coffee lovers to thrive.":
      "最先端のコーヒーマシンと経験豊富なバリスタがコーヒー愛好家をサポートします。",
    "Annual Trips": "年間旅行",
    "Enjoy a break from the daily grind with sponsored trips to cool new locations and experiences.":
      "スポンサー付きのクールな新しい場所や体験への旅行で、日々の忙しさから離れて休暇をお楽しみください。",
    "Office Entertainment": "オフィスエンターテインメント",
    "Games and gadgets to distract yourself with while thinking up novel solutions to hard problems.":
      "難しい問題に対する斬新な解決策を考えながら気を紛らわせるゲームやガジェット。",
    "Food Allowance": "食費手当",
    "Extra lunch money provided along with the occasional team lunch and dinners.":
      "臨時のチームランチとディナーに加えて、追加のランチマネーが提供されます。",
    "Commute Allowance": "通勤手当",
    "Concerns with transportation and parking eased so that team members can focus on delivering value.":
      "交通手段や駐車場に関する懸念が軽減され、チームメンバーは価値の提供に集中できるようになりました。",
    "Flex Benefits": "フレックス特典",
    "For almost anything that improves your life - training courses, fitness plans... even beauty facials!":
      "トレーニング コース、フィットネス プラン、さらにはビューティー フェイシャルなど、あなたの生活を向上させるほぼすべてのことに対応しています。",
    "Medical Benefits": "医療給付金",
    "Coverage of outpatient, dental and optical services from a registered clinic of your choice.":
      "選択した登録クリニックの外来、歯科、光学サービスをカバーします。",
    "Flexible working hours": "フレキシブルな労働時間",
    "Contribute to the team effort without worrying about what time to clock in.":
      "打刻時間を気にすることなく、チームの努力に貢献します。",
    "Learning Sessions": "学習セッション",
    "Keep up with the latest breakthroughs and best practices in the industry with learned and experienced teammates.":
      "知識と経験豊富なチームメイトとともに、業界の最新の進歩とベスト プラクティスを常に把握してください。",
    "Working at BlocksScan": "BlocksScan での作業",
    "BlocksScan is a team pioneering at the forefront of the blockchain industry. Our platform provides the infrastructure for users and developers to access and build decentralized applications powering billions of dollars in value.":
      "BlocksScan は、ブロックチェーン業界の最前線で先駆者となるチームです。当社のプラットフォームは、ユーザーと開発者が数十億ドルの価値を生み出す分散型アプリケーションにアクセスして構築するためのインフラストラクチャを提供します。",
    "We are made up of diverse backgrounds and skillsets, with a combined passion for providing equitable access to open financial systems. While we strive for excellence in all that we do, we don't take ourselves too seriously either. We love to explore new frontiers and have fun in doing so.":
      "私たちは多様な背景とスキルセットで構成されており、オープンな金融システムへの公平なアクセスを提供することに共通の情熱を持っています。私たちは、行うすべてのことにおいて卓越性を追求する一方で、自分自身をあまり真剣に考えていません。私たちは新しいフロンティアを探索するのが大好きで、それを楽しんでいます。",
    "Understand Contract Functionalities":
      "契約機能の理解契約の仕組みを理解する",
    "What are the functions or methods available in this contract?":
      "この契約ではどのような機能や方法が利用できますか？",
    "Design a WithdrawBalance() method to withdraw contract balance.":
      "契約残高を引き出すための WithdrawBalance() メソッドを設計します。",
    "What is the logical structure of this contract's code?":
      "この契約のコードの論理構造は何ですか?",
    "Is this a proxy contract?": "これは代理人契約ですか？",
    "Write me a smart contract of voting.":
      "投票のスマートな契約書を書いてくれ。",
    "What does this contract do?": "この契約は何をするのでしょうか?",
    "How does SafeMath change the contract's state?":
      "SafeMath はどのように契約の状態を変更するのですか？",
    "Code Reader": "コードリーダー",
    NEW: "新しい",
    "Understand and use smart contracts better with CodeRunAI.":
      "CodeRunAI を使用してスマートコントラクトをよりよく理解し、活用する。",
    Mode: "モード",
    "I have an API Key": "APIキーを持っています",
    "Any open source contract": "任意のオープンソース契約",
    "Any question": "任意の質問",
    "I don't have an API Key": "APIキーを持っていません",
    "Popular open source contracts": "人気のあるオープンソース契約",
    "Example questions": "例の質問",
    DEMO: "デモ",
    "OpenAI API key": "OpenAI APIキー",
    "Where to find my API Key?": "APIキーの場所は？",
    "Save it to the browser": "ブラウザに保存する",
    "GPT Model": "GPTモデル",
    "Smart Contract": "スマート契約",
    "Contract Address": "契約アドレス",
    "Contract Code": "契約コード",
    Load: "読み込み",
    Trending: "トレンド",
    "Contract Files": "契約ファイル",
    "Source Code": "ソースコード",
    "Popular Contracts": "人気のある契約",
    "No Record Found": "レコードが見つかりません",
    "Loading...": "読み込み中...",
    "Hi, there.": "こんにちは。",
    "I can help you in the following scenarios":
      "以下のシナリオでお手伝いします",
    ME: "自分",
    "Enter any question you are interested in":
      "興味のある質問を入力してください",
    Signer: "署名者",
    "Send native": "ネイティブ送信",
    "Add one more": "もう1つ追加",
    Read: "読む",
    Write: "書く",
    Loading: "読み込み中",
    "Method Name": "メソッド名",
    Permalink: "パーマリンク",
    Code: "コード",
    "Read Contract": "契約の読み取り",
    "Read Proxy": "プロキシの読み取り",
    "Write Contract": "契約の書き込み",
    "Write Proxy": "プロキシの書き込み",
    "Contract Source Code Verified": "契約ソースコードが検証済み",
    "Are you the contract creator": "契約作成者ですか",
    "Verify And Publish": "検証して公開",
    "your contract source code today": "あなたの契約ソースコードを今日",
    "EVM Version": "EVMバージョン",
    "Optimization Runs": "最適化実行",
    "Contract File Path": "契約ファイルパス",
    "Compiler Version": "コンパイラバージョン",
    "Optimization Enabled": "最適化有効",
    "Verified At": "検証済み",
    "Constructor Arguments": "コンストラクタ引数",
    "ABI-encoded": "ABIエンコード",
    "Byte Code": "バイトコード",
    File: "ファイル",
    of: "の",
    "ABI for the implementation contract at": "実装契約のABI",
    "Type of Backing": "バッキングの種類",
    "Type of Peg": "ペグの種類",
    "Collater­alization": "担保",
    "Storage Of Assets": "資産の保管",
    Market: "市場",
    "Market Capitalization": "時価総額",
    "Circulating Supply": "流通供給量",
    "Click here to": "ここをクリックして",
    update: "更新",
    "the token information / general information": "トークン情報 / 一般情報",
    Metadata: "メタデータ",
    Missing: "見つかりません",
    Chain: "チェーン",
    "Portfolio %": "ポートフォリオ %",
    Price: "価格",
    Amount: "金額",
    Showing: "表示中",
    "tokens with a value of": "価値のあるトークン",
    Sponsored: "スポンサード",
    "Token Name": "トークン名",
    "Total Holders": "総保有者数",
    "Collection Name": "コレクション名",
    Validator: "バリデーター",
    in: "で",
    View: "表示",
    "Latest Transactions": "最新トランザクション",
    "Latest Blocks": "最新ブロック",
    Customize: "カスタマイズ",
    "NFT Address": "NFTアドレス",
    "all Blocks": "すべてのブロック",
    "Top Tokens": "トップトークン",
    "Top Contracts": "トップ契約",
    "Top NFTs": "トップNFT",
    "Ask AI | Search by Address | Txn Hash | Block | Token...":
      "アドレス/トランザクションハッシュ/ブロック/トークンで検索...",
    "No transactions found.": "トランザクションが見つかりません。",
    "Market Cap": "時価総額",
    "Total Transactions": "総取引高",
    "MED GAS PRICE": "メドガス価格",
    "TOTAL BLOCK": "トータルブロック",
    "GasTracker Estimate for Average Gas":
      "ガストラッカーによる平均ガス見積もり",
    "Total Accounts": "総アカウント数",
    "Daily Transactions": "日々の取引",
    "Contact Us": "お問い合わせ",
    "Feedback Form": "フィードバックフォーム",
    "Please understand that we can only assist with issues directly related to the block explorer services. Before writing in, please be informed that":
      "ブロックエクスプローラーサービスに直接関連する問題についてのみサポートできることをご理解ください。お問い合わせの前に、以下のことをお知らせください。",
    "Pending Transaction": "保留中の取引",
    "We do not": "私たちはしない",
    "process transactions": "トランザクションを処理する",
    "and are therefore unable to expedite, cancel or replace them. If you are having issues with pending transactions, please refer":
      "したがって、それらを早めたり、キャンセルしたり、交換したりすることはできません。保留中のトランザクションに関する問題が発生した場合は、こちらを参照してください。",
    here: "此処",
    "Blocksscan is an independent": "Blocksscan は独立したものです",
    "block explorer": "ブロックエクスプローラー",
    "unrelated to other service providers (unless stated explicitly otherwise) and is therefore unable to provide a precise response for inquiries that are specific to other service providers.":
      "他のサービスプロバイダーとは無関係であるため（明示的に別段の記載がない限り）、他のサービスプロバイダーに固有の問い合わせに対して正確な回答を提供することはできません。",
    "Knowledge Base": "知識ベース",
    "The links on the right might already have the answer to your inquiry. If they don't, perhaps one of the other articles in our":
      "右側のリンクには、あなたの問い合わせに対する答えがすでに掲載されている可能性があります。そうでない場合は、おそらく他の記事のいずれかが必要です。",
    "page does.": "ページはそうします。",
    "Community Support": "コミュニティサポート",
    "We will never ask for your private keys or passwords.":
      "私たちがあなたの秘密キーやパスワードを尋ねることは決してありません。",
    "NEVER send us these.": "決して私たちにこれらを送ってはいけません。",
    "While we try our best to respond in a timely manner, we often have a backlog of inquiries. Please do not":
      "タイムリーに対応するよう最善を尽くしておりますが、お問い合わせが殺到していることがよくあります。しないでください",
    "spam us": "私たちにスパムメールを送信する",
    "Wallet / Exchange / Project related issues":
      "ウォレット / エクスチェンジ / プロジェクト関連の問題",
    "Kindly reach out to your wallet service provider, exchanges or project/contract owner for further support as they are in a better position to assist you on the issues related to and from their platforms.":
      "ウォレット サービス プロバイダー、取引所、またはプロジェクト/契約所有者に連絡してさらなるサポートを求めてください。プラットフォームに関連する問題やプラットフォームから発生する問題については、彼らの方がより適切な立場にあるためです。",
    Subject: "主題",
    "Please Select Your Message Subject": "メッセージの件名を選択してください",
    "General Inquiry": "一般的なお問い合わせ",
    Advertising: "広告",
    "Update Token Info": "トークン情報の更新",
    "Add Name Tag/Label": "ネームタグ/ラベルの追加",
    "Request Removal of Name Tag": "名札の削除をリクエストする",
    "Suggest Transaction Action": "トランザクションアクションの提案",
    "Update Proxy Contract's Implementation Address":
      "プロキシ契約の実装アドレスの更新",
    "Report Phishing Address": "フィッシングアドレス報告",
    "Security Audit": "セキュリティ監査",
    "Priority Support": "優先サポート",
    "API Support": "APIサポート",
    "1. Inquiries": "1.問い合わせ",
    "2. Submissions": "2. 提出物",
    "3. Security": "3. セキュリティ",
    "Help & Support": "ヘルプ＆サポート",
    "24/7 support. Our team is here to help you and make sure your product is up to date.":
      "年中無休のサポート。私たちのチームがお客様をサポートし、製品が最新の状態であることを確認します。",
    Sales: "販売",
    "Have a presale question about our products and features? Or looking for a refund?":
      "当社の製品と機能について販売前の質問がありますか?それとも返金をお探しですか?",
    "Media & Press": "メディアとプレス",
    "Online awards and publications. Get our online media resources and learn about our company.":
      "オンラインの賞と出版物。オンライン メディア リソースを入手して、当社について学びましょう。",
    "Tx Hash|Block|Age": "トランザクション・ハッシュ|ブロック|年齢",
    "12 days ago": "12日前",
    "Event Logs": "イベントログ",
    Mint: "ミント",
    Transaction: "トランザクション",
    Success: "成功",
    Failed: "失敗",
    Pending: "未決",
    "Contract call": "契約コール",
    "Contract creation": "契約書の作成",
    "Token transfer": "トークンの転送",
    "Token creation": "トークンの作成",
    "Coin transfer": "コイン・トランスファー",
    REMASC: "報酬マネージャーのスマートコントラクト",
    Bridge: "ブリッジ",
    Rank: "ランク",
    Quantity: "数量",
    Item: "アイテム",
    Details: "詳細",
    ID: "身元",
    "Last Traded": "最後の取引",
    "Estimated Value on Day of Txn": "取引当日の推定価格",
    "Displaying current Value; Click to show Value on Day of Txn":
      "現在の値を表示します。クリックすると取引当日の価値が表示されます",
    Action: "アクション",
    "Verify & Publish Contract Source Code": "契約ソースコードの検証と公開",
    "COMPILER TYPE AND VERSION SELECTION":
      "コンパイラーのタイプとバージョンの選択",
    "Contract Licence": "契約ライセンス",
    "For best practices, all contract source code holders, publishers and authors are encouraged to also specify the accompanying license for their verified contract source code provided.":
      "ベスト プラクティスとして、すべての契約ソース コード所有者、発行者、および作成者は、提供される検証済みの契約ソース コードに付随するライセンスも指定することをお勧めします。",
    "Please select": "選んでください",
    "Verification Method": "検証方法",
    "Compiler Type": "コンパイラ・タイプ",
    "Enter name here...": "ここに名前を入力...",
    "Is Yul Contract": "Yulコントラクトですか",
    "The compiler version is specified in pragma solidity X.X.X. Use the compiler version rather than the nightly build. If using the Solidity compiler, run solc —version to check.":
      "コンパイラのバージョンはプラグマ Solidity X.X.X で指定します。夜間ビルドではなくコンパイラ バージョンを使用してください。 Solidity コンパイラーを使用している場合は、solc — バージョンを実行して確認します。",
    "The EVM version the contract is written for. If the bytecode does not match the version, we try to verify using the latest EVM version.":
      "コントラクトが書かれている Ethereum 仮想マシンのバージョン。バイトコードがバージョンと一致しない場合は、最新の Ethereum 仮想マシン バージョンを使用して検証を試みます。",
    "Type here ...": "ここに入力 ...",
    "We recommend using flattened code. This is necessary if your code utilizes a library or inherits dependencies. Use the POA solidity flattener or the Truffle flattener.":
      "フラット化されたコードを使用することをお勧めします。これは、コードがライブラリを利用する場合、または依存関係を継承する場合に必要です。 Proof-Of-Authority (PoA) Solidity Flattener または Truffle Flattener を使用します。",
    "Enter Source Code...": "ソースコードを入力...",
    "Standard JSON Input": "標準の JavaScript オブジェクト表記法 (JSON) 入力",
    "Sources and Metadata JSON": "ソースとメタデータ JSON",
    "Sources *.sol or *.yul files": "ソース *.sol または *.yul ファイル",
    "Main Interfaces (.vy or .json)":
      "メインインターフェイス (.vy または .json)",
    "Upload the standard input JSON file created during contract compilation":
      "コントラクトのコンパイル中に作成された標準入力 JSON ファイルをアップロードします",
    "Upload all Solidity contract source files and JSON metadata file(s) created during contract compilation.":
      "すべての Solidity コントラクト ソース ファイルとコントラクトのコンパイル中に作成された JSON メタデータ ファイルをアップロードします。",
    "Upload all Solidity or Yul contract source files":
      "すべての Solidity または Yul 契約のソース ファイルをアップロードする",
    "Primary compiled Vyper contract. Only add the main contract here whose bytecode has been deployed, all other files can be uploaded to the interfaces box below":
      "プライマリコンパイル済みVyperコントラクト。バイトコードがデプロイされているメイン コントラクトのみをここに追加します。他のすべてのファイルは下のインターフェイス ボックスにアップロードできます。",
    "Drag & drop files here, or click to select files":
      "ここにファイルをドラッグ アンド ドロップするか、クリックしてファイルを選択します",
    "Verify & Publish": "検証して公開する",
    Reset: "リセット",
    "Gas tracker": "ガストラッカー",
    "Network utilization": "ネットワーク利用率",
    "low load": "低負荷",
    "Last updated": "最終更新",
    Fast: "速い",
    Normal: "ノーマル",
    Slow: "遅い",
    "Gas price history": "ガス価格の履歴",
    "Charts & stats": "チャートと統計",
    "Account Balance Checker": "口座残高チェッカー",
    "You can Lookup the Account": "アカウントを検索できます",
    "Historical Balance at a specific Block No or Date.":
      "特定のブロック番号または日付における過去の残高。",
    Account: "アカウント",
    "Date for the snapshot": "スナップショットの日付",
    "Block Number": "ブロック番号",
    Lookup: "見上げる",
    "Choose desired language": "希望の言語を選択",
    "Choose the theme": "テーマを選ぶ",
    "Dark Mode": "ダークモード",
    "Do you want to show relative time? eg.": "相対時間を表示しますか?例えば。",
    "Do you want to show UTC time? eg.": "UTC時間を表示しますか?例えば。",
    "Change Fiat Currency": "不換紙幣の変更",
    Use: "使用",
    "prefix instead of": "の代わりに接頭辞",
    "NFT Latest Mints": "NFT最新ミント",
    "Token Id": "トークン・アイデンティティ",
    "NFT Transfer": "NFTトランスファー",
    "Your browser does not support the video tag.":
      "お使いのブラウザはビデオタグをサポートしていません。",
    "Min Price": "最低価格",
    "Last Sale": "ラストセール",
    "Current owner of this NFT.": "このNFTの現オーナー。",
    "Address of this NFT contract.": "このNFT契約の住所",
    "Address that deployed this NFT contract.":
      "この NFT コントラクトをデプロイしたアドレス。",
    Creator: "クリエイター",
    "Classification of NFT based on how the image is stored: On-Chain refers to those with data stored on the blockchain while Off-Chain refers to those with data stored elsewhere on the Internet.":
      "画像の保存方法によるNFTの分類： オンチェーンとはブロックチェーン上にデータが保存されているものを指し、オフチェーンとはインターネット上の別の場所にデータが保存されているものを指す。",
    Classification: "分類",
    "This NFT's unique token ID.": "このNFTの固有のトークンID。",
    "The standard followed by this NFT.": "このNFTに続く標準。",
    "Token Standard": "トークン規格",
    "Marketplaces trading this NFT.": "このNFTを取引するマーケットプレイス。",
    Marketplaces: "マーケットプレイス",
    Properties: "プロパティ",
    Description: "説明",
    Collection: "コレクション",
    Volume: "音量",
    Change: "変化",
    "Max Price": "最高価格",
    "NFT Top Mints": "NFTトップミント",
    Owners: "所有者",
    "Total Assets": "総資産",
    "Note: Filtered by totalSupply(), method returned a zero value.":
      "注：トータル・サプライ()でフィルタリングされ、メソッドはゼロ値を返した。",
    "A Paid Service": "支払いサービス",
    "At BlocksScan, we handle a substantial volume of support requests daily. While we strive our best to respond to each one in a timely manner, we recognize that certain projects may want to submit their token information quicker than our standard turnaround time.":
      "BlocksScanでは、毎日大量のサポートリクエストを処理しています。その一つ一つにタイムリーに対応するよう最善を尽くしていますが、プロジェクトによっては、トークン情報を標準納期よりも早く提出したい場合もあることを認識しています。",
    "In addition to expediting token updates, we also provide priority support for other urgent or technical matters, such as contract verification.":
      "トークンの更新を迅速に行うことに加えて、契約の検証など、その他の緊急または技術的な問題に対する優先サポートも提供します。",
    "Kindly follow the simple steps below to get your support prioritized.":
      "以下の簡単な手順に従って、サポートの優先順位を決定してください。",
    "Get Started": "始めましょう",
    "How to Start": "始め方",
    "Simple steps for getting Priority Support.":
      "優先サポートを受けるための簡単な手順。",
    "Step 1": "ステップ1",
    "Check Token Information": "トークン情報の確認",
    "Ensure that token information is submitted. Check our token update guidelines":
      "トークン情報が送信されていることを確認してください。トークン更新ガイドラインを確認してください。",
    "Step 2": "ステップ2",
    "Provide Token Update Request": "トークン更新リクエストを提供する",
    "Provide us with the token update request information in the contact form below.":
      "下のコンタクトフォームにトークン更新リクエスト情報を入力してください。",
    "Step 3": "ステップ3",
    "We'll Review Your Request": "リクエストを検討させていただきます",
    "Our team will review your request and contact you using an official":
      "私たちのチームは、あなたのリクエストを検討し、公式に連絡します",
    "Please use this form to get in touch for priority support or featured listing with our team.":
      "優先サポートや注目のリストについて当社チームにご連絡いただくには、このフォームをご利用ください。",
    "Requester Name": "依頼者名",
    "Requester Email": "リクエスタのメールアドレス",
    "Token Contract Address": "トークンコントラクトアドレス",
    "Choose Priority Service": "優先サービスを選択してください",
    "Token Update": "トークンの更新",
    "Featured Listing": "注目のリスト",
    "Contract Verification": "契約の検証",
    Others: "その他",
    "Learn more about": "詳しくはこちら",
    "Helpdesk Ticket Number": "ヘルプデスクチケット番号",
    "Additional Notes": "その他の注意事項",
    Note: "注意",
    "We will": "私たちは",
    NOT: "ない",
    "request payment via direct deposit. All payments will be directed through our official payment channel.":
      "直接入金による支払いをリクエストします。すべての支払いは、当社の公式支払いチャネルを通じて行われます。",
    NEVER: "なし",
    "ask for your private key or seed words. Please":
      "秘密鍵またはシードワードを尋ねます。お願いします",
    "DO NOT SHARE": "共有しないでください",
    "your private keys with anyone.": "あなたの秘密鍵を誰にでも共有できます。",
    "Send Message": "メッセージを送信",
    "Ex. John Doe": "例 - ジョン・ドウ",
    "If you have any additional notes, please leave them here":
      "その他の注意事項があれば、ここに書き残してください。",
    "Privacy Policy": "プライバシーポリシー",
    "Welcome to Blocksscan Explorer Services":
      "Blocksscan Explorer サービスへようこそ",
    we: "私たち",
    us: "私たち",
    our: "私たちの",
    "We are committed to respecting the privacy and security of our users' information. This Privacy Policy outlines the types of information we may collect from you or that you may provide when you use our services, and our practices for collecting, using, maintaining, protecting, and disclosing that information.":
      "当社はユーザーの情報のプライバシーとセキュリティを尊重することに尽力しています。このプライバシー ポリシーでは、当社がお客様から収集する情報の種類、またはお客様が当社のサービスを使用する際に提供する可能性のある情報の種類、およびその情報の収集、使用、維持、保護、開示に関する当社の慣行について概説します。",
    "Information We Collect": "当社が収集する情報",
    "We collect several types of information from and about users of our Blocksscan Explorer Services, including":
      "当社は、Blocksscan Explorer サービスのユーザーから、およびユーザーに関するいくつかの種類の情報を収集します。",
    "Personal Information: Data that personally identifies you, such as your name, email address, and payment details, provided at registration or during the use of our services.":
      "個人情報: 登録時または当社サービスの使用中に提供される、名前、電子メール アドレス、支払い詳細など、お客様を個人的に特定するデータ。",
    "Non-Personal Information: Data that does not personally identify you but is linked to your usage of our services, such as IP addresses, browser types, and access times.":
      "非個人情報： IPアドレス、ブラウザの種類、アクセス時間など、個人を特定するものではないが、当社サービスの利用に関連するデータ。",
    "Transactional Information: Data related to transactions conducted on the network, which may include public blockchain data.":
      "取引情報： ネットワーク上で行われた取引に関するデータで、パブリックブロックチェーンデータを含む場合がある。",
    "How We Use Your Information": "お客様の情報の使用方法",
    "We use the information that we collect about you or that you provide to us, including any personal information":
      "当社は、お客様について収集した情報、またはお客様が当社に提供した情報（個人情報を含む）を使用します。",
    "To present our services and its contents to you.":
      "当社のサービスおよびその内容をお客様にご紹介するため。",
    "To provide you with information, products, or services that you request from us.":
      "お客様が当社に求める情報、製品、サービスを提供するため。",
    "To fulfill any other purpose for which you provide it.":
      "お客様が提供するその他の目的を達成するため。",
    "To carry out our obligations and enforce our rights arising from any contracts entered into between you and us.":
      "当社の義務を履行し、お客様と当社との間で締結された契約から生じる権利を行使するため。",
    "To notify you about changes to our service or any products or services we offer or provide through it.":
      "当社のサービス、または当社が提供または提供する製品やサービスの変更についてお客様に通知するため。",
    "For any other purpose with your consent.":
      "その他、お客様の同意を得た目的のため。",
    "Data Security": "データセキュリティ",
    "We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. However, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our services.":
      "우리는 우발적인 손실과 무단 액세스, 사용, 변경 및 공개로부터 귀하의 개인 정보를 보호하기 위해 고안된 조치를 취했습니다. 그러나 인터넷을 통한 정보 전송은 완전히 안전하지 않습니다. 우리는 귀하의 개인 정보를 보호하기 위해 최선을 다하지만 , 당사는 당사 서비스에 전송되는 귀하의 개인정보의 보안을 보장할 수 없습니다.",
    "Data Retention": "データ保持",
    "We will retain your personal information for as long as your account is active or as needed to provide you services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.":
      "当社は、お客様のアカウントが有効である限り、またはお客様にサービスを提供するために必要な限り、お客様の個人情報を保持します。当社は、当社の法的義務の遵守、紛争の解決、および契約の執行のために必要なお客様の情報を保持し、使用します。",
    "Your Rights": "あなたの権利",
    "You have the right to request access to, correct, or delete any personal information that you have provided to us. You may also object to or request restrictions on the processing of your personal information.":
      "お客様は、当社に提供した個人情報へのアクセス、訂正、削除を要求する権利を有します。また、個人情報の処理に異議を唱えたり、制限を要求したりすることもできます。",
    "Changes to Our Privacy Policy": "プライバシーポリシーの変更",
    "It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you and for periodically visiting our website and this privacy policy to check for any changes.":
      "当社のプライバシーポリシーに変更があった場合は、このページに掲載する方針です。プライバシーポリシーの最終改訂日は、ページの上部に記載されています。利用者は、当社が利用者の最新かつ有効で配信可能な電子メールアドレスを持っていることを確認し、当社のウェブサイトおよびこのプライバシーポリシーを定期的に訪問して、変更がないかどうかを確認する責任を負うものとします。",
    "Contact Information": "連絡先",
    "To ask questions or comment about this privacy policy and our privacy practices, contact us at":
      "このプライバシー ポリシーおよび当社のプライバシー慣行についてご質問またはコメントが必要な場合は、次のアドレスまでお問い合わせください。",
    "By using Blocksscan Explorer Services, you consent to our Privacy Policy.":
      "お客様は、Blocksscan Explorerのサービスを利用することにより、当社のプライバシーポリシーに同意したものとみなされます。",
    "Max Total Supply": "最大総供給量",
    "Note: Filtered by totalSupply(), method returned a zero value":
      "注：合計供給量()によってフィルタリングされ、メソッドはゼロ値を返した。",
    Holders: "ホルダー",
    "Total Transfers": "振替高",
    Implementation: "実施",
    "Fully Diluted Market Cap": "完全希薄化後時価総額",
    "Circulating Supply Market Cap": "循環供給市場キャップ",
    "Other Info": "他の情報",
    "Token Contract": "トークンコントラクト",
    "FILTERED BY TOKEN HOLDER": "トークン保有者ごとにフィルタリング",
    BALANCE: "バランス",
    Inventory: "在庫",
    Info: "情報",
    With: "と",
    decimals: "小数点以下",
    "Volume 24h": "ボリューム24時間",
    "Circulating Market Cap": "流通時価総額",
    "Onchain Market Cap": "オンチェーン時価総額",
    "Token Transactions": "トークン取引",
    "X (Twitter)": "X（ツイッター）",
    Github: "ギットハブ",
    Facebook: "フェイスブック",
    Instagram: "インスタグラム",
    "Back to Top": "このページのトップへ",
    "Powered by BlocksScan": `${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"} の提供`,
    "BlocksScan is a Block Explorer and Analytics Platform for":
      `${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"}はブロックエクスプローラーであり、次のような分析プラットフォームです。`,
    "a decentralized smart contracts platform.":
      "分散型スマート・コントラクト・プラットフォームである。",
    Company: "会社",
    "About Us": "私たちについて",
    "Brand Assets": "ブランド資産",
    Careers: "キャリア",
    "We're Hiring": "募集中です",
    Terms: "条項",
    Privacy: "プライバシー",
    Community: "コミュニティ",
    "API Documentation": "APIドキュメント",
    "Network Status": "ネットワークステータス",
    Newsletters: "ニュースレター",
    Products: "プロダクツ",
    Services: "サービス",
    "Explorer-as-a-Service": "エクスプローラー・アズ・ア・サービス",
    "API Plans": "APIプラン",
    "All Rights Reserved": "無断転載を禁じます",
    Donations: "寄付",
    Home: "家",
    CodeRunAI: "コードランAI",
    Blockchain: "ブロックチェーン",
    Live: "ライブ",
    WIP: "仕掛品",
    "Pending Transactions": "未決済取引",
    "Contract Internal Transactions": "コントラクト内部取引",
    "View Blocks": "ブロックを見る",
    "Top Accounts": "トップアカウント",
    "Token Transfers": "トークン譲渡",
    "Top Mints": "トップミント",
    "Latest Trades": "最新の取引",
    "Latest Transfers": "最新の振替",
    "Latest Mints": "最新のミント",
    Resources: "リソース",
    "Charts And Stats": "チャートと統計",
    "Top Statistics": "トップの統計",
    Leaderboard: "リーダーボード",
    Directory: "ディレクトリ",
    Developers: "開発者",
    "Verify Contract": "契約の確認",
    "Similar Contract Search": "類似契約検索",
    "Smart Contract Search": "スマートコントラクトの検索",
    "Contract Diff Checker": "契約差分チェッカー",
    "Vyper Online Compiler": "Vyperオンラインコンパイラー",
    "Bytecode to Opcode": "バイトコードからオペコードへ",
    "Broadcast Transaction": "ブロードキャストトランザクション",
    More: "もっと",
    "Balance Checker": "バランスチェッカー",
    "Gas Tracker": "ガストラッカー",
    Light: "ライト",
    Dim: "薄暗い",
    Dark: "暗い",
    Gas: "ガス",
    "Last Updated": "最終更新日",
    "Gas tracker overview": "ガストラッカーの概要",
    "All Transactions": "すべての取引",
    "Custom Card": "カスタムカード",
    "Customize this card by selecting one of the options below":
      "以下のオプションのいずれかを選択して、このカードをカスタマイズします",
    "Advanced Filters": "高度なフィルター",
    Present: "現在",
    "Saved Filters": "保存されたフィルター",
    Close: "閉じる",
    "Save Changes": "変更内容を保存",
    "Top NFT": "トップNFT",
    "Top Contract": "トップコントラクト",
    "Top Token": "トップ・トークン",
    "Latest Transaction": "最新の取引",
    "Latest Block": "最新のブロック",
  },
};
