import React, {CSSProperties, FC, useEffect, useState} from "react";
import BTooltip from "../../../components/b-tooltip/index";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import CG from "../../../images/coingecko_new.svg";
import CMC from "../../../images/cmc_new.svg";
import {dashboardService} from "../../../store/actions";
import MetaMaskButton from "../metamask";
import {Link, useNavigate} from "react-router-dom";
import {useDarkMode, useLocalStorage} from "usehooks-ts";
import BAvatar from "../../common/b-avatar";
import BlockImg from "../../../images/blocks_bg.png";
import WaveLight from "../../../images/waves-light.svg";
import NetworkButton from '../metamask/support-files/network-btn';

const Footer: FC = () => {
    const {t, i18n} = useTranslation();
    const _config = useSelector((e: Object) => (e as any)?.common?.config);
    const [syncStatus, setSyncStatus] = useState<any>(null);
    const [showSyncStatus, setShowSyncStatus] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isDarkMode, toggle} = useDarkMode();
    const [value, setValue] = useLocalStorage<string>(
        "isDarkMode",
        isDarkMode ? "dark" : "light"
    );
    const [bgImageIndex, setBgImageIndex] = useState<number>(1);
    const [bgGradient, setBgGradient] = useState<string>('');

    const backgroundImages: { [key: number]: string } = {
        1: BlockImg,
        2: WaveLight,
        3: BlockImg,
    };

    const getBackgroundImage = (index: number): string => {
        return backgroundImages[index] || BlockImg;
    };

    const getBackgroundGradient = (mode: string): string => {
        if (mode === "dark") {
            return process.env.REACT_APP_BACKGROUND_DARK || "45deg, #111111, #1a1a1a, #232323, #2c2c2c, #363636";
        } else if (mode === "dim") {
            return process.env.REACT_APP_BACKGROUND_DIM || "45deg, #111a2e, #14223d, #19294d, #1d3160, #233a75";
        } else {
            return process.env.REACT_APP_BACKGROUND_GRADIENT || "45deg, #081d35, #102a55, #193877, #224799";
        }
    };

    const handleThemeToggle = () => {
        const newTheme = value === "dark" ? "light" : "dark";
        setValue(newTheme);
        toggle();
    };

    const handleClick = () => {
        navigate('/settings');
    };

    const getSyncStatus = async () => {
        try {
            dashboardService.syncStatus().then(response => {
                setSyncStatus(response)
            }).catch((e) => '');
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSyncStatus(true);
        }, 5000);

        getSyncStatus();
        const parsedBgImageIndex = parseInt(process.env.REACT_APP_BACKGROUND_IMAGE_INDEX || '1', 10);
        setBgImageIndex(parsedBgImageIndex);
        const gradient = getBackgroundGradient(value);
        setBgGradient(gradient);

        return () => clearTimeout(timer);
    }, [value]);
    const legacy = _config.footer?.legecyFooter ? false : true;
    const footerStyle: CSSProperties | undefined = !legacy
        ? {backgroundImage: `url(${getBackgroundImage(bgImageIndex)}), linear-gradient(${bgGradient})`}
        : undefined;
    const removeMargin: CSSProperties | undefined = !legacy
        ? {marginBottom: "-1px"}
        : undefined;
    const combinedStyle: CSSProperties = {
        ...footerStyle,
        ...removeMargin
    };

    return (
        <React.Fragment>
            {/* add 'mb-5' if wanted to add syncStatus?.finished_indexing_blocks */}
            <footer className={`bs-footer d-print-none ${syncStatus?.finished_indexing_blocks ? '' : ''}`} style={combinedStyle}>
                <div className="container pt-3">
                    <div className={!legacy ? "row justify-content-sm-between align-items-center" : "d-flex justify-content-between align-items-baseline py-6"}>
                        {!legacy ? (
                            <React.Fragment>
                            <div className="col-sm mb-3 mb-sm-0">
                            <div className="d-flex align-items-center">
                            <Link className="text-decoration-none text-dark" to="/">
                            <div className="d-flex align-items-center mb-3">
                            <BAvatar className="mr-2" size={26}/>
                                <span className="ms-2 fs-5 text-white">{t("Powered by ")} {_config?.footer?.poweredByText || 'OpenScan.ai'}</span>
                            </div>                        
                            </Link>
                            </div>
                            </div>
                            <div className="col-sm-6 align-self-bottom">
                            <div className="d-flex justify-content-sm-end align-items-baseline mb-4 gap-2">
                                <span><MetaMaskButton/></span>
                                <button className="btn btn-sm bs-btn-cust align-self-baseline" onClick={handleClick}>
                                    <i className="bi bi-gear-fill me-2"></i> Preferences
                                </button>
                                <BTooltip text={"Day/Night Mode"}>
                                    <div className="btn-group align-self-baseline">
                                        <button className="btn dropdown-toggle theme-dropdown link-dark" type="button" id="defaultDropdown" onClick={handleThemeToggle}>
                                            {value === "dark" ? (
                                                <i className="bi bi-moon"></i>
                                            ) : (
                                                <i className="bi bi-brightness-high"></i>
                                            )}
                                        </button>
                                    </div>
                                </BTooltip>
                                <a className="link-dark align-self-baseline text-white" href="#">
                                    <i className="bi bi-arrow-bar-up me-1"></i>{t("Back to Top")}
                                </a>
                            </div>
                        </div>
                            </React.Fragment>
                        ) : (<React.Fragment>
                        <div className="d-flex gap-2">
                            {!!_config?.social?.twitter?.length && <BTooltip text={t("X (Twitter)")} options={{position: "right center"}}>
                                <a
                                    className="social-icon btn btn-sm btn-secondary content-center rounded-circle"
                                    style={{width: "2rem", height: "2rem"}}
                                    href={_config?.social?.twitter}
                                    rel="nofollow noopener"
                                    target="_blank"
                                >
                                    <i className="bi bi-twitter-x"></i>
                                </a>
                            </BTooltip>}
                            {!!_config?.social?.github?.length && <BTooltip text={t("Github")} options={{position: "top center"}}>
                                <a
                                    className="social-icon btn btn-sm btn-secondary content-center rounded-circle"
                                    style={{width: "2rem", height: "2rem"}}
                                    href={_config?.social?.github}
                                    rel="nofollow noopener"
                                    target="_blank"
                                >
                                    <i className="bi bi-github"></i>
                                </a>
                            </BTooltip>}
                            {!!_config?.social?.facebook?.length && <BTooltip text={t("Facebook")} options={{position: "top center"}}>
                                <a
                                    className="social-icon btn btn-sm btn-secondary content-center rounded-circle"
                                    style={{width: "2rem", height: "2rem"}}
                                    href={_config?.social?.facebook}
                                    rel="nofollow noopener"
                                    target="_blank"
                                >
                                    <i className="bi bi-facebook"></i>
                                </a>
                            </BTooltip>}
                            {!!_config?.social?.instagram?.length && <BTooltip text={t("Instagram")} options={{position: "top center"}}>
                                <a
                                    className="social-icon btn btn-sm btn-secondary content-center rounded-circle"
                                    style={{width: "2rem", height: "2rem"}}
                                    href={_config?.social?.instagram}
                                    rel="nofollow noopener"
                                    target="_blank"
                                >
                                    <i className="bi bi-instagram"></i>
                                </a>
                            </BTooltip>}
                            {!!_config?.social?.telegram?.length && <BTooltip text={t("Telegram")} options={{position: "top center"}}>
                                <a
                                    className="social-icon btn btn-sm btn-secondary content-center rounded-circle"
                                    style={{width: "2rem", height: "2rem"}}
                                    href={_config?.social?.telegram}
                                    rel="nofollow noopener"
                                    target="_blank"
                                >
                                    <i className="bi bi-telegram"></i>
                                </a>
                            </BTooltip>}
                            {!!_config?.social?.reddit?.length && <BTooltip text={t("Reddit")} options={{position: "top center"}}>
                                <a
                                    className="social-icon btn btn-sm btn-secondary content-center rounded-circle"
                                    style={{width: "2rem", height: "2rem"}}
                                    href={_config?.social?.reddit}
                                    rel="nofollow noopener"
                                    target="_blank"
                                >
                                    <i className="bi bi-reddit"></i>
                                </a>
                            </BTooltip>}
                            {!!_config?.social?.medium?.length && <BTooltip text={t("Medium")} options={{position: "top center"}}>
                                <a
                                    className="social-icon btn btn-sm btn-secondary content-center rounded-circle"
                                    style={{width: "2rem", height: "2rem"}}
                                    href={_config?.social?.medium}
                                    rel="nofollow noopener"
                                    target="_blank"
                                >
                                    <i className="bi bi-medium"></i>
                                </a>
                            </BTooltip>}
                            {!!_config?.social?.youtube?.length && <BTooltip text={t("YouTube")} options={{position: "top center"}}>
                                <a
                                    className="social-icon btn btn-sm btn-secondary content-center rounded-circle"
                                    style={{width: "2rem", height: "2rem"}}
                                    href={_config?.social?.youtube}
                                    rel="nofollow noopener"
                                    target="_blank"
                                >
                                    <i className="bi bi-youtube"></i>
                                </a>
                            </BTooltip>}
                            {!!_config?.social?.coingecko?.length && <BTooltip text={t("CoinGecko")} options={{position: "top center"}}>
                                <a
                                    className="social-icon btn btn-sm btn-secondary content-center rounded-circle"
                                    style={{width: "2rem", height: "2rem"}}
                                    href={_config?.social?.coingecko}
                                    rel="nofollow noopener"
                                    target="_blank"
                                >
                                    <img src={CG} height={14} width={14}/>
                                </a>
                            </BTooltip>}
                            {!!_config?.social?.coinmarketcap?.length && <BTooltip text={t("CoinMarketCap")} options={{position: "top center"}}>
                                <a
                                    className="social-icon btn btn-sm btn-secondary content-center rounded-circle"
                                    style={{width: "2rem", height: "2rem"}}
                                    href={_config?.social?.coinmarketcap}
                                    rel="nofollow noopener"
                                    target="_blank"
                                >
                                    <img src={CMC} height={14} width={14}/>
                                </a>
                            </BTooltip>}
                        </div>
                                {/* <a className="link-dark" href="#">
                            <i className="fa fa-arrow-up-to-line me-1"></i>{t("Back to Top")}
                        </a> */}
                            </React.Fragment>
                        )}

                        {legacy && (<a className="link-dark" href="#">
                            <i className="bi bi-arrow-bar-up me-1"></i>{t("Back to Top")}
                        </a>)}
                    </div>
                    <hr className="my-0"/>

                    {legacy && (<div className="row justify-content-md-between py-6">
                        <div className="col-lg-4 pe-xl-16 mb-4 mb-lg-0">
                            <div className="d-flex align-items-center mb-3">
                                <span className="fs-5">{t("Powered by ")} {_config?.footer?.poweredByText || 'OpenScan.ai'}</span>
                            </div>
                            <p className="fs-sm">
                                {_config?.footer?.scanText || 'OpenScan.ai'} {t(" is a Block Explorer and Analytics Platform for")}{" "}
                                {process.env.REACT_APP_BASE_UNIT}, {t("a decentralized smart contracts platform.")}
                            </p>
                            <NetworkButton/>
                        </div>
                        <div className="col-6 col-md-4 col-lg mb-10 mb-md-0">
                            <h4 className="fw-bold mb-3">{t("Company")}</h4>

                            <ul className="list-unstyled list-sm-space fs-sm mb-0">
                                {!!_config?.links?.aboutUs && <li className="mb-2">
                                    <a className="link-dark" href={_config?.links?.aboutUs}>
                                        {t("About Us")}
                                    </a>
                                </li>}
                                {!!_config?.links?.brandAssets && <li className="mb-2">
                                    <a className="link-dark" href="#">
                                        {t("Brand Assets")}
                                    </a>
                                </li>}
                                {!!_config?.links?.contactUs && <li className="mb-2">
                                    <a className="link-dark" href={_config?.links?.contactUs}>
                                        {t("Contact Us")}
                                    </a>
                                </li>}
                                {!!_config?.links?.careers && <li className="mb-2">
                                    <a className="link-dark" href={_config?.links?.careers}>
                                        <span className="me-1">{t("Careers")}</span>{" "}
                                        <span className="bg-primary text-white small fw-medium text-nowrap rounded-pill p-1 px-2">
                                            {t("We're Hiring")}!
                                        </span>
                                    </a>
                                </li>}
                                {!!_config?.links?.careers && <li className="mb-2">
                                    <a className="link-dark" href="/privacyPolicy">
                                        {t("Terms")}
                                    </a>{" "}
                                    &amp;{" "}
                                    <a className="link-dark" href="/privacyPolicy">
                                        {t("Privacy")}
                                    </a>
                                </li>}
                            </ul>
                        </div>
                        <div className="col-6 col-md-4 col-lg mb-10 mb-md-0">
                            <h4 className="fw-bold mb-3">{t("Community")}</h4>

                            <ul className="list-unstyled list-sm-space fs-sm mb-0">
                                {!!_config?.links?.api && <li className="mb-2">
                                    <a
                                        className="link-dark"
                                        href={_config?.links?.api}
                                        target="_blank"
                                    >
                                        {t("API Documentation")}
                                    </a>
                                </li>}
                                {!!_config?.links?.knowledgeBase && <li className="mb-2">
                                    <a className="link-dark" href={_config?.links?.knowledgeBase}>
                                        {t("Knowledge Base")}
                                    </a>
                                </li>}
                                {!!_config?.links?.networkStatus && <li className="mb-2">
                                    <a
                                        className="link-dark"
                                        href={_config?.links?.networkStatus}
                                        rel="nofollow noopener"
                                        target="_blank"
                                    >
                                        {t("Network Status")}
                                    </a>
                                </li>}
                                {!!_config?.links?.newsletter && <li className="mb-2">
                                    <a
                                        className="link-dark"
                                        href={_config?.links?.newsletter}
                                    >
                                        {t("Newsletters")}
                                    </a>
                                </li>}
                            </ul>
                        </div>
                        <div className="col-6 col-md-4 col-lg">
                            <h4 className="fw-bold mb-3">{t("Products")} &amp; {t("Services")}</h4>

                            <ul className="list-unstyled list-sm-space fs-sm mb-0">
                                <li className="mb-2">
                                    <a className="link-dark" href="https://blocksscan.io">
                                        {t("Explorer-as-a-Service")} (EaaS)
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a className="link-dark" href="https://docs.blocksscan.io">
                                        {t("API Plans")}
                                    </a>
                                </li>
                                {/* <li className="mb-2">
                                    <a className="link-dark" href="/prioritysupport">
                                        {t("Priority Support")}
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>)}

                    <div className="border-top py-4">
                        <div className="row justify-content-between align-items-center fs-sm">
                            <div className="col-md mb-2 mb-md-0">
                                <p className="mb-0 text-white">© 2024 {_config?.footer?.copyRightsText}</p>
                            </div>
                            {!legacy ? (
                                <React.Fragment>
                                <div className="col-md d-flex justify-content-end"> 
                                <ul className="d-flex mb-0 list-unstyled"> 
                                    {!!_config?.links?.careers && (
                                        <li className="me-3  mt-2">
                                            <a className="link-dark text-white" href="/privacyPolicy">
                                                {t("Terms of Service")}
                                            </a>
                                        </li>
                                    )}
                                    {!!_config?.links?.networkStatus && (
                                        <li className="me-3">
                                            <a
                                                className="link-dark"
                                                href={_config?.links?.networkStatus}
                                                rel="nofollow noopener"
                                                target="_blank"
                                            >
                                                {t("Network Status")}
                                            </a>
                                        </li>
                                    )}
                                    {!!_config?.social?.twitter?.length && <BTooltip text={t("X (Twitter)")} options={{position: "right center"}}>
                                        <a
                                            className="social-icon btn btn-sm btn-secondary content-center rounded-circle me-2 mb-2"
                                            style={{width: "2rem", height: "2rem"}}
                                            href={_config?.social?.twitter}
                                            rel="nofollow noopener"
                                            target="_blank"
                                        >
                                            <i className="bi bi-twitter-x"></i>
                                        </a>
                                    </BTooltip>}
                                    {!!_config?.social?.instagram?.length && <BTooltip text={t("Instagram")} options={{position: "top center"}}>
                                        <a
                                            className="social-icon btn btn-sm btn-secondary content-center rounded-circle me-2 mb-2"
                                            style={{width: "2rem", height: "2rem"}}
                                            href={_config?.social?.instagram}
                                            rel="nofollow noopener"
                                            target="_blank"
                                        >
                                            <i className="bi bi-instagram"></i>
                                        </a>
                                    </BTooltip>}
                                    {!!_config?.social?.coinmarketcap?.length && <BTooltip text={t("CoinMarketCap")} options={{position: "top center"}}>
                                        <a
                                            className="social-icon btn btn-sm btn-secondary content-center rounded-circle me-2 mb-2"
                                            style={{width: "2rem", height: "2rem"}}
                                            href={_config?.social?.coinmarketcap}
                                            rel="nofollow noopener"
                                            target="_blank"
                                        >
                                            <img src={CMC} height={14} width={14}/>
                                        </a>
                                    </BTooltip>}
                                    {!!_config?.social?.telegram?.length && <BTooltip text={t("Telegram")} options={{position: "top center"}}>
                                        <a
                                            className="social-icon btn btn-sm btn-secondary content-center rounded-circle me-2 mb-2"
                                            style={{width: "2rem", height: "2rem"}}
                                            href={_config?.social?.telegram}
                                            rel="nofollow noopener"
                                            target="_blank"
                                        >
                                            <i className="bi bi-telegram"></i>
                                        </a>
                                    </BTooltip>}
                                </ul>
                            </div>
                                </React.Fragment>
                            ) : (
                                <div className="col-md text-md-end">
                                    <p className="mb-0">
                                        {t("Donations")}:{" "}
                                        <a
                                            className="me-1"
                                            href={`/address/${_config?.footer?.donationAddress}`}
                                        >
                                            <span id="spanDonateAddress">
                                                {_config?.footer?.donationAddress}
                                            </span>
                                        </a>{" "}
                                        <i className="fas fa-heart text-danger"></i>
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </footer>
            {/* {showSyncStatus && !syncStatus?.finished_indexing_blocks && <div id="offline" className="bg-danger text-center w-100 position-fixed bottom-0 py-2 z-3">
                <span className="text-white">⌛ Indexing in progress. Blocks indexed: {syncStatus?.indexed_blocks_ratio *100?? 0}%.</span>
            </div>} */}
        </React.Fragment>
    );
};

export default Footer;
