export const du={
    translations:{
    "ABI for the implementation contract at": "ABI für den Implementierungsvertrag bei",
    "ABI-encoded": "ABI-kodiert",
    "API Documentation": "API-Dokumentation",
    "API Plans": "API-Pläne",
    "API Support": "API-Unterstützung",
    "About Us": "Über uns",
    "Account": "Konto",
    "Account Balance Checker": "Kontostandsprüfer",
    "Action": "Aktion",
    "Add Name Tag/Label": "Name-Tag / Etikett hinzufügen",
    "Add one more": "Noch eins hinzufügen",
    "Additional Info": "Zusätzliche Informationen",
    "Additional Notes": "Zusätzliche Notizen",
    "Address": "Adresse",
    "Address of this NFT contract.": "Adresse dieses NFT-Vertrags.",
    "Address that deployed this NFT contract.": "Adresse, die diesen NFT-Vertrag bereitgestellt hat.",
    "Advertising": "Werbung",
    "Age": "Alter",
    "All Rights Reserved": "Alle Rechte vorbehalten",
    "All Transactions": "Alle Transaktionen",
    "Amount": "Betrag",
    "Analytics": "Analytik",
    "Annual Trips": "Jährliche Reisen",
    
    "Are you the contract creator": "Sind Sie der Vertragscreator",
    "Back to Top": "Zurück nach oben",
    "Balance": "Bilanz",
    "Balance Checker": "Kontostandsprüfer",
    "Base": "Basis",
    "Base Fee": "Grundgebühr",
    "Base Fee Per Gas": "Grundgebühr pro Gas",
    "Best Coffee in Town": "Bester Kaffee in der Stadt",
    "Block": "Block",
    "Block Height": "Blockhöhe",
    "Block Number": "Blocknummer",
    "Block Reward": "Blockbelohnung",
    "Block header withdrawal root hash.": "Blockheader-Auszahlungswurzel-Hash.",
    "Block nonce is a value used during mining to demonstrate proof of work for a block.": "Block-Nonce ist ein Wert, der beim Mining verwendet wird, um den Arbeitsnachweis für einen Block zu demonstrieren.",
    "Block proposed on epoch": "Block vorgeschlagen auf Epoche",
    "Blocksscan is an independent": "Blocksscan ist unabhängig",
    "Blockchain": "Blockchain",
    "Blocks": "Blöcke",
    "Broadcast Transaction": "Transaktion übertragen",
    "Brand Assets": "Markenvermögen",
    "Bridge": "Brücke",
    "Burnt": "Verbrannt",
    "Burnt & Txn Savings Fees": "Verbrannte & Transaktionssparkosten",
    "Byte Code": "Byte-Code",
    "Bytecode to Opcode": "Bytecode zu Opcode",
    "By using Blocksscan Explorer Services, you consent to our Privacy Policy.": "Durch die Nutzung der Blocksscan Explorer-Dienste stimmen Sie unserer Datenschutzrichtlinie zu.",
    "CSV Export": "CSV-Export",
    "Call": "Anruf",
    "Capacity": "Kapazität",
    "Careers": "Karrieren",
    "Careers at BlocksScan": "Karrieren bei BlocksScan",
    "Change Fiat Currency": "Ändern Sie die Fiat-Währung",
    "Change": "Ändern",
    "Charts & stats": "Diagramme & Statistiken",
    "Charts And Stats": "Diagramme und Statistiken",
    "Choose Priority Service": "Wählen Sie den Prioritätsservice",
    "Choose desired language": "Wählen Sie die gewünschte Sprache",
    "Choose the theme": "Wählen Sie das Thema",
    "Circulating Market Cap": "Zirkulierende Marktkapitalisierung",
    "Circulating Supply": "Zirkulierendes Angebot",
    "Circulating Supply Market Cap": "Zirkulierende Angebotsmarktkapitalisierung",
    "Classification of NFT based on how the image is stored: On-Chain refers to those with data stored on the blockchain while Off-Chain refers to those with data stored elsewhere on the Internet.": "Klassifizierung von NFT basierend darauf, wie das Bild gespeichert ist: On-Chain bezieht sich auf diejenigen mit Daten, die auf der Blockchain gespeichert sind, während Off-Chain sich auf diejenigen mit Daten bezieht, die anderswo im Internet gespeichert sind.",
    "Classification": "Klassifizierung",
    "Click here to": "Klicken Sie hier, um",
    "Click to show less": "Klicken Sie, um weniger anzuzeigen",
    "Click to show more": "Klicken Sie, um mehr anzuzeigen",
    "Close": "Schließen",
    "Code": "Code",
    "Code Reader": "Codeleser",
    "CodeRunAI": "CodeRunAI",
    "Coin transfer": "Münzübertragung",
    "Collater­alization": "Besicherung",
    "Collection": "Sammlung",
    "Collection Name": "Sammlungsname",
    "Commute Allowance": "Pendlerzulage",
    "Community": "Gemeinschaft",
    "Community Support": "Community-Unterstützung",
    "Company": "Unternehmen",
    "Compiler": "Compiler",
    "Compiler Type": "Compiler-Typ",
    "Compiler Version": "Compiler-Version",
    "COMPILER TYPE AND VERSION SELECTION": "Auswahl von Compiler-Typ und -Version",
    "ConfirmationToken": "Bestätigung",
    "Confirmation": "Bestätigung",
    "Confirmed within": "Bestätigt innerhalb",
    "Confirming": "Bestätigung",
    "Contact Us": "Kontaktiere uns",
    "Contact Information": "Kontaktinformationen",
    "Contract": "Vertrag",
    "Contract Address": "Vertragsadresse",
    "Contract Code": "Vertragscode",
    "Contract Diff Checker": "Vertragsdiff-Prüfer",
    "Contract File Path": "Vertragsdateipfad",
    "Contract Internal Transactions": "Interne Vertragsabschlüsse",
    "Contract Licence": "Vertragslizenz",
    "Contract Name": "Vertragsname",
    "Contract Source Code Verified": "Verifizierter Vertragsquellcode",
    "Contract Verification": "Vertragsüberprüfung",
    "Contract call": "Vertragsanruf",
    "Contract creation": "Vertragserrichtung",
    "Contracts": "Verträge",
    "Contracts Internal": "Interne Verträge",
    "Cost per unit of gas spent for the transaction, in Ether and Gwei.": "Kosten pro Einheit Gas, die für die Transaktion in Ether und Gwei ausgegeben wurden.",
    "Creator": "Schöpfer",
    "Created": "Erstellt",
    "Current owner of this NFT.": "Aktueller Besitzer dieses NFTs.",
    "Custom Card": "Benutzerdefinierte Karte",
    "Customize": "Anpassen",
    "Customize this card by selecting one of the options below": "Passen Sie diese Karte an, indem Sie eine der unten stehenden Optionen auswählen",
    "Daily Transactions": "Tägliche Transaktionen",
    "Dark": "Dunkel",
    "Dark Mode": "Dunkler Modus",
    "Data": "Daten",
    "Data Security": "Datensicherheit",
    "Date for the snapshot": "Datum für die Momentaufnahme",
    "Decode input data": "Eingabedaten entschlüsseln",
    "Details": "Einzelheiten",
    "Development": "Entwicklung",
    "Dim": "Dämpfen",
    "Directory": "Verzeichnis",
    "Display All Chains": "Alle Chains anzeigen",
    "Displaying current Value; Click to show Value on Day of Txn": "Anzeige des aktuellen Werts; Klicken Sie, um den Wert am Tag der Transaktion anzuzeigen",
    "Donations": "Spenden",
    "Drag & drop files here, or click to select files": "Dateien hierher ziehen und ablegen oder klicken, um Dateien auszuwählen",
    "Download": "Herunterladen",
    "Downloaded": "Heruntergeladen",
    "EVM Version": "EVM-Version",
    "Encode": "Codieren",
    "Encoded": "Codiert",
    "Encoding": "Codierung",
    "Enter Source Code...": "Quellcode eingeben...",
    "Enter name here...": "Name hier eingeben...",
    "Ensure that token information is submitted. Check our token update guidelines": "Stellen Sie sicher, dass Token-Informationen eingereicht werden. Überprüfen Sie unsere Richtlinien für Token-Updates",
    "Event Logs": "Ereignisprotokolle",
    "Events": "Ereignisse",
    "Ex. John Doe": "Zum Beispiel John Doe",
    "Example questions": "Beispiel Fragen",
    "Explorer-as-a-Service": "Explorer-als-Service",
    "Export": "Exportieren",
    "Extra Data": "Zusätzliche Daten",
    "Extra lunch money provided along with the occasional team lunch and dinners.": "Zusätzliches Mittagessen Geld zusammen mit gelegentlichen Team Mittagessen und Abendessen bereitgestellt.",
    "FAQ": "FAQ",
    "Failed": "Gescheitert",
    "Fast": "Schnell",
    "Fee": "Gebühr",
    "Fee Recipient": "Gebührenempfänger",
    "Feedback Form": "Feedback-Formular",
    "File": "Datei",
    "Files": "Dateien",
    "Filter": "Filter",
    "Filters": "Filter",
    "Filtered": "Gefiltert",
    "Finalized": "Abgeschlossen",
    "Flex Benefits": "Flexible Vorteile",
    "Flexible working hours": "Flexible Arbeitszeiten",
    "Food Allowance": "Essenszulage",
    "For": "Für",
    "For almost anything that improves your life - training courses, fitness plans... even beauty facials!": "Für fast alles, was Ihr Leben verbessert - Schulungskurse, Fitnesspläne... sogar Schönheitsbehandlungen!",
    "For best practices, all contract source code holders, publishers and authors are encouraged to also specify the accompanying license for their verified contract source code provided.": "Für bewährte Verfahren werden alle Inhaber, Herausgeber und Autoren von Vertragsquellcodes auch dazu ermutigt, die begleitende Lizenz für ihren verifizierten Vertragsquellcode anzugeben.",
    "For each block, the block producer is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block.": "Für jeden Block wird der Blockproduzent zusätzlich zu den für alle Transaktionen im Block gezahlten Gebühren mit einer begrenzten Menge Ether belohnt.",
    "Forums": "Foren",
    "Free Flow Drinks & Snacks": "Freier Fluss von Getränken & Snacks",
    "From": "Von",
    "Fully Diluted Market Cap": "Vollständige verwässerte Marktkapitalisierung",
    "Functionality": "Funktionalität",
    "Functions": "Funktionen",
    "Gas": "Gas",
    "Gas Fees": "Gasgebühren",
    "Gas Limit": "Gaslimit",
    "Gas Limit & Usage by Txn": "Gaslimit & Verwendung pro Transaktion",
    "Gas Price": "Gaspreis",
    "Gas Target": "Gasziel",
    "Gas Tracker": "Gas-Tracker",
    "Gas Used": "Verwendetes Gas",
    "Gas price history": "Gaspreisverlauf",
    "Gas tracker": "Gas-Tracker",
    "Gas tracker overview": "Übersicht über den Gas-Tracker",
    "General Inquiry": "Allgemeine Anfrage",
    "Github": "Github",
    "Get Started": "Loslegen",
    "Help & Support": "Hilfe & Support",
    "Helpdesk Ticket Number": "Hilfeschreibticketnummer",
    "Hex": "Hex",
    "Hide All Chains": "Alle Chains ausblenden",
    "Hide Chains": "Chains ausblenden",
    "Highlighted events of the transaction.": "Hervorgehobene Ereignisse der Transaktion.",
    "Historical Balance at a specific Block No or Date.": "Historisches Gleichgewicht an einem bestimmten Block Nr. oder Datum.",
    "Home": "Zuhause",
    "How We Use Your Information": "Wie wir Ihre Informationen verwenden",
    "How to Start": "Wie man anfängt",
    "I can help you in the following scenarios": "Ich kann Ihnen in den folgenden Szenarien helfen",
    "I don't have an API Key": "Ich habe keinen API-Schlüssel",
    "I have an API Key": "Ich habe einen API-Schlüssel",
    "ID": "ID",
    "If using the Solidity compiler, run solc —version to check.": "Wenn Sie den Solidity-Compiler verwenden, führen Sie solc --version aus, um zu überprüfen.",
    "If you have any additional notes, please leave them here": "Wenn Sie zusätzliche Anmerkungen haben, hinterlassen Sie diese bitte hier",
    "Implementation": "Implementierung",
    "In addition to expediting token updates, we also provide priority support for other urgent or technical matters, such as contract verification.": "Neben der Beschleunigung von Token-Updates bieten wir auch Prioritätssupport für andere dringende oder technische Angelegenheiten wie Vertragsüberprüfungen.",
    "Indexed": "Indiziert",
    "Information We Collect": "Informationen, die wir sammeln",
    "Input Data": "Eingabedaten",
    "Instagram": "Instagram",
    "Internal Transactions": "Interne Transaktionen",
    "Internal transaction is transactions as a result of contract execution that involves Ether value.": "Interne Transaktionen sind Transaktionen, die als Ergebnis der Vertragsausführung Ether-Werte involvieren.",
    "Is Yul Contract": "Ist Yul-Vertrag",
    "Item": "Artikel",
    "It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you and for periodically visiting our website and this privacy policy to check for any changes.": "Es ist unsere Politik, alle Änderungen, die wir an unserer Datenschutzrichtlinie vornehmen, auf dieser Seite zu veröffentlichen. Das Datum, an dem die Datenschutzrichtlinie zuletzt überarbeitet wurde, wird oben auf der Seite identifiziert. Sie sind dafür verantwortlich, sicherzustellen, dass wir eine aktuelle, aktive und zustellbare E-Mail-Adresse für Sie haben, und regelmäßig unsere Website und diese Datenschutzrichtlinie besuchen, um Änderungen zu überprüfen.",
    "Join BlocksScan and enhance the world's leading blockchain explorer and search, API & analytics platform.": "Treten Sie BlocksScan bei und verbessern Sie den weltweit führenden Blockchain-Explorer und Such-, API- und Analyseplattform.",
    "Keep up with the latest breakthroughs and best practices in the industry with learned and experienced teammates.": "Halten Sie mit erfahrenen und erfahrenen Teamkollegen Schritt mit den neuesten Durchbrüchen und besten Praktiken in der Branche.",
    "Kindly follow the simple steps below to get your support prioritized.": "Bitte befolgen Sie die einfachen Schritte unten, um Ihren Support priorisiert zu bekommen.",
    "Kindly reach out to your wallet service provider, exchanges or project/contract owner for further support as they are in a better position to assist you on the issues related to and from their platforms.": "Bitte wenden Sie sich an Ihren Wallet-Service-Anbieter, Börsen oder Projekt-/Vertragsinhaber für weiteren Support, da sie sich in einer besseren Position befinden, um Ihnen bei Problemen zu helfen, die mit ihren Plattformen zusammenhängen.",
    "Knowledge Base": "Wissensdatenbank",
    "Last Sale": "Letzter Verkauf",
    "Last Traded": "Zuletzt gehandelt",
    "Last Updated": "Zuletzt aktualisiert",
    "Last updated": "Zuletzt aktualisiert",
    "Latest Block": "Neuester Block",
    "Latest Blocks": "Neueste Blöcke",
    "Latest Mints": "Neueste Mints",
    "Latest Signed Block": "Letzter signierter Block",
    "Latest Transaction": "Neueste Transaktion",
    "Latest Transactions": "Neueste Transaktionen",
    "Latest Trades": "Letzte Trades",
    "Latest Transfers": "Neueste Überweisungen",
    "Learn more about": "Erfahren Sie mehr über",
    "Learning Sessions": "Lernsitzungen",
    "Legacy": "Erbe",
    "License": "Lizenz",
    "Light": "Licht",
    "List of": "Liste von",
    "List of {{_tokenType}}20 tokens transferred in the transaction.": "Liste der {{_tokenType}}20-Token, die in der Transaktion übertragen wurden.",
    "List of tokens burnt transferred in the transaction.": "Liste der verbrannten Token, die in der Transaktion übertragen wurden.",
    "List of tokens mint transferred in the transaction.": "Liste der geprägten Token, die in der Transaktion übertragen wurden.",
    "Load": "Laden",
    "Loading": "Wird geladen",
    "Loading...": "Wird geladen...",
    "Logdata to clipboard": "Protokolldaten in die Zwischenablage",
    "Logs": "Protokolle",
    "Look up": "Suche",
    "Lookup": "Suche",
    "Low load": "Niedrige Belastung",
    "MED GAS PRICE": "MED GAS PREIS",
    "Main Interfaces (.vy or .json)": "Haupt-Schnittstellen (.vy oder .json)",
    "Market": "Markt",
    "Market Cap": "Marktkapitalisierung",
    "Market Capitalization": "Marktkapitalisierung",
    "Marketplaces": "Marktplätze",
    "Marketplaces trading this NFT.": "Marktplätze, die dieses NFT handeln.",
    "Max": "Max",
    "Max Priority": "Maximale Priorität",
    "Max Price": "Maximaler Preis",
    "Max Total Supply": "Maximale Gesamtversorgung",
    "Media & Press": "Medien & Presse",
    "Medical Benefits": "Medizinische Vorteile",
    "Method ID": "Methoden-ID",
    "Method Name": "Methodenname",
    "Method": "Methode",
    "Method id": "Methoden-ID",
    "Metadata": "Metadaten",
    "Min Price": "Minimaler Preis",
    "Missing": "Fehlt",
    "Mode": "Modus",
    "More Details": "Mehr Details",
    "More Info": "Weitere Informationen",
    "Multichain Info": "Multichain-Info",
    "Multichain Portfolio": "Multichain-Portfolio",
    "Name": "Name",
    "Name Tag": "Namensschild",
    "NEVER": "Nie",
    "NEVER send us these.": "SENDEN SIE UNS DAS NIE.",
    "NEW": "Neu",
    "NFT Address": "NFT-Adresse",
    "NFT Latest Mints": "Neueste NFT-Prägungen",
    "NFT Top Mints": "Top NFT-Prägungen",
    "NFT Transfer": "NFT-Übertragung",
    "NFT Transfers": "NFT-Übertragungen",
    "NFT's unique token ID.": "Einzigartige Token-ID des NFT.",
    "NFT": "NFT",
    "Node": "Knoten",
    "Nonce": "Nonce",
    "Non-Personal Information: Data that does not personally identify you but is linked to your usage of our services, such as IP addresses, browser types, and access times.": "Nicht persönliche Informationen: Daten, die Sie nicht persönlich identifizieren, aber mit Ihrer Nutzung unserer Dienste verbunden sind, wie IP-Adressen, Browsertypen und Zugriffszeiten.",
    "Normal": "Normal",
    "Note: Filtered by  totalSupply() , method returned a zero value.": "Hinweis: Gefiltert nach totalSupply(), Methode hat einen Wert von Null zurückgegeben.",
    "Note": "Hinweis",
    "NOT": "Nicht",
    "No Record Found": "Kein Eintrag gefunden",
    "No transactions found.": "Keine Transaktionen gefunden.",
    "Number": "Nummer",
    "Number of beacon withdrawals in this block.": "Anzahl der Beacon-Abhebungen in diesem Block.",
    "Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was produced.": "Nummer des Blocks, in dem die Transaktion aufgezeichnet ist. Blockbestätigungen zeigen an, wie viele Blöcke seit der Erstellung der Transaktion hinzugefügt wurden.",
    "OAuth": "OAuth",
    "Office Entertainment": "Büro-Unterhaltung",
    "Onchain Market Cap": "Marktkapitalisierung Onchain",
    "OpenAI API key": "OpenAI API-Schlüssel",
    "Optimization": "Optimierung",
    "Optimization Enabled": "Optimierung aktiviert",
    "Optimization Runs": "Optimierungsläufe",
    "Order": "Reihenfolge",
    "Others": "Andere",
    "Ours isn’t just a grand vision. We back it up with the company and culture tailored for realizing it.": "Unser ist nicht nur eine große Vision. Wir unterstützen sie mit dem Unternehmen und der Kultur, die darauf ausgerichtet sind, sie zu verwirklichen.",
    "Our team will review your request and contact you using an official": "Unser Team wird Ihre Anfrage überprüfen und Sie unter Verwendung einer offiziellen",
    "Overview": "Übersicht",
    "Owner": "Besitzer",
    "Owners": "Besitzer",
    "Page": "Seite",
    "Parent Hash": "Übergeordneter Hash",
    "Pending Transaction": "Ausstehende Transaktion",
    "Pending Transactions": "Ausstehende Transaktionen",
    "Pending...": "Ausstehend...",
    "Percentage": "Prozentsatz",
    "Perks and Benefits": "Vergünstigungen und Vorteile",
    "Personal Information: Data that personally identifies you, such as your name, email address, and payment details, provided at registration or during the use of our services.": "Persönliche Informationen: Daten, die Sie persönlich identifizieren, wie Ihr Name, Ihre E-Mail-Adresse und Zahlungsdetails, die bei der Registrierung oder während der Nutzung unserer Dienste angegeben wurden.",
    "Phone": "Telefon",
    "Platform": "Plattform",
    "Please Select Your Message Subject": "Bitte wählen Sie Ihr Nachrichten-Thema aus",
    "Please understand that we can only assist with issues directly related to the block explorer services. Before writing in, please be informed that": "Bitte haben Sie Verständnis dafür, dass wir nur bei Problemen helfen können, die direkt mit den Block-Explorer-Diensten zusammenhängen. Bitte informieren Sie sich, bevor Sie schreiben, dass",
    "Please use this form to get in touch for priority support or featured listing with our team.": "Bitte verwenden Sie dieses Formular, um sich für prioritäre Unterstützung oder eine hervorgehobene Auflistung mit unserem Team in Verbindung zu setzen.",
    "Position": "Position",
    "Post-London Upgrade, this represents the minimum gas Used multiplier required for a tx to be included in a block.": "Nach dem London-Upgrade stellt dies den minimalen Gas-Verbrauchs-Multiplikator dar, der für eine Transaktion erforderlich ist, um in einem Block enthalten zu sein.",
    "Post-London Upgrade, this represents the part of the tx fee that is burnt: baseFeePerGas * gasUsed.": "Nach dem London-Upgrade stellt dies den Teil der Transaktionsgebühr dar, der verbrannt wird: baseFeePerGas * gasUsed.",
    "Power": "Leistung",
    "Powered by BlocksScan": `Betrieben von ${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"}`,
    "BlocksScan is a Block Explorer and Analytics Platform for": `${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"} ist ein Block-Explorer und Analytics-Plattform für`,
    "Present": "Anwesend",
    "Preserve": "Erhalten",
    "Press": "Drücken",
    "Price": "Preis",
    "Primary compiled Vyper contract. Only add the main contract here whose bytecode has been deployed, all other files can be uploaded to the interfaces box below": "Primär kompilierter Vyper-Vertrag. Fügen Sie hier nur den Hauptvertrag hinzu, dessen Bytecode bereitgestellt wurde. Alle anderen Dateien können im folgenden Interfaces-Feld hochgeladen werden.",
    "Privacy": "Privatsphäre",
    "Privacy Policy": "Datenschutzrichtlinie",
    "Private Name Tags": "Private Namens-Tags",
    "Processing": "Verarbeitung",
    "Products": "Produkte",
    "Profile": "Profil",
    "Programs": "Programme",
    "Project": "Projekt",
    "Properties": "Eigenschaften",
    "Proposed On": "Vorgeschlagen am",
    "Proxy": "Proxy",
    "Publish": "Veröffentlichen",
    "Published": "Veröffentlicht",
    "Quantity": "Menge",
    "Rank": "Rang",
    "Read": "Lesen",
    "Read Contract": "Vertrag lesen",
    "Read Proxy": "Proxy lesen",
    "Real-time": "Echtzeit",
    "Receipt": "Quittung",
    "Recent": "Kürzlich",
    "Record": "Aufzeichnung",
    "Recover": "Wiederherstellen",
    "Recovery": "Wiederherstellung",
    "Recycle": "Recyceln",
    "Redo": "Wiederholen",
    "Refresh": "Aktualisieren",
    "Register": "Registrieren",
    "Registration": "Registrierung",
    "Related": "Verwandt",
    "Release": "Freigeben",
    "Reload": "Neu laden",
    "Removal": "Entfernung",
    "Remove": "Entfernen",
    "Removed": "Entfernt",
    "Removing": "Entfernen",
    "Renew": "Erneuern",
    "Replace": "Ersetzen",
    "Request Removal of Name Tag": "Anfrage zur Entfernung des Namensetiketts",
    "Request": "Anfrage",
    "Requester Email": "E-Mail des Antragstellers",
    "Requester Name": "Name des Antragstellers",
    "Requests": "Anfragen",
    "Resend": "Erneut senden",
    "Reset": "Zurücksetzen",
    "Resources": "Ressourcen",
    "Response": "Antwort",
    "Responses": "Antworten",
    "Restore": "Wiederherstellen",
    "Restrict": "Einschränken",
    "Result": "Ergebnis",
    "Results": "Ergebnisse",
    "Resume": "Fortsetzen",
    "Retain": "Behalten",
    "Retrieve": "Abrufen",
    "Return": "Zurückkehren",
    "Returned": "Zurückgegeben",
    "Returning": "Rückkehr",
    "Review": "Überprüfung",
    "Rewards": "Belohnungen",
    "Rewrite": "Neu schreiben",
    "Rich": "Reich",
    "Right": "Richtig",
    "Rights": "Rechte",
    "Role": "Rolle",
    "Roles": "Rollen",
    "Rollback": "Rückgängig machen",
    "Root": "Wurzel",
    "Route": "Route",
    "Routing": "Routing",
    "Row": "Reihe",
    "Rules": "Regeln",
    "Run": "Ausführen",
    "Running": "Laufen",
    "Sales": "Verkäufe",
    "Save Changes": "Änderungen speichern",
    "Saved Filters": "Gespeicherte Filter",
    "Saving": "Speichern",
    "Scenario": "Szenario",
    "Schemas": "Schemata",
    "Schedule": "Zeitplan",
    "Scheduled": "Geplant",
    "Scheduling": "Planung",
    "Schema": "Schema",
    "Scope": "Umfang",
    "Screen": "Bildschirm",
    "Scripts": "Skripte",
    "Search": "Suche",
    "Ask AI | Search by Address | Txn Hash | Block | Token... ": "Suche nach Adresse / Transaktionshash / Block / Token...",
    "Searches": "Suchen",
    "Searching": "Suchen",
    "Second": "Zweite",
    "Seconds": "Sekunden",
    "Sec": "Sek",
    "Secret": "Geheimnis",
    "Section": "Abschnitt",
    "Secure": "Sicher",
    "Securing": "Sichern",
    "Security": "Sicherheit",
    "Security Audit": "Sicherheitsprüfung",
    "See more details": "Weitere Details anzeigen",
    "See preview of the transaction details.": "Vorschau der Transaktionsdetails anzeigen.",
    "Select": "Auswählen",
    "Selected": "Ausgewählt",
    "Selection": "Auswahl",
    "Send": "Senden",
    "Send Message": "Nachricht senden",
    "Sender": "Absender",
    "Sending": "Senden",
    "Send native": "Senden von nativen",
    "Sequence": "Sequenz",
    "Server": "Server",
    "Service": "Dienst",
    "Services": "Dienstleistungen",
    "Session": "Sitzung",
    "Sessions": "Sitzungen",
    "Set": "Setzen",
    "Setting": "Einstellung",
    "Settings": "Einstellungen",
    "Setup": "Einrichtung",
    "Share": "Teilen",
    "Shared": "Geteilt",
    "Sharing": "Teilen",
    "Show": "Zeigen",
    "Show More Chains": "Mehr Ketten anzeigen",
    "Show less": "Weniger anzeigen",
    "Show more": "Mehr anzeigen",
    "Showing": "Anzeigen",
    "Signer": "Unterzeichner",
    "Similar Contract Search": "Ähnliche Vertragssuche",
    "Simple steps for getting Priority Support.": "Einfache Schritte für den Erhalt von Prioritätsunterstützung.",
    "Size": "Größe",
    "Slow": "Langsam",
    "Slot and epoch this block is proposed on.": "Slot und Epoche, auf die dieser Block vorgeschlagen wird.",
    "Smart Contract": "Smartvertrag",
    "Smart Contract Search": "Smart-Vertragssuche",
    "Smart Contracts": "Smartverträge",
    "Snapshot": "Momentaufnahme",
    "Snapshots": "Momentaufnahmen",
    "Social": "Sozial",
    "Software": "Software",
    "Solidity": "Solidität",
    "Solution": "Lösung",
    "Solutions": "Lösungen",
    "Source Code": "Quellcode",
    "Sources *.sol or *.yul files": "Quellen *.sol oder *.yul Dateien",
    "Sources and Metadata JSON": "Quellen und Metadaten JSON",
    "Span": "Spanne",
    "Special": "Besondere",
    "Specific": "Spezifisch",
    "Specification": "Spezifikation",
    "Specifications": "Spezifikationen",
    "Specified": "Spezifiziert",
    "Specify": "Angeben",
    "Specs": "Spezifikationen",
    "Sponsor": "Sponsor",
    "Sponsored": "Gefördert",
    "Sponsoring": "Förderung",
    "Sponsors": "Sponsoren",
    "Spotlight": "Spotlight",
    "Stable": "Stabil",
    "Staging": "Inszenierung",
    "Standard JSON Input": "Standard-JSON-Eingabe",
    "Start": "Anfang",
    "Starting": "Start",
    "State": "Zustand",
    "State Difference": "Zustandsunterschied",
    "State Root": "Zustandswurzel",
    "State of the art coffee machine and experienced baristas in the house for the coffee lovers to thrive.": "State-of-the-art-Kaffeemaschine und erfahrene Baristas im Haus, damit die Kaffeeliebhaber gedeihen können.",
    "Statistics": "Statistiken",
    "Status": "Status",
    "Step 1": "Schritt 1",
    "Step 2": "Schritt 2",
    "Step 3": "Schritt 3",
    "Steps": "Schritte",
    "Storage Of Assets": "Speicherung von Vermögenswerten",
    "Store": "Speichern",
    "Stored": "Gespeichert",
    "Storing": "Speichern",
    "Stream": "Strom",
    "Streaming": "Streaming",
    "Streams": "Ströme",
    "Strength": "Stärke",
    "String": "String",
    "Strings": "Strings",
    "Structure": "Struktur",
    "Structures": "Strukturen",
    "Style": "Stil",
    "Styles": "Stile",
    "Submit": "Einreichen",
    "Submitted": "Eingereicht",
    "Submitting": "Einreichen",
    "Subscription": "Abonnement",
    "Subscriptions": "Abonnements",
    "Subsequent": "Nachfolgend",
    "Success": "Erfolg",
    "Successful": "Erfolgreich",
    "Successfully": "Erfolgreich",
    "Suggest Transaction Action": "Transaktionsaktion vorschlagen",
    "Suggestions": "Vorschläge",
    "Summary": "Zusammenfassung",
    "Support": "Unterstützung",
    "Supported": "Unterstützt",
    "Supporting": "Unterstützung",
    "Supports": "Unterstützt",
    "Supply": "Versorgung",
    "Surface": "Oberfläche",
    "Survey": "Umfrage",
    "Surveys": "Umfragen",
    "Suspend": "Aussetzen",
    "Suspended": "Ausgesetzt",
    "Suspending": "Aussetzen",
    "Swap": "Tauschen",
    "Swapping": "Tauschen",
    "Switch": "Wechseln",
    "Switching": "Wechseln",
    "Symbol": "Symbol",
    "Symbols": "Symbole",
    "Sync": "Synchronisieren",
    "Synchronize": "Synchronisieren",
    "Synchronized": "Synchronisiert",
    "Synchronizing": "Synchronisieren",
    "System": "System",
    "Systems": "Systeme",
    "TV": "Fernsehen",
    "Table": "Tabelle",
    "Tables": "Tabellen",
    "Tag": "Etikett",
    "Tags": "Tags",
    "Take": "Nehmen",
    "Taken": "Genommen",
    "Taking": "Nehmen",
    "Target": "Ziel",
    "Targets": "Ziele",
    "Task": "Aufgabe",
    "Tasks": "Aufgaben",
    "Team": "Team",
    "Teams": "Teams",
    "Technical": "Technisch",
    "Technique": "Technik",
    "Techniques": "Techniken",
    "Technology": "Technologie",
    "Template": "Vorlage",
    "Templates": "Vorlagen",
    "Temporary": "Vorübergehend",
    "Terminate": "Beenden",
    "Terminated": "Beendet",
    "Terminating": "Beenden",
    "Terms": "Bedingungen",
    "Test": "Test",
    "Tested": "Getestet",
    "Testing": "Testen",
    "Tests": "Tests",
    "Text": "Text",
    "Theme": "Thema",
    "Themes": "Themen",
    "Therapy": "Therapie",
    "This block is finalized.": "Dieser Block ist abgeschlossen.",
    "This block is finilized.": "Dieser Block ist abgeschlossen.",
    "This page allows you to customize your browsing experience on Blocksscan.": "Diese Seite ermöglicht es Ihnen, Ihr Browsererlebnis auf Blocksscan anzupassen.",
    "This NFT's unique token ID.": "Die eindeutige Token-ID dieses NFTs.",
    "Thread": "Thread",
    "Threads": "Threads",
    "Threshold": "Schwelle",
    "Thresholds": "Schwellen",
    "Ticket": "Ticket",
    "Tickets": "Tickets",
    "Time": "Zeit",
    "Timestamp": "Zeitstempel",
    "Title": "Titel",
    "Titles": "Titel",
    "To": "An",
    "Token Contract": "Token-Vertrag",
    "Token Contract Address": "Token-Vertragsadresse",
    "Token Creation": "Token-Erstellung",
    "Token Holding": "Token-Haltung",
    "Token ID": "Token-ID",
    "Token Inventory": "Token-Inventar",
    "Token Name": "Token-Name",
    "Token Standard": "Token-Standard",
    "Token Transactions": "Token-Transaktionen",
    "Token Transfers": "Token-Überweisungen",
    "Token Update": "Token-Update",
    "Token creation": "Token-Erstellung",
    "Token transfer": "Token-Übertragung",
    "Tokens": "Token",
    "Tokens Transferred": "Übertragene Token",
    "Tokens burnt": "Verbrannte Token",
    "Tokens minted": "Geprägte Token",
    "Tokens transferred in the transaction.": "Tokens übertragen in der Transaktion.",
    "ToDo": "Aufgabe",
    "To clipboard": "In Zwischenablage kopieren",
    "Toggle": "Umschalten",
    "Toggled": "Umschalten",
    "Toggling": "Umschalten",
    "Tool": "Werkzeug",
    "Tools": "Werkzeuge",
    "Top Accounts": "Top-Konten",
    "Top Contract": "Top-Vertrag",
    "Top Contracts": "Top-Verträge",
    "Top Mints": "Top-Prägungen",
    "Top NFT": "Top-NFT",
    "Top NFTs": "Top-NFTs",
    "Top Statistics": "Top-Statistiken",
    "Top Token": "Top-Token",
    "Top Tokens": "Top-Token",
    "Topic": "Thema",
    "Topics": "Themen",
    "Total": "Gesamt",
    "Total Accounts": "Gesamtkonten",
    "Total Assets": "Gesamtvermögen",
    "Total Difficulty": "Gesamtschwierigkeit der Kette bis zu diesem Block.",
    "Total Holders": "Gesamtinhaber",
    "Total Transfers": "Gesamtüberweisungen",
    "Total amount of ETH burnt from this tx & total fees saved from the amount the user was willing to pay for this tx.": "Gesamtmenge an ETH, die von dieser Tx verbrannt wurde, & Gesamtgebühren, die aus dem Betrag gespart wurden, den der Benutzer für diese Tx zu zahlen bereit war.",
    "Total gas limit provided by all transactions in the block.": "Gesamtes Gaslimit, das von allen Transaktionen im Block bereitgestellt wurde.",
    "Total gas used in the block and its percentage of gas filled in the block.": "Insgesamt im Block verwendetes Gas und sein Prozentsatz des im Block gefüllten Gases.",
    "Total transactions": "Gesamttransaktionen",
    "Trace": "Spur",
    "Traces": "Spuren",
    "Tracking": "Verfolgung",
    "Trade": "Handel",
    "Trades": "Handel",
    "Trading": "Handel",
    "Transaction": "Transaktion",
    "Transaction Action": "Transaktionsaktion",
    "Transaction Details": "Transaktionsdetails",
    "Transaction Fee": "Transaktionsgebühr",
    "Transaction Hash": "Transaktions-Hash",
    "Transaction Type": "Transaktionstyp",
    "Transactions": "Transaktionen",
    "Transactions Internal": "Interne Transaktionen",
    "Transcript": "Transkript",
    "Transcripts": "Transkripte",
    "Transfer": "Übertragung",
    "Transferred": "Übertragen",
    "Transferring": "Übertragen",
    "Transfers": "Übertragungen",
    "Translate": "Übersetzen",
    "Translated": "Übersetzt",
    "Translating": "Übersetzen",
    "Translation": "Übersetzung",
    "Translations": "Übersetzungen",
    "Trending": "Trend",
    "Trends": "Trends",
    "Trigger": "Auslöser",
    "Triggered": "Ausgelöst",
    "Triggering": "Auslösen",
    "Triggers": "Auslöser",
    "Troubleshoot": "Fehlerbehebung",
    "Troubleshooting": "Fehlerbehebung",
    "Trust": "Vertrauen",
    "Trusted": "Vertrauenswürdig",
    "Trusting": "Vertrauen",
    "Try": "Versuchen",
    "Trying": "Versuchen",
    "Tx Fee": "Tx-Gebühr",
    "Tx Hash": "Tx-Hash",
    "Tx Hash|Block|Age": "Tx-Hash|Block|Alter",
    "Tx row trace to clipboard": "Tx-Reihenverfolgung in die Zwischenablage kopieren",
    "Tx": "Tx",
    "Txn": "Txn",
    "Txn Savings": "Txn Einsparungen",
    "Txn Type": "Txn Typ",
    "Txns": "Txns",
    "Type": "Typ",
    "Type here ...": "Hier eingeben...",
    "Type of Backing": "Art der Unterstützung",
    "Type of Peg": "Art des Peg",
    "Types": "Typen",
    "UI": "Benutzeroberfläche",
    "URL": "URL",
    "URLs": "URLs",
    "Undo": "Rückgängig machen",
    "Undone": "Rückgängig gemacht",
    "Unfinished": "Unvollendet",
    "Unit": "Einheit",
    "Units": "Einheiten",
    "Universe": "Universum",
    "Unknown": "Unbekannt",
    "Unlink": "Verknüpfung aufheben",
    "Unlinked": "Verknüpft",
    "Unlinking": "Verknüpfen",
    "Unlock": "Entsperren",
    "Unlocked": "Entsperrt",
    "Unlocking": "Entsperren",
    "Unread": "Ungelesen",
    "Unresolved": "Ungeklärt",
    "Unseen": "Ungesehen",
    "Unsubscribe": "Abbestellen",
    "Unsubscribed": "Abbestellt",
    "Unsubscribing": "Abbestellen",
    "Untagged": "Unbeschriftet",
    "Untagging": "Entfernen von Tags",
    "Update": "Aktualisieren",
    "Update Proxy Contract's Implementation Address": "Implementierungsadresse des Proxy-Vertrags aktualisieren",
    "Update Token Info": "Token-Info aktualisieren",
    "Updated": "Aktualisiert",
    "Updating": "Aktualisieren",
    "Upgrade": "Upgrade",
    "Upgraded": "Aktualisiert",
    "Upgrading": "Aktualisieren",
    "Upload": "Hochladen",
    "Upload all Solidity contract source files and JSON metadata file(s) created during contract compilation.": "Alle Solidity-Vertragsquellcodedateien und JSON-Metadatendateien, die während der Vertragserstellung erstellt wurden, hochladen.",
    "Upload all Solidity or Yul contract source files": "Alle Solidity- oder Yul-Vertragsquellcodedateien hochladen",
    "Upload the standard input JSON file created during contract compilation": "Die während der Vertragserstellung erstellte Standard-Eingabe-JSON-Datei hochladen",
    "Uploaded": "Hochgeladen",
    "Uploading": "Hochladen",
    "Uptime": "Betriebszeit",
    "Us": "Uns",
    "Usage": "Verwendung",
    "Use": "Verwenden",
    "Used": "Verwendet",
    "User": "Benutzer",
    "Users": "Benutzer",
    "Using": "Verwenden",
    "Utilities": "Dienstprogramme",
    "Utility": "Dienstprogramm",
    "Validate": "Validieren",
    "Validated": "Validiert",
    "Validating": "Validierung",
    "Validation": "Validierung",
    "Value": "Wert",
    "Values": "Werte",
    "Variable": "Variable",
    "Variables": "Variablen",
    "Variant": "Variante",
    "Variants": "Varianten",
    "Varying": "Variierend",
    "Vault": "Tresor",
    "Vaults": "Tresore",
    "Vector": "Vektor",
    "Vectors": "Vektoren",
    "Verification": "Verifizierung",
    "Verification Method": "Verifizierungsmethode",
    "Verified": "Verifiziert",
    "Verified At": "Verifiziert um",
    "Verified Contracts": "Verifizierte Verträge",
    "Verified Smart Contract": "Verifizierter Smart Contract",
    "Verifying": "Verifizierung",
    "Version": "Version",
    "Versions": "Versionen",
    "Verify & Publish": "Überprüfen und Veröffentlichen",
    "Verify And Publish": "Überprüfen und Veröffentlichen",
    "Verify Contract": "Vertrag überprüfen",
    "View": "Anzeigen",
    "View Blocks": "Blöcke anzeigen",
    "Views": "Ansichten",
    "Viewing": "Anzeigen",
    "Visible": "Sichtbar",
    "Visit": "Besuchen",
    "Visiting": "Besuchen",
    "Visitor": "Besucher",
    "Visitors": "Besucher",
    "Visual": "Visuell",
    "Visuals": "Visuelle",
    "Volume": "Volumen",
    "Volume 24h": "Volumen 24h",
    "Volumes": "Volumina",
    "Vote": "Abstimmen",
    "Voted": "Abgestimmt",
    "Voter": "Wähler",
    "Voters": "Wähler",
    "Voting": "Abstimmung",
    "Votes": "Stimmen",
    "Vyper Online Compiler": "Vyper Online-Compiler",
    "WAITING": "WARTEN",
    "Wallet": "Brieftasche",
    "Wallet / Exchange / Project related issues": "Brieftasche / Börse / Projektbezogene Probleme",
    "Wallets": "Brieftaschen",
    "Warning": "Warnung",
    "Warnings": "Warnungen",
    "Watch": "Beobachten",
    "Watching": "Beobachten",
    "Web": "Web",
    "Webpage": "Webseite",
    "Webpages": "Webseiten",
    "Website": "Webseite",
    "Websites": "Webseiten",
    "Welcome": "Willkommen",
    "Welcome to Blocksscan Explorer Services": `Willkommen bei den ${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"} Explorer-Diensten`,
    "Wellness": "Wohlbefinden",
    "We're Hiring": "Wir stellen ein",
    "What are the functions or methods available in this contract?": "Welche Funktionen oder Methoden stehen in diesem Vertrag zur Verfügung?",
    "What does this contract do?": "Was macht dieser Vertrag?",
    "What is the logical structure of this contract's code?": "Was ist die logische Struktur des Codes dieses Vertrags?",
    "Where to find my API Key?": "Wo finde ich meinen API-Schlüssel?",
    "While we try our best to respond in a timely manner, we often have a backlog of inquiries. Please do not": "Obwohl wir unser Bestes tun, um zeitnah zu antworten, haben wir oft einen Rückstand an Anfragen. Bitte nicht",
    "White": "Weiß",
    "Who": "Wer",
    "Whole": "Ganz",
    "Wholesale": "Großhandel",
    "Widget": "Widget",
    "Widgets": "Widgets",
    "Width": "Breite",
    "Widths": "Breiten",
    "Wiki": "Wiki",
    "Wikis": "Wikis",
    "Willing": "Bereit",
    "Win": "Gewinnen",
    "Window": "Fenster",
    "Windows": "Fenster",
    "Winning": "Gewinnen",
    "Wins": "Gewinne",
    "Wire": "Draht",
    "Wires": "Drähte",
    "Wiring": "Verdrahtung",
    "Wisdom": "Weisheit",
    "Wish": "Wunsch",
    "Wished": "Gewünscht",
    "Wishes": "Wünsche",
    "Wishing": "Wünschen",
    "Withdraw": "Abheben",
    "Withdrawal": "Abhebung",
    "Withdrawals": "Abhebungen",
    "WithdrawalsRoot": "Abhebungsstamm",
    "Withdrawing": "Abheben",
    "Withdrawn": "Abgehoben",
    "With": "Mit",
    "Within": "Innerhalb",
    "Without": "Ohne",
    "Witness": "Zeuge",
    "Witnessed": "Bezeugt",
    "Witnesses": "Zeugen",
    "Witnessing": "Bezeugen",
    "Work": "Arbeit",
    "Worked": "Gearbeitet",
    "Working": "Arbeiten",
    "Works": "Funktioniert",
    "Workspace": "Arbeitsbereich",
    "Workspaces": "Arbeitsbereiche",
    "World": "Welt",
    "Worldwide": "Weltweit",
    "Worry": "Sorge",
    "Worried": "Besorgt",
    "Worries": "Sorgen",
    "Worrying": "Sorgen machen",
    "Worth": "Wert",
    "Write": "Schreiben",
    "Write Contract": "Vertrag schreiben",
    "Write Proxy": "Proxy schreiben",
    "Write me a smart contract of voting.": "Schreiben Sie mir einen Smart Contract für Abstimmungen.",
    "Writes": "Schreibt",
    "Writing": "Schreiben",
    "Written": "Geschrieben",
    "Wrong": "Falsch",
    "Wrote": "Geschrieben",
    "X (Twitter)": "X (Twitter)",
    "XML": "XML",
    "XRay": "Röntgen",
    "Xrays": "Röntgenstrahlen",
    "Year": "Jahr",
    "Years": "Jahre",
    "Yellow": "Gelb",
    "Yes": "Ja",
    "Yesterday": "Gestern",
    "Yield": "Ertrag",
    "Yielding": "Ertragbringend",
    "Yields": "Erträge",
    "You can Lookup the Account": "Sie können das Konto nachschlagen",
    "Your browser does not support the video tag.": "Ihr Browser unterstützt das Video-Tag nicht.",
    "Your private keys with anyone.": "Ihre privaten Schlüssel mit niemandem teilen.",
    "Your Rights": "Ihre Rechte",
    "Youth": "Jugend",
    "Zone": "Zone",
    "Zones": "Zonen",
    "Zoning": "Zonierung",
    "Zoom": "Zoom",
    "Zoomed": "Gezoomt",
    "Zooming": "Zoomen",
    "Zooms": "Zooms",
    "rsync-builder in 12 secs": "rsync-Builder in 12 Sekunden",
    "rsyncbuilder": "rsync-Builder",
    "sec": "Sek.",
    "tokens with a value of": "Tokens mit einem Wert von",
    "to clipboard": "In die Zwischenablage",
    "tokens transferred in the transaction.": "Tokens wurden in der Transaktion übertragen.",
    "withdrawals in this block": "Abhebungen in diesem Block",
    "we": "Wir",
    "us": "Uns",
    "our": "Unser"
    }
}