import React, {FC, useState} from "react";
import {getFiat, toXDCNumber} from "../../common/utils/function";
import moment from "moment";
import axios from "axios";
import BTooltip from "../../components/b-tooltip";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

interface BPriceData {
    value: any;
    XDCUSD: any;
    decimal?: any;
    timestamp: any;
    coingeckoID: any;
    type: any;
}

const BPrice: FC<BPriceData> = (props) => {
    const fiat = useSelector((e: Object) => (e as any)?.common?.fiat);
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [flag, setFlag] = useState(false);
    const [oldValue, setOldValue] = useState(0);

    const changeUSDT = async () => {
        if (!check()) {
            if (flag) setFlag(false);
            else {
                if (!oldValue) {
                    if (props.coingeckoID && props.coingeckoID !== "0") {
                        setLoading(true);
                        const responses = await Promise.all([
                            axios.get(
                                "https://api.coingecko.com/api/v3/coins/" +
                                props.coingeckoID +
                                "/history?date=" +
                                getDate(props.timestamp ?? +new Date())
                            ),
                        ]);

                        if (responses[0].data.market_data) {
                            setOldValue(
                                responses[0].data.market_data.current_price?.usd || 1
                            );
                        }
                        setLoading(false);
                        setFlag(!flag);
                    }
                } else setFlag(!flag);
            }
        }
    };
    const check = () =>
        props.value === 0 ||
        props.value === "0" ||
        !props.coingeckoID ||
        props.coingeckoID === "0" ||
        moment().diff(moment(props.timestamp), "days") === 0;
    const getDate = (date: any) => {
        const d = date ? new Date(date) : new Date();
        return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
    };
    return (
        <React.Fragment>
            {loading ? (
                <button className="xdc-btn-skeleton">
                    <span className="xdc-btn-skeleton_loading"/>
                </button>
            ) : (
                <React.Fragment>
                    {((props?.type === "token" && props?.coingeckoID) ||
                        props?.type !== "token") && (
                        <BTooltip
                            text={
                                flag
                                    ? `${t("Estimated Value on Day of Txn")}`
                                    : `${t(
                                        "Displaying current Value; Click to show Value on Day of Txn"
                                    )}`
                            }
                        >
                            <span
                                onClick={changeUSDT}
                                className={`bs-badge bs-cp ${flag ? "h-green" : "h-gray"}`}
                            >
                                {getFiat(toXDCNumber(props?.value * (flag ? oldValue : props?.XDCUSD), props?.decimal, 8), props?.decimal, fiat, true, 8)}
                            </span>
                        </BTooltip>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

BPrice.defaultProps = {
    decimal: 5,
};
export default BPrice;
