export const cn = {
  translations: {
    welcome: "欢迎",
    Settings: "设置",
    "This page allows you to customize your browsing experience on Blocksscan.":
      "此页面允许您自定义在Blocksscan上的浏览体验。",
    Address: "地址",
    Overview: "概览",
    Balance: "余额",
    Value: "价值",
    "More Info": "更多信息",
    "Private Name Tags": "私人名称标签",
    Add: "添加",
    "LAST TXN SENT": "最后发送的交易",
    from: "从",
    "FIRST TXN SENT": "首次发送的交易",
    "Multichain Info": "多链信息",
    day: "天",
    days: "天",
    hour: "小时",
    hours: "小时",
    minute: "分钟",
    minutes: "分钟",
    second: "秒",
    seconds: "秒",
    ago: "前",
    "Multichain Portfolio": "多链投资组合",
    Transactions: "交易",
    "Internal Transactions": "内部交易",
    Transfers: "转账",
    "NFT Transfers": "NFT 转账",
    "Token Holding": "代币持有",
    "Token Inventory": "代币清单",
    Events: "事件",
    Contract: "合约",
    Rewards: "奖励",
    Analytics: "分析",
    Download: "下载",
    "CSV Export": "CSV 导出",
    Tokens: "代币",
    Chains: "链",
    "Hide Chains": "隐藏链",
    "Show More Chains": "显示更多链",
    "Hide All Chains": "隐藏所有链",
    "Display All Chains": "显示所有链",
    "Name Tag": "名称标签",
    Percentage: "百分比",
    Capacity: "容量",
    Owner: "所有者",
    "Latest Signed Block": "最新已签名区块",
    Status: "状态",
    "Contract Name": "合约名称",
    Compiler: "编译器",
    Version: "版本",
    Txns: "交易数",
    Verified: "已验证",
    Audit: "审计",
    License: "许可",
    "New Smart Contracts": "新智能合约",
    "New Verified Contracts": "新已验证合约",
    "Smart Contracts": "智能合约",
    "Verified Smart Contract": "已验证智能合约",
    Optimization: "优化",
    "Constructor args": "构造器参数",
    "Verified Contracts": "已验证合约",
    Solidity: "Solidity",
    Accounts: "账户",
    "Block Height": "区块高度",
    ToDo: "待办事项",
    Finalized: "已完成",
    Timestamp: "时间戳",
    "Proposed On": "提议时间",
    "Block proposed on epoch": "提议区块所在时期",
    Withdrawals: "提取",
    "withdrawals in this block": "此区块中的提取",
    Fee: "费用",
    "Fee Recipient": "费用接收者",
    "rsync-builder in 12 secs": "12 秒内的 rsync 构建器",
    "Block Reward": "区块奖励",
    "Total Difficulty": "总难度",
    Size: "大小",
    bytes: "字节",
    "Gas Used": "已使用 Gas",
    "Gas Target": "Gas 目标",
    "Gas Limit": "Gas 限制",
    "Base Fee Per Gas": "每 Gas 基本费用",
    "Burnt Fees": "已燃尽费用",
    "Extra Data": "额外数据",
    rsyncbuilder: "rsync 构建器",
    Hash: "哈希",
    "Parent Hash": "父哈希",
    StateRoot: "状态根",
    WithdrawalsRoot: "提取根",
    Nonce: "随机数",
    "More Details": "更多细节",
    "Click to show less": "点击显示更少",
    "Click to show more": "点击显示更多",
    "Also known as Block Number. The block height, which indicates the length of the blockchain, increases after the addition of the new block.":
      "也称为区块编号。区块高度表示区块链的长度，在添加新区块后会增加。",
    "The finality status of the block.": "区块的最终状态。",
    "This block is finilized.": "此区块已完成。",
    "The date and time at which a block is produced.": "生成区块的日期和时间。",
    "Slot and epoch this block is proposed on.": "此区块提案的槽和时期。",
    "The number of transactions in the block. Internal transaction is transactions as a result of contract execution that involves Ether value.":
      "区块中的交易数量。内部交易是指由于合约执行而产生的涉及以太币价值的交易。",
    "Number of beacon withdrawals in this block.": "此区块中的信标提款数量。",
    "Address receiving fees from transactions in this block.":
      "接收此区块交易手续费的地址。",
    "For each block, the block producer is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block.":
      "对于每个区块，区块生产者除了收取区块内所有交易的费用外，还会获得一定数量的以太币作为奖励。",
    "Total difficulty of the chain until this block.": "此区块之前链的总难度。",
    "The block size is actually determined by the block's gas limit.":
      "区块大小实际上由区块的 Gas 限制确定。",
    "The total gas used in the block and its percentage of gas filled in the block.":
      "区块中使用的总 Gas 以及区块中充填的 Gas 的百分比。",
    "Total gas limit provided by all transactions in the block.":
      "区块中所有交易提供的总 Gas 限制。",
    "Post-London Upgrade, this represents the minimum gas Used multiplier required for a tx to be included in a block.":
      "伦敦升级后，这代表了要将交易包括在区块中所需的最小 Gas 使用倍增器。",
    "Post-London Upgrade, this represents the part of the tx fee that is burnt: baseFeePerGas * gasUsed.":
      "伦敦升级后，这代表了交易费用中被燃烧的部分：baseFeePerGas * gasUsed。",
    "Any data that can be included by the block producer in the block.":
      "区块生产者可以在区块中包含的任何数据。",
    "The hash of the block header of the current block.":
      "当前区块的区块头哈希。",
    "The hash of the block from which this block was generated, also known as its parent block.":
      "生成此区块的区块的哈希，也称为其父区块。",
    "The root of the state trie.": "状态 trie 的根。",
    "Block header withdrawal root hash.": "区块头提取根哈希。",
    "Block nonce is a value used during mining to demonstrate proof of work for a block.":
      "区块随机数是在挖矿过程中用来证明区块工作证明的值。",
    Block: "区块",
    Blocks: "区块",
    Age: "年龄",
    Node: "节点",
    "Base Fee": "基本费用",
    "See preview of the transaction details.": "查看交易详情的预览。",
    "Additional Info": "附加信息",
    "Transaction Action": "交易操作",
    Transfer: "转账",
    To: "至",
    "Transaction Fee": "交易费用",
    "Gas Info": "Gas 信息",
    "See more details": "查看更多详情",
    "Tx Hash": "交易哈希",
    Type: "类型",
    From: "从",
    "Tx Fee": "交易费用",
    "in the position": "在位置",
    "A TxHash or transaction hash is a unique 66-character identifier that is generated whenever a transaction is executed.":
      "TxHash 或交易哈希是在执行交易时生成的唯一的 66 个字符标识符。",
    "Transaction Hash": "交易哈希",
    "TxHash to clipboard": "复制交易哈希",
    "The status of the transaction.": "交易状态。",
    "Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was produced.":
      "交易记录在其中的区块号。区块确认表示自交易生成以来已添加了多少个区块。",
    Confirmation: "确认",
    "Pending...": "待处理...",
    "The date and time at which a transaction is produced.":
      "交易生成的日期和时间。",
    "Confirmed within": "在确认之内",
    sec: "秒",
    "Highlighted events of the transaction.": "交易的突出事件。",
    "The sending party of the transaction.": "交易的发送方。",
    "The receiving party of the transaction (could be a contract address).":
      "交易的接收方（可能是合约地址）。",
    created: "已创建",
    "List of {{_tokenType}}20 tokens transferred in the transaction.":
      "交易中转移的 {{_tokenType}}20 代币列表。",
    "Tokens Transferred": "已转移的代币",
    For: "用于",
    " tokens transferred in the transaction.": "在交易中转移的代币。",
    "List of": "列表",
    "Token ID": "代币 ID",
    "List of tokens mint transferred in the transaction.":
      "交易中转移的代币列表。",
    "Tokens minted": "已铸造的代币",
    "List of tokens burnt transferred in the transaction.":
      "交易中燃烧的代币列表。",
    "Tokens burnt": "已燃烧的代币",
    "The value being transacted in Ether and fiat value. Note: You can click the fiat value (if available) to see historical value at the time of transaction.":
      "以太币和法定货币价值的交易价值。注意：您可以单击法定货币价值（如果可用）以查看交易时的历史价值。",
    "Amount paid to process the transaction in Ether and fiat value.":
      "用以太币和法定货币支付的交易处理金额。",
    "Actual Tx Cost/Fee": "实际交易成本/费用",
    "Cost per unit of gas spent for the transaction, in Ether and Gwei.":
      "交易花费的每单位 Gas 成本，以以太币和 Gwei 表示。",
    "Gas Price": "Gas 价格",
    "Maximum amount of gas allocated for the transaction & the amount eventually used. Normal ETH transfers involve 21,000 gas units while contracts involve higher values.":
      "分配给交易的最大 Gas 量和最终使用的 Gas 量。普通的 ETH 转账涉及 21,000 个 Gas 单位，而合约涉及更高的值。",
    "Gas Limit & Usage by Txn": "交易的 Gas 限制和使用情况",
    "Base Fee refers to the network Base Fee at the time of the block, while Max Fee & Max Priority Fee refer to the max amount a user is willing to pay for their tx & to give to the block producer respectively.":
      "基本费用指的是区块时网络的基本费用，而最大费用和最大优先级费用分别指用户愿意为其交易支付的最大金额和愿意提供给区块生产者的最大金额。",
    "Gas Fees": "Gas 费",
    Max: "最大",
    "Max Priority": "最大优先度",
    Base: "基础",
    "Total amount of ETH burnt from this tx & total fees saved from the amount the user was willing to pay for this tx.":
      "此交易中消耗的 ETH 总额及用户愿意支付此交易费用的总费用节省。",
    "Burnt & Txn Savings Fees": "燃烧和交易节省费用",
    Burnt: "燃烧",
    "Txn Savings": "交易节省",
    "Other data related to this transaction.": "与此交易相关的其他数据。",
    "Other Attributes": "其他属性",
    "Txn Type": "交易类型",
    Position: "位置",
    Legacy: "传统",
    "Input Data": "输入数据",
    Hex: "十六进制",
    "to clipboard": "复制到剪贴板",
    "Decode input data": "解码输入数据",
    "Method id": "方法 ID",
    Call: "调用",
    Name: "名称",
    Data: "数据",
    "Logdata to clipboard": "日志数据到剪贴板",
    "Address to clipboard": "地址到剪贴板",
    Indexed: "索引",
    Topics: "主题",
    Text: "文本",
    Number: "数字",
    TO: "至",
    number: "数字",
    "Tx row trace to clipboard": "交易行追踪到剪贴板",
    "No trace entries found.": "未找到追踪条目。",
    Before: "之前",
    After: "之后",
    "State Difference": "状态差异",
    Token: "代币",
    "Transaction Details": "交易详情",
    "Token transfers": "代币转账",
    Logs: "日志",
    state: "状态",
    "Raw trace": "原始追踪",
    Method: "方法",
    "Careers at BlocksScan": "BlocksScan 的职业",
    "Join BlocksScan and enhance the world's leading blockchain explorer and search, API & analytics platform.":
      "加入 BlocksScan 并增强全球领先的区块链浏览器、搜索、API 和分析平台。",
    "Perks and Benefits": "福利和好处",
    "Ours isn’t just a grand vision. We back it up with the company and culture tailored for realizing it.":
      "我们的愿景不仅仅是宏伟的。我们为实现它而提供了量身定制的公司和文化支持。",
    "Free Flow Drinks & Snacks": "畅饮畅食",
    "Never worry about a lack of refreshments or tasty morsels to munch on while adding value to the team.":
      "在为团队增加价值的同时，永远不必担心缺少饮料或美味的零食。",
    "Best Coffee in Town": "城里最好的咖啡",
    "State-of-the-art coffee machine and experienced baristas in the house for the coffee lovers to thrive.":
      "最先进的咖啡机和经验丰富的咖啡师，让咖啡爱好者蓬勃发展。",
    "Annual Trips": "年度旅行",
    "Enjoy a break from the daily grind with sponsored trips to cool new locations and experiences.":
      "享受赞助的旅行，体验酷炫的新地点和经历，摆脱日常的繁忙。",
    "Office Entertainment": "办公室娱乐",
    "Games and gadgets to distract yourself with while thinking up novel solutions to hard problems.":
      "玩游戏和小玩意，分散注意力，同时考虑解决困难问题的新颖方案。",
    "Food Allowance": "食物津贴",
    "Extra lunch money provided along with the occasional team lunch and dinners.":
      "额外的午餐钱，偶尔的团队午餐和晚餐。",
    "Commute Allowance": "通勤津贴",
    "Concerns with transportation and parking eased so that team members can focus on delivering value.":
      "解决交通和停车问题，让团队成员专注于创造价值。",
    "Flex Benefits": "灵活福利",
    "For almost anything that improves your life - training courses, fitness plans... even beauty facials!":
      "几乎任何能改善生活的事物——培训课程、健身计划...甚至美容面部护理！",
    "Medical Benefits": "医疗福利",
    "Coverage of outpatient, dental and optical services from a registered clinic of your choice.":
      "您选择的注册诊所提供门诊、牙科和视光服务的覆盖范围。",
    "Flexible working hours": "灵活的工作时间",
    "Contribute to the team effort without worrying about what time to clock in.":
      "为团队努力做出贡献，不用担心何时打卡。",
    "Learning Sessions": "学习会议",
    "Keep up with the latest breakthroughs and best practices in the industry with learned and experienced teammates.":
      "与学有专长和经验丰富的团队成员一起了解行业的最新突破和最佳实践。",
    "Working at BlocksScan": "在 BlocksScan 工作",
    "BlocksScan is a team pioneering at the forefront of the blockchain industry. Our platform provides the infrastructure for users and developers to access and build decentralized applications powering billions of dollars in value.":
      "BlocksScan 是一支处于区块链行业前沿的团队。我们的平台为用户和开发人员提供基础设施，让他们可以访问和构建支持数十亿美元价值的去中心化应用。",
    "We are made up of diverse backgrounds and skillsets, with a combined passion for providing equitable access to open financial systems. While we strive for excellence in all that we do, we don't take ourselves too seriously either. We love to explore new frontiers and have fun in doing so.":
      "我们由具有多样化背景和技能的成员组成，我们共同热爱为开放的金融系统提供公平的访问。虽然我们在所有工作中都追求卓越，但我们也不会太认真。我们热爱探索新的领域，并从中获得乐趣。",
    "Understand Contract Functionalities": "了解合约功能",
    "What are the functions or methods available in this contract?":
      "此合约中有哪些可用的功能或方法？",
    "Design a WithdrawBalance() method to withdraw contract balance.":
      "设计一个 WithdrawBalance() 方法来提取合约余额。",
    "What is the logical structure of this contract's code?":
      "此合约代码的逻辑结构是什么？",
    "Is this a proxy contract?": "这是一个代理合约吗？",
    "Write me a smart contract of voting.": "为我编写一个投票的智能合约。",
    "What does this contract do?": "这个合约是用来做什么的？",
    "How does SafeMath change the contract's state?":
      "SafeMath 如何改变合约的状态？",
    "Code Reader": "代码阅读器",
    NEW: "新的",
    "Understand and use smart contracts better with CodeRunAI.":
      "使用 CodeRunAI 更好地理解和使用智能合约。",
    Mode: "模式",
    "I have an API Key": "我有一个 API 密钥",
    "Any open source contract": "任何开源合约",
    "Any question": "有什么问题",
    "I don't have an API Key": "我没有 API 密钥",
    "Popular open source contracts": "热门开源合约",
    "Example questions": "示例问题",
    DEMO: "演示",
    "OpenAI API key": "OpenAI API 密钥",
    "Where to find my API Key?": "API 密钥在哪里找？",
    "Save it to the browser": "保存到浏览器",
    "GPT Model": "GPT 模型",
    "Smart Contract": "智能合约",
    "Contract Address": "合约地址",
    "Contract Code": "合约代码",
    Load: "加载",
    Trending: "热门",
    "Contract Files": "合约文件",
    "Source Code": "源代码",
    "Popular Contracts": "热门合约",
    "No Record Found": "未找到记录",
    "Loading...": "加载中...",
    "Hi, there.": "嗨，你好。",
    "I can help you in the following scenarios": "我可以在以下情况下帮助您",
    ME: "我",
    "Enter any question you are interested in": "输入您感兴趣的任何问题",
    Signer: "签署者",
    "Send native": "发送原生",
    "Add one more": "再添加一个",
    Read: "读取",
    Write: "写入",
    Loading: "加载中",
    "Method Name": "方法名称",
    Permalink: "永久链接",
    Code: "代码",
    "Read Contract": "读取合约",
    "Read Proxy": "读取代理",
    "Write Contract": "写入合约",
    "Write Proxy": "写入代理",
    "Contract Source Code Verified": "合约源代码已验证",
    "Are you the contract creator": "您是否是合约创建者",
    "Verify And Publish": "验证并发布",
    "your contract source code today": "今天的合约源代码",
    "EVM Version": "EVM 版本",
    "Optimization Runs": "优化运行次数",
    "Contract File Path": "合约文件路径",
    "Compiler Version": "编译器版本",
    "Optimization Enabled": "启用优化",
    "Verified At": "验证时间",
    "Constructor Arguments": "构造函数参数",
    "ABI-encoded": "ABI 编码",
    "Byte Code": "字节码",
    File: "文件",
    of: "的",
    "ABI for the implementation contract at": "实施合约的 ABI",
    "Type of Backing": "支持类型",
    "Type of Peg": "锚定类型",
    "Collater­alization": "抵押",
    "Storage Of Assets": "资产存储",
    Market: "市场",
    "Market Capitalization": "市值",
    "Circulating Supply": "流通供应量",
    "Click here to": "点击这里",
    update: "更新",
    "the token information / general information": "令牌信息 / 通用信息",
    Metadata: "元数据",
    Missing: "丢失",
    Chain: "链",
    "Portfolio %": "投资组合",
    Price: "价格",
    Amount: "数量",
    Showing: "显示",
    "tokens with a value of": "价值的代币",
    Sponsored: "赞助",
    Complier: "编译器",
    "Token Name": "令牌名称",
    "Total Holders": "持有者总数",
    "Collection Name": "收藏名称",
    Validator: "验证器",
    in: "在",
    View: "查看",
    "Latest Transactions": "最新交易",
    "Latest Blocks": "最新区块",
    Customize: "自定义",
    "NFT Address": "NFT 地址",
    "all Blocks": "所有区块",
    "Top Tokens": "热门令牌",
    "Top Contracts": "热门合约",
    "Top NFTs": "热门 NFT",
    "Ask AI | Search by Address | Txn Hash | Block | Token... ":
      "通过地址 / 交易哈希 / 区块 / 代币搜索... ",
    "No transactions found.": "未找到交易。",
    "Market Cap": "市值",
    "Total Transactions": "总交易量",
    "MED GAS PRICE": "MED GAS 价格",
    "TOTAL BLOCK": "总区块数",
    "GasTracker Estimate for Average Gas": "GasTracker 平均 Gas 估算",
    "Total Accounts": "总账户数",
    "Daily Transactions": "每日交易量",
    "Contact Us": "联系我们",
    "Feedback Form": "反馈表格",
    "Please understand that we can only assist with issues directly related to the block explorer services. Before writing in, please be informed that":
      "请理解我们只能处理与区块浏览器服务直接相关的问题。在联系我们之前，请注意：",
    "Pending Transaction": "待处理交易",
    "We do not": "我们不会",
    "process transactions": "处理交易",
    "and are therefore unable to expedite, cancel or replace them. If you are having issues with pending transactions, please refer":
      "因此无法加速处理、取消或替换它们。如果您遇到待处理交易的问题，请参考",
    here: "此处",
    "Blocksscan is an independent": "Blocksscan 是独立的",
    "block explorer": "区块浏览器",
    "unrelated to other service providers (unless stated explicitly otherwise) and is therefore unable to provide a precise response for inquiries that are specific to other service providers.":
      "与其他服务提供商无关（除非另有明确说明），因此无法对与其他服务提供商相关的特定查询提供精确的响应。",
    "Knowledge Base": "知识库",
    "The links on the right might already have the answer to your inquiry. If they don't, perhaps one of the other articles in our":
      "右侧的链接可能已经包含了您的查询答案。如果没有，请参考我们的其他文章：",
    "page does.": "页面。",
    "Community Support": "社区支持",
    "We will never ask for your private keys or passwords.":
      "我们绝不会要求您的私钥或密码。",
    "NEVER send us these.": "永远不要将这些发送给我们。",
    "While we try our best to respond in a timely manner, we often have a backlog of inquiries. Please do not":
      "尽管我们会尽力及时回复，但我们经常有大量的查询积压。请不要",
    "spam us": "向我们发送垃圾邮件",
    "Wallet / Exchange / Project related issues":
      "钱包 / 交易所 / 项目相关问题",
    "Kindly reach out to your wallet service provider, exchanges or project/contract owner for further support as they are in a better position to assist you on the issues related to and from their platforms.":
      "请与您的钱包服务提供商、交易所或项目/合同所有者联系，以获取进一步的支持，因为他们更有能力帮助您解决与他们平台相关的问题。",
    Subject: "主题",
    "Please Select Your Message Subject": "请选择您的消息主题",
    "General Inquiry": "一般查询",
    Advertising: "广告",
    "Update Token Info": "更新代币信息",
    "Add Name Tag/Label": "添加名称标签/标签",
    "Request Removal of Name Tag": "请求删除名称标签",
    "Suggest Transaction Action": "建议交易操作",
    "Update Proxy Contract's Implementation Address": "更新代理合约的实施地址",
    "Report Phishing Address": "报告钓鱼地址",
    "Security Audit": "安全审计",
    "Priority Support": "优先支持",
    "API Support": "API 支持",
    "1. Inquiries": "1. 询问",
    "2. Submissions": "2. 提交",
    "3. Security": "3. 安全",
    "Help & Support": "帮助与支持",
    "24/7 support. Our team is here to help you and make sure your product is up to date.":
      "全天候支持。我们的团队在这里帮助您，确保您的产品是最新的。",
    Sales: "销售",
    "Have a presale question about our products and features? Or looking for a refund?":
      "有关我们的产品和特性有预售问题吗？还是在寻找退款？",
    "Media & Press": "媒体与新闻",
    "Online awards and publications. Get our online media resources and learn about our company.":
      "在线奖项和出版物。获取我们的在线媒体资源，并了解我们的公司。",
    "Tx Hash|Block|Age": "交易哈希|区块|时间",
    "12 days ago": "12 天前",
    "Event Logs": "事件日志",
    Mint: "铸币",
    Transaction: "交易",
    Success: "成功",
    Failed: "失败",
    Pending: "待处理",
    "Contract call": "合约调用",
    "Contract creation": "合约创建",
    "Token transfer": "代币转账",
    "Token creation": "代币创建",
    "Coin transfer": "币种转账",
    REMASC: "报酬管理智能合约",
    Bridge: "桥接",
    Rank: "排名",
    Quantity: "数量",
    Item: "项目",
    Details: "详情",
    ID: "身份证",
    "Last Traded": "最后交易",
    "Estimated Value on Day of Txn": "交易当日估计价值",
    "Displaying current Value; Click to show Value on Day of Txn":
      "显示当前值；单击以显示交易当日的值",
    Action: "操作",
    "Verify & Publish Contract Source Code": "验证和发布合约源代码",
    "COMPILER TYPE AND VERSION SELECTION": "编译器类型和版本选择",
    "Contract Licence": "合约许可证",
    "For best practices, all contract source code holders, publishers and authors are encouraged to also specify the accompanying license for their verified contract source code provided.":
      "为了最佳实践，鼓励所有合约源代码持有者、发布者和作者也为其提供的经过验证的合约源代码指定相应的许可证。",
    "Please select": "请选择",
    "Verification Method": "验证方法",
    "Compiler Type": "编译器类型",
    "Enter name here...": "在此输入名称...",
    "Is Yul Contract": "是否为 Yul 合约",
    "The compiler version is specified in pragma solidity X.X.X. Use the compiler version rather than the nightly build. If using the Solidity compiler, run solc —version to check.":
      "编译器版本在 pragma solidity X.X.X 中指定。请使用编译器版本，而不是夜间构建版本。如果使用 Solidity 编译器，请运行 solc —version 进行检查。",
    "The EVM version the contract is written for. If the bytecode does not match the version, we try to verify using the latest EVM version.":
      "合约编写的 EVM 版本。如果字节码与版本不匹配，我们尝试使用最新的 EVM 版本进行验证。",
    "Type here ...": "在此输入...",
    "We recommend using flattened code. This is necessary if your code utilizes a library or inherits dependencies. Use the POA solidity flattener or the Truffle flattener.":
      "我们建议使用扁平化的代码。如果您的代码使用了库或继承了依赖项，则这是必需的。使用 POA solidity flattener 或 Truffle flattener。",
    "Enter Source Code...": "输入源代码...",
    "Standard JSON Input": "标准的 JSON 输入",
    "Sources and Metadata JSON": "源代码和元数据 JSON",
    "Sources *.sol or *.yul files": "源文件 *.sol 或 *.yul",
    "Main Interfaces (.vy or .json)": "主要接口 (.vy 或 .json)",
    "Upload the standard input JSON file created during contract compilation":
      "上传在合约编译过程中创建的标准输入 JSON 文件",
    "Upload all Solidity contract source files and JSON metadata file(s) created during contract compilation.":
      "上传在合约编译过程中创建的所有 Solidity 合约源文件和 JSON 元数据文件。",
    "Upload all Solidity or Yul contract source files":
      "上传所有 Solidity 或 Yul 合约源文件",
    "Primary compiled Vyper contract. Only add the main contract here whose bytecode has been deployed, all other files can be uploaded to the interfaces box below":
      "主要编译的 Vyper 合约。只添加已部署字节码的主合约，其他所有文件都可以上传到下面的接口框中。",
    "Drag & drop files here, or click to select files":
      "拖放文件到此处，或点击选择文件",
    "Verify & Publish": "验证和发布",
    "Gas tracker": "Gas 追踪器",
    "Network utilization": "网络利用率",
    "low load": "低负载",
    "Last updated": "最近更新",
    Fast: "快速",
    Normal: "普通",
    Slow: "慢",
    "Gas price history": "Gas 价格历史",
    "Charts & stats": "图表与统计",
    "Account Balance Checker": "账户余额检查器",
    "You can Lookup the Account": "您可以查找账户",
    "Historical Balance at a specific Block No or Date.":
      "特定区块号或日期的历史余额。",
    Account: "账户",
    "Date for the snapshot": "快照日期",
    "Block Number": "区块号",
    Lookup: "查找",
    "Choose desired language": "选择所需的语言",
    "Choose the theme": "选择主题",
    "Dark Mode": "深色模式",
    "Do you want to show relative time? eg.": "是否显示相对时间？例如",
    "Do you want to show UTC time? eg.": "是否显示 UTC 时间？例如",
    "Change Fiat Currency": "更改法定货币",
    Use: "使用",
    "prefix instead of": "代替前缀",
    "NFT Latest Mints": "NFT 最新铸币",
    "Token Id": "代币 ID",
    "NFT Transfer": "NFT 转移",
    "Your browser does not support the video tag.":
      "您的浏览器不支持视频标签。",
    "Min Price": "最低价格",
    "Last Sale": "最近销售",
    "Current owner of this NFT.": "此 NFT 的当前所有者。",
    "Address of this NFT contract.": "此 NFT 合约的地址。",
    "Address that deployed this NFT contract.": "部署此 NFT 合约的地址。",
    Creator: "创建者",
    "Classification of NFT based on how the image is stored: On-Chain refers to those with data stored on the blockchain while Off-Chain refers to those with data stored elsewhere on the Internet.":
      "根据图像存储方式对 NFT 进行分类：链上指的是数据存储在区块链上的 NFT，链下指的是数据存储在互联网上的 NFT。",
    Classification: "分类",
    "This NFT's unique token ID.": "此 NFT 的唯一代币 ID。",
    "The standard followed by this NFT.": "此 NFT 遵循的标准。",
    "Token Standard": "代币标准",
    "Marketplaces trading this NFT.": "交易此 NFT 的市场。",
    Marketplaces: "市场",
    Properties: "属性",
    Description: "描述",
    Collection: "收藏",
    Volume: "量",
    Change: "变化",
    "Max Price": "最高价格",
    "NFT Top Mints": "NFT 最高铸币",
    Owners: "所有者",
    "Total Assets": "总资产",
    "Note: Filtered by  totalSupply() , method returned a zero value.":
      "注意：根据 totalSupply() 进行过滤，方法返回了零值。",
    "A Paid Service": "付费服务",
    "At BlocksScan, we handle a substantial volume of support requests daily. While we strive our best to respond to each one in a timely manner, we recognize that certain projects may want to submit their token information quicker than our standard turnaround time.":
      "在 BlocksScan，我们每天处理大量的支持请求。虽然我们努力及时回应每一个请求，但我们认识到某些项目可能希望比我们的标准交付时间更快地提交其代币信息。",
    "In addition to expediting token updates, we also provide priority support for other urgent or technical matters, such as contract verification.":
      "除了加快代币更新速度外，我们还为其他紧急或技术问题提供优先支持，例如合约验证。",
    "Kindly follow the simple steps below to get your support prioritized.":
      "请按照以下简单步骤提高您的支持优先级。",
    "Get Started": "开始",
    "How to Start": "如何开始",
    "Simple steps for getting Priority Support.": "获取优先支持的简单步骤。",
    "Step 1": "步骤1",
    "Check Token Information": "检查代币信息",
    "Ensure that token information is submitted. Check our token update guidelines":
      "确保已提交代币信息。查看我们的代币更新指南",
    "Step 2": "步骤2",
    "Provide Token Update Request": "提供代币更新请求",
    "Provide us with the token update request information in the contact form below.":
      "在下面的联系表单中提供代币更新请求信息。",
    "Step 3": "步骤3",
    "We'll Review Your Request": "我们将审查您的请求",
    "Our team will review your request and contact you using an official":
      "我们的团队将审查您的请求，并使用官方联系您",
    "Please use this form to get in touch for priority support or featured listing with our team.":
      "请使用此表单与我们的团队联系，以获取优先支持或注目列表。",
    "Requester Name": "请求者姓名",
    "Requester Email": "请求者电子邮件",
    "Token Contract Address": "代币合约地址",
    "Choose Priority Service": "选择优先服务",
    "Token Update": "代币更新",
    "Featured Listing": "注目列表",
    "Contract Verification": "合约验证",
    Others: "其他",
    "Learn more about": "了解更多",
    "Helpdesk Ticket Number": "帮助台工单号",
    "Additional Notes": "附加说明",
    Note: "注意",
    "We will": "私たちは",
    NOT: "ない",
    "request payment via direct deposit. All payments will be directed through our official payment channel.":
      "直接入金による支払いをリクエストします。すべての支払いは、当社の公式支払いチャネルを通じて行われます。",
    NEVER: "なし",
    "ask for your private key or seed words. Please":
      "秘密鍵またはシードワードを尋ねます。お願いします",
    "DO NOT SHARE": "共有しないでください",
    "your private keys with anyone.": "あなたの秘密鍵を誰にでも共有できます。",
    "Send Message": "メッセージを送信",
    "Ex. John Doe": "例 - ジョン・ドウ",
    "If you have any additional notes, please leave them here":
      "その他の注意事項があれば、ここに書き残してください。",
    "Privacy Policy": "プライバシーポリシー",
    "Welcome to Blocksscan Explorer Services":
      "Blocksscan Explorer サービスへようこそ",
    we: "私たち",
    us: "私たち",
    our: "私たちの",
    "We are committed to respecting the privacy and security of our users' information. This Privacy Policy outlines the types of information we may collect from you or that you may provide when you use our services, and our practices for collecting, using, maintaining, protecting, and disclosing that information.":
      "当社はユーザーの情報のプライバシーとセキュリティを尊重することに尽力しています。このプライバシー ポリシーでは、当社がお客様から収集する情報の種類、またはお客様が当社のサービスを使用する際に提供する可能性のある情報の種類、およびその情報の収集、使用、維持、保護、開示に関する当社の慣行について概説します。",
    "Information We Collect": "当社が収集する情報",
    "We collect several types of information from and about users of our Blocksscan Explorer Services, including":
      "当社は、Blocksscan Explorer サービスのユーザーから、およびユーザーに関するいくつかの種類の情報を収集します。",
    "Personal Information: Data that personally identifies you, such as your name, email address, and payment details, provided at registration or during the use of our services.":
      "個人情報: 登録時または当社サービスの使用中に提供される、名前、電子メール アドレス、支払い詳細など、お客様を個人的に特定するデータ。",
    "Non-Personal Information: Data that does not personally identify you but is linked to your usage of our services, such as IP addresses, browser types, and access times.":
      "非個人情報： IPアドレス、ブラウザの種類、アクセス時間など、個人を特定するものではないが、当社サービスの利用に関連するデータ。",
    "Transactional Information: Data related to transactions conducted on the network, which may include public blockchain data.":
      "取引情報： ネットワーク上で行われた取引に関するデータで、パブリックブロックチェーンデータを含む場合がある。",
    "How We Use Your Information": "お客様の情報の使用方法",
    "We use the information that we collect about you or that you provide to us, including any personal information":
      "当社は、お客様について収集した情報、またはお客様が当社に提供した情報（個人情報を含む）を使用します。",
    "To present our services and its contents to you.":
      "当社のサービスおよびその内容をお客様にご紹介するため。",
    "To provide you with information, products, or services that you request from us.":
      "お客様が当社に求める情報、製品、サービスを提供するため。",
    "To fulfill any other purpose for which you provide it.":
      "お客様が提供するその他の目的を達成するため。",
    "To carry out our obligations and enforce our rights arising from any contracts entered into between you and us.":
      "当社の義務を履行し、お客様と当社との間で締結された契約から生じる権利を行使するため。",
    "To notify you about changes to our service or any products or services we offer or provide through it.":
      "当社のサービス、または当社が提供または提供する製品やサービスの変更についてお客様に通知するため。",
    "For any other purpose with your consent.":
      "その他、お客様の同意を得た目的のため。",
    "Data Security": "データセキュリティ",
    "We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. However, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our services.":
      "我们已实施旨在保护您的个人信息免遭意外丢失以及未经授权的访问、使用、更改和披露的措施。然而，通过互联网传输信息并不完全安全。尽管我们尽最大努力保护您的个人信息，但我们无法保证传输到我们服务的您的个人信息的安全性。",
    "Data Retention": "データ保持",
    "We will retain your personal information for as long as your account is active or as needed to provide you services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.":
      " 当社は、お客様のアカウントが有効である限り、またはお客様にサービスを提供するために必要な限り、お客様の個人情報を保持します。当社は、当社の法的義務の遵守、紛争の解決、および契約の執行のために必要なお客様の情報を保持し、使用します。",
    "Your Rights": "あなたの権利",
    "You have the right to request access to, correct, or delete any personal information that you have provided to us. You may also object to or request restrictions on the processing of your personal information.":
      "お客様は、当社に提供した個人情報へのアクセス、訂正、削除を要求する権利を有します。また、個人情報の処理に異議を唱えたり、制限を要求したりすることもできます。",
    "Changes to Our Privacy Policy": "プライバシーポリシーの変更",
    "It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you and for periodically visiting our website and this privacy policy to check for any changes.":
      "当社のプライバシーポリシーに変更があった場合は、このページに掲載する方針です。プライバシーポリシーの最終改訂日は、ページの上部に記載されています。利用者は、当社が利用者の最新かつ有効で配信可能な電子メールアドレスを持っていることを確認し、当社のウェブサイトおよびこのプライバシーポリシーを定期的に訪問して、変更がないかどうかを確認する責任を負うものとします。",
    "Contact Information": "連絡先",
    "To ask questions or comment about this privacy policy and our privacy practices, contact us at":
      "このプライバシー ポリシーおよび当社のプライバシー慣行についてご質問またはコメントが必要な場合は、次のアドレスまでお問い合わせください。",
    "By using Blocksscan Explorer Services, you consent to our Privacy Policy.":
      "お客様は、Blocksscan Explorerのサービスを利用することにより、当社のプライバシーポリシーに同意したものとみなされます。",
    "Max Total Supply": "最大総供給量",
    "Note: Filtered by totalSupply(), method returned a zero value":
      "注：totalSupply()によってフィルタリングされ、メソッドはゼロ値を返した。",
    Holders: "ホルダー",
    "Total Transfers": "振替高",
    Implementation: "実施",
    "Fully Diluted Market Cap": "完全希薄化後時価総額",
    "Circulating Supply Market Cap": "循環供給市場キャップ",
    "Other Info": "他の情報",
    "Token Contract": "トークンコントラクト",
    "FILTERED BY TOKEN HOLDER": "トークン保有者ごとにフィルタリング",
    BALANCE: "バランス",
    Inventory: "在庫",
    Info: "情報",
    With: "と",
    decimals: "小数点以下",
    "Volume 24h": "ボリューム24時間",
    "Circulating Market Cap": "流通時価総額",
    "Onchain Market Cap": "オンチェーン時価総額",
    "Token Transactions": "トークン取引",
    "X (Twitter)": "X（ツイッター）",
    Github: "ギットハブ",
    Facebook: "フェイスブック",
    Instagram: "インスタグラム",
    "Back to Top": "このページのトップへ",
    "Powered by BlocksScan": `${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"} の提供`,
    "BlocksScan is a Block Explorer and Analytics Platform for":
      `${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"}はブロックエクスプローラーであり、次のような分析プラットフォームです。`,
    "a decentralized smart contracts platform.":
      "分散型スマート・コントラクト・プラットフォームである。",
    Company: "会社",
    "About Us": "私たちについて",
    "Brand Assets": "ブランド資産",
    Careers: "キャリア",
    "We're Hiring": "募集中です",
    Terms: "条項",
    Privacy: "プライバシー",
    Community: "コミュニティ",
    "API Documentation": "APIドキュメント",
    "Network Status": "ネットワークステータス",
    Newsletters: "ニュースレター",
    Products: "プロダクツ",
    Services: "サービス",
    "Explorer-as-a-Service": "エクスプローラー・アズ・ア・サービス",
    "API Plans": "APIプラン",
    "All Rights Reserved": "無断転載を禁じます",
    Donations: "寄付",
    Home: "家",
    CodeRunAI: "コードランAI",
    Blockchain: "ブロックチェーン",
    Live: "ライブ",
    WIP: "仕掛品",
    "Pending Transactions": "未決済取引",
    "Contract Internal Transactions": "コントラクト内部取引",
    "View Blocks": "ブロックを見る",
    "Top Accounts": "トップアカウント",
    "Token Transfers": "トークン譲渡",
    "Top Mints": "トップミント",
    "Latest Trades": "最新の取引",
    "Latest Transfers": "最新の振替",
    "Latest Mints": "最新のミント",
    Resources: "リソース",
    "Charts And Stats": "チャートと統計",
    "Top Statistics": "トップの統計",
    Leaderboard: "リーダーボード",
    Directory: "ディレクトリ",
    Developers: "開発者",
    "Verify Contract": "契約の確認",
    "Similar Contract Search": "類似契約検索",
    "Smart Contract Search": "スマートコントラクトの検索",
    "Contract Diff Checker": "契約差分チェッカー",
    "Vyper Online Compiler": "Vyperオンラインコンパイラー",
    "Bytecode to Opcode": "バイトコードからオペコードへ",
    "Broadcast Transaction": "ブロードキャストトランザクション",
    More: "もっと",
    "Balance Checker": "バランスチェッカー",
    "Gas Tracker": "ガストラッカー",
    Light: "ライト",
    Dim: "薄暗い",
    Dark: "暗い",
    Gas: "ガス",
    "Last Updated": "最終更新日",
    "Gas tracker overview": "ガストラッカーの概要",
    "All Transactions": "すべての取引",
    "Custom Card": "カスタムカード",
    "Customize this card by selecting one of the options below":
      "以下のオプションのいずれかを選択して、このカードをカスタマイズします",
    "Advanced Filters": "高度なフィルター",
    Present: "現在",
    "Saved Filters": "保存されたフィルター",
    Close: "閉じる",
    "Save Changes": "変更内容を保存",
    "Top NFT": "トップNFT",
    "Top Contract": "トップコントラクト",
    "Top Token": "トップ・トークン",
    "Latest Transaction": "最新の取引",
    "Latest Block": "最新のブロック",
  },
};
