import React, {FC} from "react";
import {useTranslation} from "react-i18next";

interface TxStatusProps {
    status: "ok" | "error" | null | 1 | 0;
}

const TxStatus: FC<TxStatusProps> = ({status}) => {
    let text;
    let className: any;
    let mark: any;
    const {t, i18n} = useTranslation();

    switch (status) {
        case "ok":
            text = `${t("Success")}`;
            className = "h-green";
            mark = "bi-check-circle-fill";
            break;
        case "error":
            text = `${t("Failed")}`;
            className = "h-red";
            mark = "bi-x-circle-fill";
            break;
        case null:
            text = `${t("Pending")}`;
            className = "h-yellow";
            mark = "bi-hourglass-split";
            break;
        case 1:
            text = `${t("Success")}`;
            className = "h-green";
            mark = "bi-check-circle-fill";
            break;
        case 0:
            text = `${t("Failed")}`;
            className = "h-red";
            mark = "bi-x-circle-fill";
            break;
    }
    return <span className={`bs-badge ${className}`}><i className={`bi ${mark}`}></i> {text}</span>;
};

export default TxStatus;
